import { createSelector } from 'reselect';
import get from 'lodash/get';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { getIsSignedInSelector } from '../../redux/reducers/user/userSelector';
import shouldSaveDefaultCollectionPoint from '../../utils/collection/shouldSaveDefaultCollectionPoint';
import { shouldSaveDefaultAddress } from '../../utils/delivery/shouldSaveDefaultAddress';
import { SAVE_AS_DEFAULT_FORM_ID } from '../../utils/form/configs/saveAsDefault';

const fieldValueSelector = (state: RootState, fieldId: string) => get(state, `form.${SAVE_AS_DEFAULT_FORM_ID}.values.${fieldId}`, '');
const saveDefaultCollectionPointSelector = (state: RootState) => shouldSaveDefaultCollectionPoint(state);
const shouldSaveDefaultAddressSelector = (state: RootState) => shouldSaveDefaultAddress(state);

const saveAsDefaultFormState = createSelector(
  [
    fieldValueSelector,
    getIsSignedInSelector,
    saveDefaultCollectionPointSelector,
    shouldSaveDefaultAddressSelector,
  ],
  (fieldValue, isSignedIn, saveDefaultCollectionPoint, saveDefaultAddress) => ({
    fieldValue,
    isSignedIn,
    saveDefaultCollectionPoint,
    saveDefaultAddress,
  }),
);

export default saveAsDefaultFormState;
