import { createSelector } from 'reselect';
import merge from 'lodash/merge';
import get from 'lodash/get';

// Config
import { BILLING_ADDRESS_FORM_ID } from '../form/configs/billingAddress';

const getBillingAddressFormValues = state => get(state, `form[${BILLING_ADDRESS_FORM_ID}].values`);
const getBillingAddressInitialValues = state => get(state, 'billingAddress.initialValues');

const mergeBillingAddressSources = createSelector(
  [getBillingAddressFormValues, getBillingAddressInitialValues, (_, prioritiseFormValues) => prioritiseFormValues],
  (billingAddressFormValues, billingAddressInitialValues, prioritiseFormValues) => {
    if (prioritiseFormValues) {
      return merge({}, billingAddressInitialValues, billingAddressFormValues);
    }
    return merge({}, billingAddressFormValues, billingAddressInitialValues);
  },
);

export default mergeBillingAddressSources;
