// jl-design-system
import {
  submitForm,
  touchForm,
  resetForm,
} from 'jl-design-system/redux/actions/form/formActions';
// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// redux
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import { clearPaymentPageErrors } from '../payment/paymentActions';
// constants
import {
  ANALYTICS_FORCE_UPDATE_SYNC_ERRORS,
  ANALYTICS_SET_SUBMIT_FAILED,
} from '../../../constants/actionConstants';
// utils
import { getRegisteredFormFieldNames, isFormMounted } from '../../../utils';

export const isValid = syncErrors => (
  Object.keys(syncErrors).reduce((acc, key) => (
    acc ? isEmpty(syncErrors[key]) : acc
  ), true)
);

export const getFormIdsToProcess = (id, formIds = []) => {
  const index = formIds.indexOf(id);
  return formIds.slice(index + 1);
};

export const resetAllOtherForms = (ignoreIds = [], keepSyncErrors = false) => async (dispatch, getState) => {
  const forms = get(getState(), 'form', {});
  const allForms = Object.keys(forms);

  await allForms.reduce(async (promise, id) => {
    // This line will wait for the last async function to finish.
    // The first iteration uses an already resolved Promise
    // so, it will immediately continue.
    await promise;
    const form = forms[id];
    if (!ignoreIds.includes(id) && isFormMounted(form)) {
      const values = form.values || {};
      await dispatch(resetForm(id, values, keepSyncErrors));
    }
  }, Promise.resolve());
};

export const processForms = (formToSubmitId, allFormIds) => async (dispatch, getState) => {
  const formSyncErrors = {};

  const formToSubmit = get(getState().form, formToSubmitId, {});

  // console.warn('--------- processForms', formToSubmitId);

  if (isFormMounted(formToSubmit)) {

    const allForms = getFormIdsToProcess(formToSubmitId, allFormIds);

    // console.warn('--------- processForms', formToSubmitId, allForms);

    const formToSubmitSyncErrors = get(getState().form, `${formToSubmitId}.syncErrors`);

    if (formToSubmitSyncErrors) {
      formSyncErrors[formToSubmitId] = formToSubmitSyncErrors;

      await dispatch(clearPaymentPageErrors([formToSubmitId]), true);

      // console.warn('submitting:', formToSubmitId, 'errors', formToSubmitSyncErrors);

      // submit this form to turn fields red and show validation error message
      dispatch(submitForm(formToSubmitId));

      // touch all other forms to turn the fields red
      allForms.forEach((formId) => {
        if (formId !== formToSubmitId) {
          const form = get(getState().form, formId);

          if (isFormMounted(form)) {
            const syncErrors = form.syncErrors || {};
            const fieldNames = getRegisteredFormFieldNames(form, true);

            // console.warn('>>> touching:', formId);
            dispatch(touchForm(formId, fieldNames));
            formSyncErrors[formId] = syncErrors;
          }
        }
      });
    }
  }

  return formSyncErrors;
};

const getErrorFields = formSyncErrors => Object.keys(formSyncErrors).reduce((acc, key) => {
  if (formSyncErrors[key]) {
    return {
      ...acc,
      [key]: Object.keys(formSyncErrors[key]).map(key => key),
    };
  }
  return acc;
}, {});

export const handleSubmitFormValidationErrorAnalytics = formSyncErrors => (dispatch) => {

  // ensure analytics syncErrors have been updated
  Object.keys(formSyncErrors).forEach(async (form) => {
    if (isEmpty(formSyncErrors)) {
      return;
    }
    dispatch(triggerAnalyticsEvent(
      ANALYTICS_FORCE_UPDATE_SYNC_ERRORS,
      {
        meta: { form },
        payload: { syncErrors: { ...formSyncErrors[form] } },
      },
    ));
  });

  // dispatch custom analytics ANALYTICS_SET_SUBMIT_FAILED
  // this allows all these forms to be treated as a single form submission in analytics
  dispatch(triggerAnalyticsEvent(ANALYTICS_SET_SUBMIT_FAILED, {
    meta: {
      fields: getErrorFields(formSyncErrors),
    },
  }));
};
