// lodash
import get from 'lodash/get';
//
import env from 'jl-design-system/utils/env/env';

function getSelectOptions(data) {
  const stubs = get(data, 'stubs', {});

  return Object.keys(stubs).reduce((acc, key) => (
    [
      ...acc,
      { label: `Stub : ${key}`, value: key },
    ]
  ), []);
}

export default async function loadPaymentWalletStubs(stubName) {
  const stubsResponse = await window.fetch(window.env.MOCKDATA_LOCATION).catch(() => {
    // eslint-disable-next-line no-console
    console.warn('Can not load mockData.json - stubs will not work');
  });

  const jsonStubsResponse = await stubsResponse.json();
  const mockData = jsonStubsResponse[stubName];
  const options = getSelectOptions(mockData);
  const firstOption = get(options, 0);
  const payload = get(mockData, `stubs.${firstOption.value}`);

  return {
    mockData,
    stub: firstOption.label,
    options,
    payload,
  };
}

export function shouldLoadPaymentWalletStubs(state) {
  return env.isClientNonProd && state.payment.applePayCompatibilityChecksComplete;
}
