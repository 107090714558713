// Types
import { RootState } from 'types/RootState.types';

// Config
import { isMyJLMember } from '../../../utils/myjl/isMyJLMember';

export const getAddressBookSelector = (state: RootState) => state.user?.addressBook;
export const getAgeCheckErrorSelector = (state: RootState) => state.user?.ageCheckError;
export const getAgeCheckRequiredSelector = (state: RootState) => state.user?.ageCheckRequired || false;
export const getAgeCheckSuccessSelector = (state: RootState) => state.user?.ageCheckSuccess || false;
export const getAgeCheckMinimumAgeSelector = (state: RootState) => state.user?.ageCheckMinimumAge || '';
export const getAgeRestrictedProductsSelector = (state: RootState) => state.user?.ageRestrictedProducts;
export const getAgeRestrictedProductsInBasketSelector = (state: RootState) => state.user?.ageRestrictedProductsInBasket;
export const getAgeRestrictedProductsCanRemoveSelector =
  (state: RootState) => state.user?.ageRestrictedProductsCanRemove || false;
export const getAllItemsHaveBatchingFailureSelector =
  (state: RootState) => state.user?.allItemsHaveBatchingFailure || false;
export const getApplyPartnerDiscountErrorSelector = (state: RootState) => state.user?.applyPartnerDiscountError;
export const getAuth0CallbackErrorSelector = (state: RootState) => state.user?.auth0CallbackError;
export const getAuth0RegistrationSuccessSelector = (state: RootState) => state.user?.auth0RegistrationSuccess || false;
export const getBatchingFailureItemsSelector = (state: RootState) => state.user?.batchingFailureItems;
export const getUserSavedCollectionPointsSelector = (state: RootState) => state.user?.collectionPoints;
export const getCustomerPreferencesPaymentChoiceSelector =
  (state: RootState) => state.user?.customerPreferences?.paymentChoice;
export const getUserEmailAddressSelector = (state: RootState) => state.user?.email ?? '';
export const getAgeVerificationSessionApiCallActiveSelector =
  (state: RootState) => state.user?.getAgeVerificationSessionApiCallActive || false;
export const getIsPartnerDiscountApiCallActiveSelector =
  (state: RootState) => state.user?.isPartnerDiscountApiCallActive ?? true;
export const getIsGuestSelector = (state: RootState) => state.user?.isGuest || false;
export const getIsSignedInSelector = (state: RootState) => state.user?.isSignedIn || false;
export const getIsSignedInWithDataSelector = (state: RootState) => state.user?.isSignedInWithData || false;
export const getMyJLMemberStatusSelector = (state: RootState) => state.user?.loyaltyType || '';
export const getMyJohnLewisMembershipPendingSelector =
  (state: RootState) => state.user?.myJohnLewisMembershipPending || false;
export const getPartnerDiscountCardNumberSelector =
  (state: RootState) => state.user?.partnerDiscountCardNumber || '';
export const getUserOrderSavedSelector = (state: RootState) => state.user?.orderSaved || false;
export const getPartnerDiscountEnabledSelector = (state: RootState) => state.user?.partnerDiscountEnabled || false;
export const getPreviewSelectedBillingAddressSelector =
  (state: RootState) => state.user?.previewSelectedBillingAddress || false;
export const getSavedPaymentCardsSelector = (state: RootState) => state.user?.savedPaymentCards;
export const getSelectedBillingAddressSelector = (state: RootState) => state.user?.selectedBillingAddress;
export const getSelectedDeliveryAddressSelector = (state: RootState) => state.user?.selectedDeliveryAddress;
export const getSelectedBillingAddressAddressSelector =
  (state: RootState) => state.user?.selectedBillingAddress?.address;
export const getUserSelectedPaymentTypeSelector = (state: RootState) => state.user?.selectedPaymentType;
export const getShowAgeCheckModalSelector = (state: RootState) => state.user?.showAgeCheckModal || false;
export const getShouldShowClickAndCollectPriceWarningSelector =
  (state: RootState) => state.user?.showClickAndCollectPriceWarning || false;
export const getShowRemoveBatchingFailureItemErrorSelector =
  (state: RootState) => state.user?.showRemoveBatchingFailureItemError || false;
export const getShowBatchingFailureModalSelector = (state: RootState) => state.user?.showBatchingFailureModal || false;
export const getShowMarketingPreferencesPromptSelector =
  (state: RootState) => state.user?.showMarketingPreferencesPrompt || false;
export const getShowMyJLAccountPromptSelector = (state: RootState) => state.user?.showMyJLAccountPrompt || false;
export const getSignOutApiCallActiveSelector = (state: RootState) => state.user?.signOutApiCallActive || false;
export const getUserDobSelector = (state: RootState) => state.user?.userDob;
export const getYotiCheckSuccessSelector = (state: RootState) => state.user?.yotiCheckSuccess || false;
export const getShowYotiConfirmationModalSelector =
  (state: RootState) => state.user?.showYotiConfirmationModal || false;
export const getYotiErrorSelector = (state: RootState) => state.user?.yotiError || 'default';
export const getYotiLoadingSelector = (state: RootState) => state.user?.yotiLoading ?? true;
export const getYotiQueryParamsSelector = (state: RootState) => state.user?.yotiQueryParams || '';
export const getYotiSessionIdSelector = (state: RootState) => state.user?.yotiSessionId;
export const getUserInvalidPOSCreditAddressSelector = (state: RootState) => state.user?.invalidPOSCreditAddress;
export const getUserSelectedResidentialAddressSelector = (state: RootState) => state.user?.selectedResidentialAddress;
export const getUserShouldUseDeliveryAddressAsResidentialAddressSelector =
  (state: RootState) => state.user?.shouldUseDeliveryAddressAsResidentialAddress || false;
export const getUserIsMyJLMemberSelector = (state: RootState) => isMyJLMember(getMyJLMemberStatusSelector(state));
export const getIsPartnerDiscountProcessingSelector =
  (state: RootState) => state.user?.isPartnerDiscountProcessing || false;
export const getEligibleForPartnerDiscountSelector =
  (state: RootState) => state.user?.eligibleForPartnerDiscount || false;
export const getPartnerDiscountStatusSelector = (state: RootState) => state.user?.partnerDiscountStatus;
