import React from 'react';

import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
  MY_JOHN_LEWIS_TERMS_AND_CONDITIONS,
  SEARCH_UK_POSTCODE_BUTTON,
  POSTCODE,
} from 'jl-design-system/form/fields';
import getAddressFieldsConfig from 'jl-design-system/form/configs/address';
import urlConstants from '../../../constants/urlConstants';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export const TERMS_CONDITIONS_PRIVACY_NOTICE = {
  component: () => (
    <p data-testid="termsText">
      By joining you accept the my John Lewis{' '}
      <a
        href={urlConstants.JL_TERMS_CONDITIONS}
        rel="noopener noreferrer"
        target="_blank"
      >terms & conditions
      </a> and our{' '}
      <a
        href={urlConstants.JL_PRIVACY_NOTICE}
        rel="noopener noreferrer"
        target="_blank"
      >privacy notice
      </a>.
    </p>
  ),
  extra: true,
  name: 'privacy_terms',
};

export const MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID = 'myJohnLewisAddressFormSimple';

export function getMyJohnLewisAddressFormSimpleConfig() {
  return {
    id: MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID,
    submitButton: 'Join My John Lewis',
    fields: [
      MY_JOHN_LEWIS_TERMS_AND_CONDITIONS,
      TERMS_CONDITIONS_PRIVACY_NOTICE,
    ],
  };
}

export const MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID = 'myJohnLewisAddressFormEdit';

export function getMyJohnLewisAddressFormEditConfig({
  countryCode,
  addressLines,
  maxAddressLines,
  actions,
  configExtras,
}) {

  const ADDRESS_FIELDS = getAddressFieldsConfig({
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
  });

  const PHONE_NUMBER = getInternationalPhoneNumberConfig();

  return {
    id: MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID,
    submitButton: 'Join My John Lewis',
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
      MY_JOHN_LEWIS_TERMS_AND_CONDITIONS,
      TERMS_CONDITIONS_PRIVACY_NOTICE,
    ],
  };
}

export const MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID = 'myJohnLewisAddressFormNew';

export function getMyJohnLewisAddressFormNewConfig({
  countryCode,
  addressLines,
  maxAddressLines,
  actions,
  configExtras,
  postcodeSearchFormVisible,
}) {

  const { searchForAnotherAddress } = actions;

  const searchForAnotherAddressButton = {
    ...SEARCH_UK_POSTCODE_BUTTON,
    // action: searchForAnotherAddress,
    props: {
      ...SEARCH_UK_POSTCODE_BUTTON.props,
      'data-testid': 'search-for-address-button',
      onClick: searchForAnotherAddress,
    },
  };

  const ADDRESS_FIELDS = !postcodeSearchFormVisible ? getAddressFieldsConfig({
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
    renderAddressFields: !postcodeSearchFormVisible,
  }) : [];

  const PHONE_NUMBER = getInternationalPhoneNumberConfig();

  if (ADDRESS_FIELDS.length > 1) {
    const index = ADDRESS_FIELDS.findIndex(field => field?.id === POSTCODE?.id);
    if (index >= 0) {
      ADDRESS_FIELDS[index].fieldExtra = searchForAnotherAddressButton;
    }
  }

  const TERMS = postcodeSearchFormVisible ? [] : [
    MY_JOHN_LEWIS_TERMS_AND_CONDITIONS,
    TERMS_CONDITIONS_PRIVACY_NOTICE,
  ];

  return {
    id: MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
    submitButton: 'Join My John Lewis',
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
      ...TERMS,
    ],
  };
}
