// single
import searchSingleResult from './mocks/search/searchSingleResult.json';
import selectSingleResult from './mocks/select/selectSingleResult.json';

// three
import searchThreeResultsContainer from './mocks/search/searchThreeResultsContainer.json';
import searchThreeResults from './mocks/search/searchThreeResults.json';
import selectThreeResults1 from './mocks/select/selectThreeResults1.json';
import selectThreeResults2 from './mocks/select/selectThreeResults2.json';
import selectThreeResults3 from './mocks/select/selectThreeResults3.json';

// out of area
import searchOutOfAreaResult from './mocks/search/searchOutOfAreaResult.json';
import selectOutOfAreaResult from './mocks/select/selectOutOfAreaResult.json';

// excatchment gvf
import searchExCatchmentGVFResult from './mocks/search/searchExCatchmentGVFResult.json';
import selectExCatchmentGVFResult from './mocks/select/selectExCatchmentGVFResult.json';

// gvf limited instructions
import searchGreenVanFleetLimitedInstructionsResult from './mocks/search/searchGreenVanFleetLimitedInstructionsResult.json';
import selectGreenVanFleetLimitedInstructionsResult from './mocks/select/selectGreenVanFleetLimitedInstructionsResult.json';

// gvf undeliverable items
import searchGreenVanFleetUndeliverableResult from './mocks/search/searchGreenVanFleetUndeliverableResult.json';
import selectGreenVanFleetUndeliverableResult from './mocks/select/selectGreenVanFleetUndeliverableResult.json';

// d2c undeliverable items
import searchUndeliverableD2CResult from './mocks/search/searchUndeliverableD2CResult.json';
import selectUndeliverableD2CResult from './mocks/select/selectUndeliverableD2CResult.json';

// fields too long
import searchFieldsTooLongResult from './mocks/search/searchFieldsTooLongResult.json';
import selectFieldsTooLongResult from './mocks/select/selectFieldsTooLongResult.json';

// sw1e5nn - specific to journey tests
import searchSW1E5NN from './mocks/search/searchSW1E5NN.json';
import selectSW1E5NN from './mocks/select/selectSW1E5NN.json';

// force loqate 'success' timeout error
import timeoutError from './mocks/search/timeoutError.json';

// isle of man
import isleOfManSearch from './mocks/search/searchIsleOfMan.json';
import isleOfManSelect from './mocks/select/selectIsleOfMan.json';

export default function getLoqateMocks(searchTerm) {
  switch (searchTerm) {
    case 'timeoutError':
      return timeoutError;

    case 'IM2 4RE':
      return isleOfManSearch;

    case 'isleOfManSelect':
      return isleOfManSelect;

    case 'SR74DD':
      return searchSingleResult;

    case 'singleResult':
      return selectSingleResult;

    case 'IV548LS':
      return searchThreeResultsContainer;

    case 'three':
      return searchThreeResults;

    case 'threeResults1':
      return selectThreeResults1;

    case 'threeResults2':
      return selectThreeResults2;

    case 'threeResults3':
      return selectThreeResults3;

    case 'BF13AA':
      return searchOutOfAreaResult;

    case 'outOfAreaResult':
      return selectOutOfAreaResult;

    case 'XX99 1BA':
      return searchExCatchmentGVFResult;

    case 'exCatchmentGVFResult':
      return selectExCatchmentGVFResult;

    case 'XX99 2BA':
      return searchGreenVanFleetLimitedInstructionsResult;

    case 'greenVanFleetLimitedInstructionsResult':
      return selectGreenVanFleetLimitedInstructionsResult;

    case 'XX99 3BA':
      return searchGreenVanFleetUndeliverableResult;

    case 'greenVanFleetUndeliverableResult':
      return selectGreenVanFleetUndeliverableResult;

    case 'EP1A 1JL':
      return searchUndeliverableD2CResult;

    case 'undeliverableD2CResult':
      return selectUndeliverableD2CResult;

    case 'GL34AQ':
      return searchFieldsTooLongResult;

    case 'fieldsTooLongResult':
      return selectFieldsTooLongResult;

    case 'SW1E5NN':
    case 'SW1E5NN ':
    case 'SW1E 5NN':
    case 'SW1E 5NN ':
      return searchSW1E5NN;

    case 'GB|RM|B|23748469':
      return selectSW1E5NN;

    default:
      return undefined;
  }
}
