// Types
import { RootState } from 'types/RootState.types';

// Config
import isPayPalAvailable from './isPayPalAvailable';

export default function isBasketPayPalPayLaterEligible(state: RootState): boolean {
  const deliveriesWithClearabee = state?.delivery?.deliveriesWithClearabee ?? [];
  const outstandingBalanceRaw = Number(state?.orderForm?.amounts?.outstandingBalanceRaw);

  if (
    !isPayPalAvailable(state)
    || deliveriesWithClearabee.length > 0
    || (outstandingBalanceRaw < 30 || outstandingBalanceRaw > 2000)
  ) {
    return false;
  }

  return true;
}
