/* eslint-disable css-modules/no-unused-class */
import React from 'react';

// Design System
import InputManageCursor from 'jl-design-system/elements/input-manage-cursor/InputManageCursor';

// Types
import { InputCreditCardProps } from './InputCreditCard.types';

// Components
import Container from '../../../../components/container';

// Styles
import styles from './payment-types.scss';

const InputCreditCard = ({
  inputWrapperClassName = '',
  ...props
}: InputCreditCardProps) => (
  <Container
    className={styles[inputWrapperClassName]}
    testId="field-wrapper"
  >
    <InputManageCursor {...props} data-testid="input-manage-cursor-field" />
  </Container>
);

export default InputCreditCard;
