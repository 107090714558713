// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

const isPayPalAvailable = (state: RootState) => !isFeatureActive(state, featureConstants.DISABLE_PAYPAL);

export default isPayPalAvailable;
