import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

// Config
import { getIsKlarnaPaymentInProgress } from '../../../redux/reducers/payment/paymentSelector';

// Styles
import styles from './klarna-container.scss';

export const klarnaContainerId = 'klarna-payments-container';

const KlarnaContainer = () => {
  const isKlarnaPaymentInProgress = useSelector(getIsKlarnaPaymentInProgress);

  return (
    <div
      className={cx(styles.klarnaContainer, {
        [styles.showKlarna]: isKlarnaPaymentInProgress,
      })}
      id={klarnaContainerId}
    />
  );
};


export default KlarnaContainer;
