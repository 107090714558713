import React from 'react';
import { format } from 'date-fns';
import { ROYAL_MAIL_OWNER_ID } from '../../constants/clickCollectConstants';
import { isCollectPlusRelatedType } from '../collection/getCollectPlusRelatedStores';

export function getDeliveryMethodText(props) {
  const {
    isGreenVanFleet,
    isGreenVanFleetExCatchment,
    isGreenVanFleetUnavailable,
    method,
  } = props;

  if (isGreenVanFleet) {
    if (isGreenVanFleetExCatchment) {
      return 'UK delivery usually within 7-14 working days';
    }
    if (isGreenVanFleetUnavailable) {
      return 'Standard specialist delivery';
    }
  }
  return method;
}

export function getDeliveryInfoText(props) {
  const {
    additionalInformation,
    collectionTime,
    ownerId,
    deliveryInfo,
    isClickCollectOrder,
    isGreenVanFleet,
    isGreenVanFleetExCatchment,
    isGreenVanFleetUnavailable,
    isGvfSlotGreaterThanTwoHours,
    isMadeToMeasureDelivery,
    isOneManDayDelivery,
    isOneManEveningDelivery,
    isOneManMorningDelivery,
    oneManPremiumDeliverySelectedDate,
  } = props;

  const getCollectionDateTimeText = () => {
    if (isCollectPlusRelatedType(ownerId)) {
      const daysCount = ownerId === ROYAL_MAIL_OWNER_ID ? 18 : 10;

      return (
        <span>
          <p>Your order will be ready to collect from <strong>{format(collectionTime, 'h:mma')}</strong> on <strong>{format(collectionTime, 'dddd D MMMM YYYY')}</strong>.</p>
          <p>
            You&apos;ll be notified when it&apos;s ready for collection & your order will be kept for {daysCount} days.
          </p>
        </span>
      );
    }

    return (
      <span>Your order will be ready to collect from <strong>{format(collectionTime, 'h:mma')}</strong> on <strong>{format(collectionTime, 'dddd D MMMM YYYY')}</strong>. We&apos;ll keep your order for 7 days.</span>
    );
  };

  if (isClickCollectOrder) {
    const collectionDateTimeText = getCollectionDateTimeText();
    return collectionDateTimeText;
  }

  const standardDatePattern = 'dddd D MMMM';

  if (isOneManEveningDelivery) {
    return (
      <span>Arriving on{' '}
        <strong>{format(oneManPremiumDeliverySelectedDate, standardDatePattern)}</strong>{' '}
        between <strong>6pm&nbsp;-&nbsp;11pm</strong>
      </span>
    );
  }

  if (isOneManMorningDelivery) {
    return (
      <span>Arriving on{' '}
        <strong>{format(oneManPremiumDeliverySelectedDate, standardDatePattern)}</strong>{' '}
        between <strong>7am&nbsp;-&nbsp;11:30am</strong>
      </span>
    );
  }

  if (isOneManDayDelivery) {
    return (
      <span>Arriving on{' '}
        <strong>{format(oneManPremiumDeliverySelectedDate, standardDatePattern)}</strong>{' '}
        between <strong>8am&nbsp;-&nbsp;8pm</strong>
      </span>
    );
  }

  if (isMadeToMeasureDelivery) {
    if (!deliveryInfo?.date) {
      return additionalInformation;
    }

    return (
      <span>
        Your order will be delivered on{' '}
        <strong>{format(deliveryInfo.date, standardDatePattern)}</strong>.
      </span>
    );
  }

  if (isGreenVanFleetExCatchment || isGreenVanFleetUnavailable) {
    return "We'll contact you within 2 working days to arrange a delivery time convenient for you.";
  }

  if (isGreenVanFleet) {
    const showAdditionalInfo = isGvfSlotGreaterThanTwoHours;
    const { date, startTime, endTime } = deliveryInfo;
    const deliveryInfoText = showAdditionalInfo ?
      (
        <span>
          Arriving on <strong>{format(date, standardDatePattern)}</strong>{' '}
          between <strong>{startTime} - {endTime}</strong>.{' '}
          The evening before your delivery we&apos;ll confirm a 2-hour delivery window.
        </span>
      ) :
      (
        <span>
          Arriving on <strong>{format(date, standardDatePattern)}</strong>{' '}
          between <strong>{startTime} - {endTime}</strong>.
        </span>
      );
    return deliveryInfoText;
  }

  return additionalInformation;
}
