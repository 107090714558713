// lodash
import get from 'lodash/get';

import appConstants from '../../constants/appConstants';
import errorCodeConstants from '../../constants/errorCodeConstants';
import {
  reportErrorToNewRelic,
  setCorrelationIdToNewRelic,
  createNewRelicTrackingObject,
  saveNewRelicTrackingEvent,
  endNewRelicTrackingEvent,
} from '../logging/logging-utils';
import getCorrelationId from '../helpers/correlationId';
import fetchWithTimeout from '../helpers/fetchWithTimeout';
import getAuthorizationHeader from './getAuthorizationHeader';

function removeTrailingSlash(url = '') {
  return url.replace(/\/$/g, '');
}

function getSessionExpiryData(headers) {
  if (!headers || !headers.get('session-health')) {
    return null;
  }
  return {
    sessionExpiresIn: headers.get('session-health'),
    timestamp: Date.parse(headers.get('date')),
  };
}

function getPropsFromHeaders(sessionId, sessionExpiryData, correlationId, applicationType) {
  if (
    !sessionId &&
    !sessionExpiryData &&
    !applicationType
  ) {
    return undefined;
  }
  return {
    ...(sessionExpiryData ? { sessionExpiryData } : {}),
    ...(sessionId ? { sessionId } : {}),
    ...(correlationId ? { correlationId } : {}),
    ...(applicationType ? { applicationType } : {}),
  };
}

async function api(params) {
  const {
    path,
    config,
    hostUrl = '',
    timeout = appConstants.BFF_TIMEOUT,
    getState = () => ({}),
    dispatch = () => {},
  } = params;

  const { method, body, headers } = config;
  const correlationId = getCorrelationId();

  const authorizationHeader = await getAuthorizationHeader({ path, getState, dispatch });

  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'JL-UI-CORRELATION-ID-V1': correlationId,
      ...authorizationHeader,
      ...headers,
    },
    credentials: 'include',
    timeout,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const host = removeTrailingSlash(hostUrl || window.env.API_HOST);

  const sessionNewRelicTrackingObject = createNewRelicTrackingObject();
  saveNewRelicTrackingEvent(sessionNewRelicTrackingObject);

  return fetchWithTimeout(`${host}/${path}`, options) // eslint-disable-line no-undef
    .then((response) => {
      const sessionId = response.headers.get('JL-SESSION-ID');
      const sessionExpiryData = getSessionExpiryData(response.headers);
      const applicationTypeHeader = response.headers.get('Application-Type');

      setCorrelationIdToNewRelic(correlationId);

      endNewRelicTrackingEvent(sessionNewRelicTrackingObject);

      const propsFromHeaders = getPropsFromHeaders(sessionId, sessionExpiryData, correlationId, applicationTypeHeader);

      if (response.status === 204 || response.status === 205) {
        const json = propsFromHeaders ? { propsFromHeaders } : undefined;
        return Promise.resolve(json);
      }

      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject({
            json: {
              ...json,
              status: response.status,
              ...(propsFromHeaders ? { propsFromHeaders } : {}),
            },
          });
        }

        return Promise.resolve({
          ...json,
          ...(propsFromHeaders ? { propsFromHeaders } : {}),
        });

      }).catch((error = {}) => {

        if ([422, 400, 403, 404, 500, 502, 503, 504].includes(response.status)) {
          dispatch(reportErrorToNewRelic({
            error,
            errorDescription: 'api error',
            options: {
              code: get(error, 'json.code'),
              message: get(error, 'json.message'),
              status: get(error, 'json.status'),
              correlationId,
            },
          }));
        }

        if (response.ok) {
          return Promise.resolve(propsFromHeaders);
        }

        // JSON parsing failure
        if (!error.json) {
          return Promise.reject({
            code: errorCodeConstants.CLIENT_UNKNOWN_TECHNICAL_ERROR, status: response.status,
          });
        }

        // error with valid JSON
        return Promise.reject({
          ...error.json,
          ...(propsFromHeaders ? { propsFromHeaders } : {}),
        });

      });

    }).catch((error = {}) => {

      if (error.name === 'AbortError') {
        dispatch(reportErrorToNewRelic({
          error,
          errorDescription: 'AbortError',
          options: {
            code: errorCodeConstants.CLIENT_API_TIMEOUT,
            message: error.message,
            correlationId,
          },
        }));

        return Promise.reject({
          code: errorCodeConstants.CLIENT_API_TIMEOUT,
        });
      }

      if (!error.status) {
        return Promise.reject({
          code: errorCodeConstants.CLIENT_CONNECTIVITY_ERROR,
        });
      }

      // rejects from response.json().catch()
      return Promise.reject(error);

    });
}

export default api;
