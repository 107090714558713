import { differenceInMilliseconds } from 'date-fns';

// Design System
import { LOQATE_ADDRESS_SEARCH } from 'jl-design-system/redux/actions/addressSearch/addressSearchActionConstants';

// Config
import { getAppApplicationTypeSelector } from './appSelector';
import { isFeatureActive } from '../config/configReducer';
import {
  GET_LOGIN_PAGE,
  GET_DELIVERY_PAGE,
  SET_ACTIVE_TOOLTIP_ID,
  GET_ORDER_CONFIRMATION_PAGE,
  INIT_PAYMENT_PAGE,
  GET_PAYMENT_PAGE,
  SUBMIT_ORDER,
  SET_PAGE_NOT_FOUND,
  HAND_OVER,
  LOGIN_RESET,
  SET_SCROLL_TO_DELIVERY_DETAILS,
  SESSION_EXPIRED,
  SESSION_REFRESH,
  REDUX_HYDRATE,
  REBATCH_ORDER,
  SHOW_DISABLE_SITE_SPINNER,
  HIDE_DISABLE_SITE_SPINNER,
  ROUTER_LOCATION_CHANGE,
  SHOW_EMPTY_BASKET_NOTIFICATION,
  AUTH0_CALLBACK,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
  GOOGLE_PAY_STUBS_ENABLED,
  LEAVE_CHECKOUT,
  CLOSE_LEAVE_CHECKOUT_MODAL,
  ANNOUNCE_SESSION_EXPIRING,
  ANNOUNCE_SESSION_EXPIRED,
  OPEN_SESSION_ENDED_MODAL,
  CLOSE_SESSION_ENDED_MODAL,
  POST_DELIVERY_PAGE,
  POST_DELIVERY_PAGE_NO_ACTION,
  SHOW_MORE_SHOPS,
  SELECT_COLLECTION_POINT,
  NEW_COLLECTION_POINT_SEARCH,
  FIND_COLLECTION_POINT,
  AUTH0_HEALTH_CHECK,
  SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR,
  SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS,
  PAYPAL_PAYMENT_STARTED,
  AUTH0_CLAIM_ORDER_CALLBACK,
  AUTH0_REGISTRATION_CALLBACK,
  APPLE_PAY_SET_CARD_TYPE,
  APPLE_PAY_SUBMIT_PAYMENT_DATA,
  REDUX_FORM_SET_SUBMIT_FAILED,
  GET_COLLECTION_POINT_DATES,
  GET_DELIVERY_METHODS,
  MONETATE_SCRIPT_LOADED,
  MONETATE_SCRIPT_LOADING,
  MONETATE_SCRIPT_FAILED,
  GTM_SCRIPT_APPENDED,
  RECORD_IMPRESSIONS,
  SAVE_TIME_TO_DELIVERY_PAGE,
  SAVE_TIME_TO_COLLECTION_DATES,
  SAVE_TIME_TO_DELIVERY_METHODS,
  DELIVERY_TIMINGS_RECORDED,
  SAVE_TIME_TO_AUTH0TOKEN,
  LAZY_COMPONENT_LOADING,
  SHOW_EXPRESS_PAYMENTS,
  SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED,
  PAYMENT_TIMINGS_RECORDED,
  SAVE_TIME_PLACE_ORDER_CLICKED,
  CONFIRMATION_TIMINGS_RECORDED,
  SET_GIFT_RECEIPT_SELECTED,
  SET_GIFT_RECEIPT_VALUE,
  SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE,
  APPLY_REWARDS,
  SET_TOAST_HAS_SHOWN,
  SET_FAKE_SESSION_ENDED,
  TOGGLE_PRICE_PROMISE_MODAL,
  RAVELIN_INSTANCE_LOADED,
  THRESHOLD_BAR_SHOWN,
} from '../../../constants/actionConstants';
import { WEB, MOB, DROID } from '../../../constants/applicationTypeConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import featureConstants from '../../../constants/featureConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import routeConstants from '../../../constants/routeConstants';

export const isWeb = state => (
  (getAppApplicationTypeSelector(state) ?? WEB) === WEB
);

export const isIosApp = state => (
  getAppApplicationTypeSelector(state) === MOB
);

export const isApps = state => (
  (getAppApplicationTypeSelector(state) ?? WEB) !== WEB ||
  isFeatureActive(state, featureConstants.FORCE_CUSTOMER_APP_TYPE)
);

export const isCustomerApp = state => (
  [MOB, DROID].includes(getAppApplicationTypeSelector(state) ?? '') ||
  isFeatureActive(state, featureConstants.FORCE_CUSTOMER_APP_TYPE)
);

export const isAndroidApp = state => getAppApplicationTypeSelector(state) === DROID;

export const getPlatformType = (state) => {
  if (isAndroidApp(state)) return 'android';
  if (isIosApp(state)) return 'ios';
  return 'web';
};

function getLoadingMessage(params) {
  const {
    key,
    isPosCreditPayment,
    isGuest,
    showAccountPrompt,
    existingMessage,
  } = params;

  const messageMap = {
    SUBMIT_ORDER: isPosCreditPayment ? 'Please wait a moment' : 'Processing your order',
    GET_ORDER_CONFIRMATION_PAGE: !isGuest && showAccountPrompt ? 'Creating your account' : 'Getting your receipt',
  };

  return messageMap[key] || existingMessage || '';
}

export const INITIAL_STATE = {
  isLoading: false,
  loadingMessage: '',
  actionsLoaded: {},
  activeTooltipID: null,
  pageNotFound: false,
  orderComplete: false,
  orderConfirmationPageSuccess: false,
  modalOpen: false,
  applicationType: undefined,
  submitOrderTimeout: false,
  orderProcessing: false,
  scrollToDeliveryDetails: false,
  scrollToPartnershipDiscountToggle: false,
  firstRequestToApp: true,
  sessionExpired: false,
  googlePayStubsEnabled: false,
  showEmptyBasketNotification: false,
  showConnectivityErrorModal: false,
  showLeaveCheckout: false,
  sessionExpiringAnnounced: false,
  sessionExpiredAnnounced: false,
  showSessionEndedModal: false,
  allLoqateCallsFailed: undefined,
  showDisableSiteSpinner: false,
  auth0HealthCheckLoading: false,
  posCreditSessionExpired: false,
  threeDSFailureCount: 0,
  isInvalidPartnerDiscount: false,
  isLoginRequired: false,
  isExpressCheckout: false,
  loadingLazyComponent: '',
  timestamps: undefined,
  recordedImpressionIds: [],
  deliveryTimingsRecorded: false,
  paymentTimingsRecorded: false,
  confirmationTimingsRecorded: false,
  defaultGiftMessageValue: {},
  isGiftReceiptSelected: {},
  hasToastBeenShown: false,
};

export const handoverLoadingMessage = (action = {}) => {
  const {
    requestPayload: {
      express,
    } = {},
  } = action;

  if (express) return 'Creating your Express checkout...';

  return '';
};

export default function appReducer(state = INITIAL_STATE, action = {}) {
  const actionType = action?.type ?? '';

  if (actionType?.includes('.FAILED')) {
    // TODO why do we group failures here?

    const applicationType = action?.error?.propsFromHeaders?.applicationType;
    const errorCode = action?.error?.code;

    if (errorCode === errorCodeConstants.CLIENT_CONNECTIVITY_ERROR) {
      return {
        ...state,
        showConnectivityErrorModal: true,
      };
    }

    if (errorCode === errorCodeConstants.ORDER_ALREADY_PLACED) {
      return {
        ...state,
        isLoading: false,
        orderComplete: true,
      };
    }

    if ([
      errorCodeConstants.ORDER_FORM_EMPTY,
      errorCodeConstants.ORDER_FORM_NOT_FOUND,
    ].includes(errorCode)) {
      return {
        ...state,
        isLoading: false,
        showDisableSiteSpinner: false,
        loadingMessage: '',
        applicationType,
        showEmptyBasketNotification: true,
      };
    }

    if ([
      errorCodeConstants.SESSION_EXPIRED,
      errorCodeConstants.CHECKOUT_SESSION_TOKEN_MISSED,
      errorCodeConstants.CHECKOUT_SESSION_TOKEN_EXPIRED,
      errorCodeConstants.CHECKOUT_SESSION_TOKEN_MALFORMED,
    ].includes(errorCode)) {

      return {
        ...state,
        applicationType,
        sessionExpired: true,
        isLoading: false,
        showDisableSiteSpinner: false,
        sessionExpiredAnnounced: true,
      };
    }

    if (actionType === `${APPS_AUTHENTICATED_HANDOVER}.FAILED`) {
      return {
        ...state,
        isLoading: false,
        showDisableSiteSpinner: false,
        loadingMessage: '',
        applicationType,
      };
    }

    if (actionType !== `${REBATCH_ORDER}.FAILED` && state.rebatchOrderApiCallActive) {
      return state;
    }

    if (actionType && actionType.includes(`${LOQATE_ADDRESS_SEARCH}.FAILED`)) {
      return {
        ...state,
        allLoqateCallsFailed: state.allLoqateCallsFailed !== undefined ? state.allLoqateCallsFailed : true,
      };
    }

    if (actionType === `${AUTH0_HEALTH_CHECK}.FAILED`) {
      return {
        ...state,
        auth0HealthCheckLoading: false,
      };
    }

    if (actionType === `${GET_PAYMENT_PAGE}.FAILED` && action.error?.code === errorCodeConstants.ORDERING_IN_PROGRESS) {
      return {
        ...state,
        submitOrderTimeout: true,
      };
    }

    if (actionType === `${SESSION_REFRESH}.FAILED`) {
      return {
        ...state,
        sessionRefreshAPICallActive: false,
      };
    }

    if (actionType === `${APPLY_REWARDS}.FAILED`) {
      return state;
    }

    return {
      ...state,
      isLoading: false,
      showDisableSiteSpinner: false,
      loadingMessage: '',
      orderProcessing: (actionType === `${SUBMIT_ORDER}.FAILED`) ? false : state.orderProcessing,
    };
  }

  const actionName = actionType.split('.')[0];
  const applicationType = action?.result?.propsFromHeaders?.applicationType ?? state.applicationType;

  switch (actionType) {

    case `${APPS_GUEST_HANDOVER}.LOADING`:
    case `${APPS_AUTHENTICATED_HANDOVER}.LOADING`: {
      return {
        ...state,
        timestamps: action.requestPayload?.timestamps,
        fromAuth0: action.requestPayload.fromAuth0,
        loadingMessage: handoverLoadingMessage(action),
        isExpressCheckout: action.requestPayload?.express,
      };
    }

    case `${HAND_OVER}.LOADING`:
      return {
        ...state,
        isLoading: true,
        loadingMessage: handoverLoadingMessage(action),
        isExpressCheckout: action.requestPayload?.express,
        timestamps: action.requestPayload?.timestamps,
      };

    case `${LAZY_COMPONENT_LOADING}.LOADING`:
      return {
        ...state,
        loadingLazyComponent: action.componentName,
      };

    case `${LAZY_COMPONENT_LOADING}.SUCCESS`:
      return {
        ...state,
        loadingLazyComponent: '',
      };

    case SET_GIFT_RECEIPT_SELECTED: {
      const newValues = state.isGiftReceiptSelected;
      newValues[action.giftFormId] = action.isSelected;

      return {
        ...state,
        isGiftReceiptSelected: newValues,
      };
    }
    case SET_GIFT_RECEIPT_VALUE: {
      const newValues = state.defaultGiftMessageValue;
      newValues[action.giftFormId] = action.value;

      return {
        ...state,
        defaultGiftMessageValue: newValues,
      };
    }
    case SHOW_DISABLE_SITE_SPINNER:
      return {
        ...state,
        isLoading: true,
        showDisableSiteSpinner: true,
      };

    case HIDE_DISABLE_SITE_SPINNER:
      return {
        ...state,
        isLoading: false,
        showDisableSiteSpinner: false,
      };

    case `${REBATCH_ORDER}.LOADING`:
      return {
        ...state,
        isLoading: true,
        showDisableSiteSpinner: true,
        rebatchOrderApiCallActive: true,
      };

    case GOOGLE_PAY_STUBS_ENABLED:
      return {
        ...state,
        googlePayStubsEnabled: true,
      };

    case `${REBATCH_ORDER}.SUCCESS`:
      return {
        ...state,
        isLoading: false,
        showDisableSiteSpinner: false,
        rebatchOrderApiCallActive: false,
      };

    case REDUX_HYDRATE: {
      const payload = action?.payload?.app ?? {};

      return {
        ...state,
        ...payload,
      };
    }

    case SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpired: true,
        isLoading: false,
        showDisableSiteSpinner: false,
      };
    }

    case SET_FAKE_SESSION_ENDED:
    case OPEN_SESSION_ENDED_MODAL: {
      return {
        ...state,
        showSessionEndedModal: true,
      };
    }

    case CLOSE_SESSION_ENDED_MODAL: {
      return {
        ...state,
        showSessionEndedModal: false,
      };
    }

    case ANNOUNCE_SESSION_EXPIRING: {
      return {
        ...state,
        sessionExpiringAnnounced: true,
      };
    }

    case ANNOUNCE_SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpiredAnnounced: true,
      };
    }

    case LEAVE_CHECKOUT: {
      return {
        ...state,
        showLeaveCheckout: true,
        leaveCheckoutModalBaseRoutePath: action.baseRoutePath,
      };
    }

    case CLOSE_LEAVE_CHECKOUT_MODAL: {
      return {
        ...state,
        showLeaveCheckout: false,
      };
    }

    case `${SESSION_REFRESH}.LOADING`: {
      return {
        ...state,
        sessionRefreshAPICallActive: true,
      };
    }

    case `${SESSION_REFRESH}.SUCCESS`: {
      return {
        ...state,
        sessionExpired: false,
        sessionExpiringAnnounced: false,
        sessionExpiredAnnounced: false,
        sessionRefreshAPICallActive: false,
      };
    }

    case `${GET_LOGIN_PAGE}.LOADING`:
    case `${GET_DELIVERY_PAGE}.LOADING`:
    case `${POST_DELIVERY_PAGE}.LOADING`:
    case `${POST_DELIVERY_PAGE_NO_ACTION}.LOADING`:
    case `${INIT_PAYMENT_PAGE}.LOADING`:
    case `${SUBMIT_ORDER}.LOADING`:
    case PAYPAL_PAYMENT_STARTED:
    case `${GET_ORDER_CONFIRMATION_PAGE}.LOADING`: {

      if ([
        `${INIT_PAYMENT_PAGE}.LOADING`,
      ].includes(action.type)) {
        if (action.pushAfter) {
          return state;
        }
      }

      const isDelivery = [
        `${GET_DELIVERY_PAGE}.LOADING`,
        `${POST_DELIVERY_PAGE}.LOADING`,
        `${POST_DELIVERY_PAGE_NO_ACTION}.LOADING`,
      ].includes(action.type);

      const isRebatch = action?.eventDetails?.isRebatch;

      if (isDelivery
        && (state.actionsLoaded[GET_DELIVERY_PAGE] || state.actionsLoaded[POST_DELIVERY_PAGE])) {
        if (isRebatch) {
          return {
            ...state,
            isLoading: true,
            showDisableSiteSpinner: true,
          };
        }

        return state;
      }

      const loadingState = (isDelivery && !isRebatch) ? {
        isLoading: true,
        showDisableSiteSpinner: false,
      } : {
        isLoading: true,
        showDisableSiteSpinner: isRebatch ? true : state.showDisableSiteSpinner,
      };

      const isPosCreditPayment = action?.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false;

      const nextState = {
        ...state,
        ...loadingState,
        loadingMessage: getLoadingMessage({
          key: actionName,
          isPosCreditPayment,
          isGuest: action.isGuest,
          showAccountPrompt: action.showAccountPrompt,
          existingMessage: state.loadingMessage,
        }),
      };

      if (actionType === `${SUBMIT_ORDER}.LOADING`) {
        return {
          ...nextState,
          orderProcessing: true,
        };
      }

      return nextState;
    }
    case `${GET_LOGIN_PAGE}.SUCCESS`:
    case `${GET_DELIVERY_PAGE}.SUCCESS`:
    case `${POST_DELIVERY_PAGE}.SUCCESS`:
    case `${INIT_PAYMENT_PAGE}.SUCCESS`:
    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {

      const nextState = {
        ...state,
        applicationType,
        isLoading: false,
        showDisableSiteSpinner: false,
        loadingMessage: '',
        actionsLoaded: {
          ...state.actionsLoaded,
          [actionName]: true,
        },
      };

      if (actionType === `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`) {
        return {
          ...nextState,
          orderComplete: true,
          orderConfirmationPageSuccess: true,
          orderProcessing: false,
        };
      }

      return nextState;
    }

    case `${SUBMIT_ORDER}.TIMEOUT`:
      return {
        ...state,
        submitOrderTimeout: true,
      };

    case SET_ACTIVE_TOOLTIP_ID: {
      const { tooltipID } = action;
      return {
        ...state,
        activeTooltipID: tooltipID,
      };
    }
    case SET_PAGE_NOT_FOUND:
      return {
        ...state,
        pageNotFound: true,
      };

    case SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR:
      return {
        ...state,
        posCreditSessionExpired: true,
        isInvalidPartnerDiscount: action.isInvalidPartnerDiscount,
        isLoginRequired: action.isLoginRequired,
      };

    case ROUTER_LOCATION_CHANGE: {
      const pathname = action?.payload?.location?.pathname ?? '';
      const isLoginRoute = pathname?.includes(routeConstants.LOGIN) || false;
      const isWebHandoverRoute = pathname?.includes(routeConstants.WEB_HANDOVER) || false;
      const isAppsHandoverRoute = pathname?.includes(routeConstants.APPS_HANDOVER) || false;
      const newSession = isLoginRoute || isAppsHandoverRoute || isWebHandoverRoute;
      const pageRefreshedWhileOrderProcessing = state.firstRequestToApp && state.orderProcessing;

      return {
        ...state,
        firstRequestToApp: false,
        pageNotFound: false,
        activeTooltipID: null,
        orderComplete: newSession ? false : state.orderComplete,
        orderConfirmationPageSuccess: false,
        posCreditSessionExpired: newSession || state.isLoginRequired ? false : state.posCreditSessionExpired,
        submitOrderTimeout: newSession ? false : state.submitOrderTimeout || pageRefreshedWhileOrderProcessing,
        sessionExpired: newSession ? false : state.sessionExpired,
        allLoqateCallsFailed: undefined,
        orderProcessing: newSession ? false : state.orderProcessing,
        threeDSFailureCount: newSession ? 0 : state.threeDSFailureCount,
        recordedImpressionIds: newSession ? [] : state.recordedImpressionIds,
        deliveryTimingsRecorded: newSession ? false : state.deliveryTimingsRecorded,
        paymentTimingsRecorded: newSession ? false : state.paymentTimingsRecorded,
        confirmationTimingsRecorded: newSession ? false : state.confirmationTimingsRecorded,
        isGiftReceiptSelected: newSession ? {} : state.isGiftReceiptSelected,
        defaultGiftMessageValue: newSession ? {} : state.defaultGiftMessageValue,
        hasToastBeenShown: newSession ? false : state.hasToastBeenShown,
      };
    }

    case SET_SCROLL_TO_DELIVERY_DETAILS:
      return {
        ...state,
        scrollToDeliveryDetails: action.value,
      };
    case SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE:
      return {
        ...state,
        scrollToPartnershipDiscountToggle: action.value,
      };
    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${HAND_OVER}.SUCCESS`: {

      return {
        ...state,
        applicationType,
        sessionExpiredAnnounced: false,
      };
    }
    case LOGIN_RESET:
      return {
        ...state,
        orderProcessing: false,
        scrollToDeliveryDetails: false,
        scrollToPartnershipDiscountToggle: false,
        showExpressPayments: false,
      };

    case SHOW_EMPTY_BASKET_NOTIFICATION:
      return {
        ...state,
        showEmptyBasketNotification: true,
      };

    case `${AUTH0_CALLBACK}.LOADING`:
      return {
        ...state,
        isLoading: true,
      };

    case `${AUTH0_CALLBACK}.SUCCESS`:
      return {
        ...state,
        fromAuth0: action.body?.fromAuth0,
      };

    case `${LOQATE_ADDRESS_SEARCH}.SUCCESS`:
      return {
        ...state,
        allLoqateCallsFailed: false,
      };
    case `${LOQATE_ADDRESS_SEARCH}.NETWORK_TIMEOUT`:
      return {
        ...state,
        allLoqateCallsFailed: state.allLoqateCallsFailed !== undefined ? state.allLoqateCallsFailed : true,
      };

    case SHOW_MORE_SHOPS:
      return {
        ...state,
        shopsToShow: action?.shopsToShow,
      };

    case SELECT_COLLECTION_POINT:
    case NEW_COLLECTION_POINT_SEARCH:
    case `${FIND_COLLECTION_POINT}.LOADING`:
      return {
        ...state,
        shopsToShow: action?.condensed ? state.shopsToShow : undefined,
      };

    case `${AUTH0_HEALTH_CHECK}.LOADING`:
      return {
        ...state,
        auth0HealthCheckLoading: true,
      };

    case SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS:
      return {
        ...state,
        threeDSFailureCount: action.threeDSFailureCount,
        newday3DSFailure: action.newdayCardType,
      };

    case `${AUTH0_REGISTRATION_CALLBACK}.LOADING`:
    case `${AUTH0_CLAIM_ORDER_CALLBACK}.LOADING`: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case `${AUTH0_CLAIM_ORDER_CALLBACK}.SUCCESS`: {
      return {
        ...state,
        isLoading: action.result.validSession,
      };
    }

    /* begin apple pay loading */
    case `${APPLE_PAY_SET_CARD_TYPE}.LOADING`:
    case `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.LOADING`: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.SUCCESS`:
    case `${APPLE_PAY_SET_CARD_TYPE}.FAILURE`:
    case `${APPLE_PAY_SUBMIT_PAYMENT_DATA}.FAILURE`: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case REDUX_FORM_SET_SUBMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case `${GET_COLLECTION_POINT_DATES}.SUCCESS`: {
      if ((action.result.collectionDates?.length || 0) === 0) {
        return {
          ...state,
          isLoading: false,
        };
      }

      return state;
    }

    case `${GET_DELIVERY_METHODS}.SUCCESS`: {
      const methods = action.result.methods || [];
      const fulfilmentOfferIdExists = methods.some(method => !!method.fulfilmentOffers.fulfilmentOfferId);

      if (!fulfilmentOfferIdExists) {
        return {
          ...state,
          isLoading: false,
        };
      }

      return state;
    }

    case MONETATE_SCRIPT_LOADING: {
      return {
        ...state,
        monetateScriptLoading: true,
      };
    }

    case MONETATE_SCRIPT_LOADED: {
      return {
        ...state,
        monetateScriptLoading: false,
        monetateScriptLoaded: true,
      };
    }

    case MONETATE_SCRIPT_FAILED: {
      return {
        ...state,
        monetateScriptLoading: false,
        monetateScriptLoaded: false,
        monetateScriptFailed: true,
      };
    }

    case RAVELIN_INSTANCE_LOADED: {
      return {
        ...state,
        ravelinInstance: action.payload,
      };
    }

    case GTM_SCRIPT_APPENDED: {
      return {
        ...state,
        gtmScriptAppended: true,
      };
    }

    case `${RECORD_IMPRESSIONS}.LOADING`: {
      const impressionId = action.impressionId;
      const recordedImpressionIds = state.recordedImpressionIds || [];

      if (!recordedImpressionIds?.includes(impressionId)) recordedImpressionIds.push(impressionId);

      return {
        ...state,
        recordedImpressionIds,
      };
    }

    case SAVE_TIME_TO_AUTH0TOKEN: {
      const {
        timestamps: {
          timeContinueToCheckoutClicked,
        } = {},
      } = state;

      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timeToAuth0Complete: differenceInMilliseconds(action.timeToAuth0Complete, timeContinueToCheckoutClicked),
        },
      };
    }

    case SAVE_TIME_TO_DELIVERY_PAGE: {
      const {
        timestamps: {
          timeContinueToCheckoutClicked,
        } = {},
      } = state;

      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timeToDeliveryPage: differenceInMilliseconds(action.timeToDeliveryPage, timeContinueToCheckoutClicked),
        },
      };
    }

    case SAVE_TIME_TO_COLLECTION_DATES: {
      const {
        timestamps: {
          timeContinueToCheckoutClicked,
        } = {},
      } = state;

      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timeToCollectionDates: differenceInMilliseconds(action.timeToCollectionDates, timeContinueToCheckoutClicked),
        },
      };
    }

    case SAVE_TIME_TO_DELIVERY_METHODS: {
      const {
        timestamps: {
          timeContinueToCheckoutClicked,
        } = {},
      } = state;

      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timeToDeliveryMethods: differenceInMilliseconds(action.timeToDeliveryMethods, timeContinueToCheckoutClicked),
        },
      };
    }

    case SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED: {
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timeContinueToPaymentClicked: action.timeContinueToPaymentClicked,
        },
      };
    }

    case DELIVERY_TIMINGS_RECORDED: {
      return {
        ...state,
        deliveryTimingsRecorded: true,
      };
    }

    case PAYMENT_TIMINGS_RECORDED: {
      return {
        ...state,
        paymentTimingsRecorded: true,
      };
    }

    case CONFIRMATION_TIMINGS_RECORDED: {
      return {
        ...state,
        confirmationTimingsRecorded: true,
      };
    }

    case SHOW_EXPRESS_PAYMENTS: {
      return {
        ...state,
        showExpressPayments: action.shouldShow,
        isApplePayExpressEnabled: action.isApplePayExpressEnabled,
        isPayPalPayExpressEnabled: action.isPayPalPayExpressEnabled,
      };
    }

    case SAVE_TIME_PLACE_ORDER_CLICKED: {
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          timePlaceOrderClicked: action.timePlaceOrderClicked,
        },
      };
    }

    case SET_TOAST_HAS_SHOWN: {
      return {
        ...state,
        hasToastBeenShown: true,
      };
    }

    case TOGGLE_PRICE_PROMISE_MODAL: {
      return {
        ...state,
        showPricePromiseModal: action?.showModal,
      };
    }

    case THRESHOLD_BAR_SHOWN: {
      return {
        ...state,
        thresholdBarShown: true,
      };
    }

    default:
      return state;
  }
}
