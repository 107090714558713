import { push } from 'connected-react-router';
import routeConstants from '../../../constants/routeConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import deliveryConstants from '../../../constants/deliveryConstants';

import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import {
  recordCollectionPaymentPageTimings,
  recordDeliveryPaymentPageTimings,
  recordPaymentTimings,
} from '../timing/timingActions';
import {
  ANALYTICS_DELIVERY_OPTIONS,
  ANALYTICS_SUBMIT_ORDER_ERROR,
  APPLE_PAY_SELECTED,
  GET_PAYMENT_PAGE,
  GET_PAYMENT_PAGE_NO_ACTION,
  GOOGLE_PAY_DEFAULT,
  GOOGLE_PAY_ENABLED,
  INIT_PAYMENT_PAGE,
  INIT_PAYMENT_PAGE_NO_ACTION,
  SAVED_CARD_SELECTED,
  VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE,
} from '../../../constants/actionConstants';
import {
  URL_PAYMENT_PAGE,
} from '../../../constants/endpointConstants';
import { canMakeGooglePayPayments } from './googlePayActions';
import { getItems } from '../edit-basket/editBasketActions';
import {
  getDeliveryMethodsAnalytics,
} from '../delivery/deliveryActions';
import { selectClickAndCollectStoreAnalytics } from '../../../utils/analytics/utils/getCollectionAnalytics';
import handleInconsistentOrderFormState from '../../../utils/orderform/handleInconsistentOrderFormState';
import { mergeItemResponse } from '../../../utils/orderform/mergeItemResponse';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import featureConstants from '../../../constants/featureConstants';
import { getPayPalClientToken } from './payPalPaymentActions';
import determineSelectedPaymentType from './selectedPaymentTypeActions';
import { isFeatureActive } from '../../reducers/config/configReducer';

const dispatchPayOptionAnalytics = customerCanMakeGooglePayPayments => async (dispatch, getState) => {
  // `payment.selectedPaymentType` should be taken from actual state after success InitPaymentPage
  const selectedPaymentType = getState().payment?.selectedPaymentType;
  if (selectedPaymentType?.includes(paymentTypeConstants.SAVED_PAYMENT_CARD)) {
    dispatch(triggerAnalyticsEvent(SAVED_CARD_SELECTED));
  }

  const applePaySelected = selectedPaymentType?.includes(paymentTypeConstants.APPLE_PAY);
  if (applePaySelected) {
    dispatch(triggerAnalyticsEvent(APPLE_PAY_SELECTED));
  }

  if (customerCanMakeGooglePayPayments) {
    dispatch(triggerAnalyticsEvent(GOOGLE_PAY_ENABLED));
    if (selectedPaymentType?.includes(paymentTypeConstants.GOOGLE_PAY)) {
      dispatch(triggerAnalyticsEvent(GOOGLE_PAY_DEFAULT));
    }
  }
};

export const initPaymentPage = (params = {}) => async (dispatch, getState) => {
  const {
    getOnly,
    eventDetails,
    pushAfter,
    shouldRecordDeliveryTimings,
  } = params;

  const {
    app,
    clickAndCollect,
    orderForm,
    delivery,
    payment,
  } = getState();

  if (payment?.getPaymentPageCallActive) {
    // try to stop mysterious duplicate calls to payment-page
    return {};
  }

  const selectedCollectionPoint = clickAndCollect?.selectedCollectionPoint;
  const deliveries = delivery?.deliveries ?? [];
  const confirmedDeliveryAddress = delivery?.confirmedDeliveryAddress;
  const selectedDeliveryChoiceId = delivery?.selectedDeliveryChoiceId;
  const orderNeedsToBeRebatched = orderForm?.orderNeedsToBeRebatched;

  const disableInitPaymentPage = orderNeedsToBeRebatched ||
    (app?.orderComplete ?? false) ||
    (app?.orderProcessing ?? false);

  if (disableInitPaymentPage) {
    return {};
  }

  const notReadyForPayment = (
    (selectedDeliveryChoiceId === deliveryConstants.DELIVERY && !confirmedDeliveryAddress) ||
    (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT && !selectedCollectionPoint) ||
    !selectedDeliveryChoiceId
  );

  if (notReadyForPayment) {
    const pathname = selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT ?
      routeConstants.CLICK_AND_COLLECT : routeConstants.DELIVERY;
    dispatch(push({ pathname }));
    return {};
  }

  if (selectedDeliveryChoiceId === deliveryConstants.DELIVERY) {
    dispatch(getDeliveryMethodsAnalytics(delivery?.deliveries));
  }

  if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {

    const searchTerm = clickAndCollect?.collectionPointSearchTerm;
    const collectionDates = clickAndCollect?.collectionPointDates;
    const analytics = selectClickAndCollectStoreAnalytics(
      selectedCollectionPoint,
      searchTerm,
      collectionDates,
    );

    dispatch(triggerAnalyticsEvent(ANALYTICS_DELIVERY_OPTIONS, {
      analytics,
    }));
  }

  const customerCanMakeGooglePayPayments = getState().payment?.canMakeGooglePayPayments;
  if (customerCanMakeGooglePayPayments) {
    await dispatch(canMakeGooglePayPayments());
  }

  const dispatchObject = {
    type: getOnly ? GET_PAYMENT_PAGE_NO_ACTION : INIT_PAYMENT_PAGE_NO_ACTION,
    request: client => client({
      path: URL_PAYMENT_PAGE,
      config: { method: getOnly ? 'GET' : 'POST' },
    }),
  };

  const paymentPageResponse = await dispatch(dispatchObject);
  let getItemsResponse;

  if (!paymentPageResponse?.type?.includes('FAILED')) {
    getItemsResponse = await dispatch(getItems({ noAction: true }));
  }

  const mergedResponses = mergeItemResponse([paymentPageResponse, getItemsResponse]);

  const response = dispatch({
    selectedCollectionPoint,
    deliveries,
    ...pushAfter && { pushAfter },
    ...eventDetails && { eventDetails },
    ...mergedResponses,
    type: paymentPageResponse.type.replace('_NO_ACTION', ''),
  });

  if (response.type === `${INIT_PAYMENT_PAGE}.SUCCESS`) {

    if (shouldRecordDeliveryTimings) {
      if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {
        dispatch(recordCollectionPaymentPageTimings());
      }

      if (selectedDeliveryChoiceId === deliveryConstants.DELIVERY) {
        dispatch(recordDeliveryPaymentPageTimings());
      }
    }

    dispatch(recordPaymentTimings());

    await dispatch(determineSelectedPaymentType());

    const isPayPalDisabled = isFeatureActive(getState(), featureConstants.DISABLE_PAYPAL);

    if (!isPayPalDisabled && payment && !payment.payPalClientToken) {
      const showError = false;
      dispatch(getPayPalClientToken(showError));
    }
  }

  if (!pushAfter) {
    dispatch(dispatchPayOptionAnalytics(customerCanMakeGooglePayPayments));
  }

  const error = response?.error?.code ?? '';
  if (error === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE) {
    await dispatch(handleInconsistentOrderFormState());
    return {};
  }

  if (pushAfter) {
    dispatch(push(routeConstants.PAYMENT));
    dispatch(dispatchPayOptionAnalytics(customerCanMakeGooglePayPayments));
  }

  return response;
};

export const getPaymentPage = eventDetails => (initPaymentPage({ getOnly: true, eventDetails }));

export const getPaymentPageAfterSubmitOrderError = submitOrderAction => async (dispatch) => {
  const response = await dispatch(initPaymentPage({
    getOnly: true,
    eventDetails: {
      posCreditSubmitOrderError:
        submitOrderAction.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false,
    },
  }));

  if (response.type === `${GET_PAYMENT_PAGE}.SUCCESS`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_SUBMIT_ORDER_ERROR, { error: submitOrderAction.error }));
  }
};

export const closeVoucherUnderspendWarningModal = () => ({
  type: VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE,
});
