export const CLICK_AND_COLLECT = 'ClickAndCollect';
export const PARTNERSHIP_STORE = 'PartnershipStore';
export const COLLECT_PLUS = 'CollectPlus';
export const ROYAL_MAIL = 'RoyalMail';
export const EVRI = 'EVRi';
export const CO_OP_MIDCOUNTIES = 'CoopMidcounties';
export const VIEW_TYPE_LIST = 'search-list';
export const VIEW_TYPE_MAP = 'search-map';
export const STORE_SELECTED = 'store-selected';
export const STORE_DETAILS = 'store-details';
export const JOHN_LEWIS_OWNER_ID = 1;
export const WAITROSE_OWNER_ID = 2;
export const COLLECT_PLUS_OWNER_ID = 3;
export const BOOTHS_OWNER_ID = 4;
export const COOP_OWNER_ID = 5;
export const SHELL_OWNER_ID = 6;
export const ROYAL_MAIL_OWNER_ID = 10;
export const EVRI_OWNER_ID = 11;
export const CO_OP_MIDCOUNTIES_OWNER_ID = 9;
export const COLLECT_PLUS_RELATED_OWNERS = [
  {
    name: 'Collect+',
    ownerId: COLLECT_PLUS_OWNER_ID,
    type: COLLECT_PLUS,
    url: 'https://www.yodel.co.uk/help-advice/what-id-should-i-have-with-me-when-collecting-my-parcel',
  }, {
    name: 'Royal Mail',
    ownerId: ROYAL_MAIL_OWNER_ID,
    type: ROYAL_MAIL,
    url: 'https://personal.help.royalmail.com/app/answers/detail/a_id/18475/~/ive-picked-local-collect-%2F-click-and-collect-as-the-delivery-option%2C-how-do-i#:~:text=Once%20we%27ve%20left%20your,hold%20items%20for%2018%20days',
  }, {
    name: EVRI,
    ownerId: EVRI_OWNER_ID,
    type: EVRI,
  },
];
export const SEARCH_TERMS_TO_MODIFY = ['liverpool', 'windsor'];
