import {
  GIFT_CARD_NUMBER,
  GIFT_CARD_PIN,
  GIFT_VOUCHER_SECURITY_CODE,
  GIFT_VOUCHER_SERIAL_NUMBER,
} from './giftFields';

export const APPLY_GIFT_CARD_FORM_ID = 'applyGiftCardFormId';
export const APPLY_GIFT_VOUCHER_FORM_ID = 'applyGiftVoucherFormId';

export const applyGiftCardFormConfig = () => ({
  id: APPLY_GIFT_CARD_FORM_ID,
  fields: [
    GIFT_CARD_NUMBER,
    GIFT_CARD_PIN,
  ],
});

export const applyGiftVoucherFormConfig = () => ({
  id: APPLY_GIFT_VOUCHER_FORM_ID,
  fields: [
    GIFT_VOUCHER_SECURITY_CODE,
    GIFT_VOUCHER_SERIAL_NUMBER,
  ],
});
