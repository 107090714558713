import unionBy from 'lodash/unionBy';

const unionWithDevFeatures = (bffFeatures) => {
  const localFeatures = window.env.FEATURES || [];

  const featuresWithImpressionIds = bffFeatures.filter(feature => feature.impressionId);

  featuresWithImpressionIds.forEach((f) => {
    if (f.impressionId) {
      const overrideFeature = localFeatures.find(localFeature => localFeature.id === f.id);
      if (overrideFeature) overrideFeature.impressionId = f.impressionId;
    }
  });

  return unionBy(localFeatures, bffFeatures, 'id');
};

export default unionWithDevFeatures;
