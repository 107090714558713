// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  GET_ORDER_CONFIRMATION_PAGE,
  HAND_OVER,
  LOGIN_RESET,
  SUBMIT_ORDER,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
  AUTH0_REGISTRATION_CALLBACK,
} from '../../../constants/actionConstants';
import { isZeroAmount } from '../../../utils';
// features
import deliveryConstants from '../../../constants/deliveryConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import { checkGvfSlotGreaterThanTwoHours } from '../payment/orderFormReducer';
// utils
import getOrderDetails from '../../../utils/helpers/getOrderDetails';
import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';

export const INITIAL_STATE = {
  order: {
    id: '',
    deliveryAddress: {
      addressee: {},
      address: {},
      phoneNumber: '',
    },
    billingAddress: {
      addressee: {},
      address: {},
      phoneNumber: '',
    },
    customer: {},
    items: [],
    payments: [],
    deliveries: [],
  },
  promoCodeApplied: {},
  showCreateAccountPrompt: false,
  showSaveOrderPrompt: false,
  isClickCollectOrder: false,
  accountError: {},
  isGiftReceiptOrder: false,
  giftMessageLines: [],
  getOrderConfirmationPageApiCallActive: false,
  orderPlacedAfterClickCollectCutOff: false,
  showTradeInBoxInOrderConfirmation: false,
};

export const parseDeliveries = (order = {}, deliveryDeliveries = []) => (
  order.deliveries.map((delivery = {}) => {
    const type = delivery.type;
    const giftMessage = get(delivery, 'fulfilment.giftMessageLines', []);
    const giftMessageLines = giftMessage[0] === 'This is a gift receipt' ? [] : giftMessage;

    let isGvfSlotGreaterThanTwoHours = false;
    if (type === deliveryConstants.GREEN_VAN_FLEET) {
      isGvfSlotGreaterThanTwoHours = checkGvfSlotGreaterThanTwoHours(delivery, deliveryDeliveries);
    }

    const deliveryItemIds = delivery.items.map(d => d.id);

    return {
      ...delivery,
      items: order.items.filter(item => deliveryItemIds.includes(item.id)),
      isGiftReceiptOrder: !isEmpty(giftMessage),
      giftMessageLines,
      isGvfSlotGreaterThanTwoHours,
    };
  })
);

export default function orderConfirmationReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {

    case `${GET_ORDER_CONFIRMATION_PAGE}.LOADING`: {
      return {
        ...state,
        getOrderConfirmationPageApiCallActive: true,
      };
    }

    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      const createAccountPrompt = get(action, 'result.createAccountPrompt') || {};

      const isClickCollectOrder = !!get(action, 'result.order.deliveries[0].fulfilment.collectionInfo');
      const order = action.result.order;
      let orderPlacedAfterClickCollectCutOff = false;
      let oldCutOffTime;

      if (isClickCollectOrder && action.selectedCollectionPoint) {
        const ownerId = order.deliveries[0].fulfilment.collectionInfo.ownerId;
        if (isCollectPlusRelatedType(ownerId)) {
          order.deliveryAddress.address.addressLine1 = action.selectedCollectionPoint.storeName;
        } else {
          if (order.deliveries[0].fulfilment.collectionInfo.collectionDateTime !== action.selectedCollectionTime) {
            orderPlacedAfterClickCollectCutOff = true;
            oldCutOffTime = action.selectedCollectionTime;
          }
          order.deliveryAddress.address.addressLine2 = action.selectedCollectionPoint.storeName;
        }
      }

      const modifiedOrder = {
        ...order,
        deliveries: parseDeliveries(order, action.deliveries),
      };

      return {
        ...state,
        orderDetails: getOrderDetails({
          deliveries: modifiedOrder.deliveries,
          deliveryAddress: modifiedOrder.deliveryAddress,
          billingAddress: modifiedOrder.billingAddress,
          payments: modifiedOrder.payments,
          isClickCollectOrder,
        }),
        getOrderConfirmationPageApiCallActive: false,
        getOrderConfirmationPageApiCallComplete: true,
        order: modifiedOrder,
        promoCodeApplied: get(action, 'result.order.appliedPromotion', {}),
        showCreateAccountPrompt: createAccountPrompt.visible || false,
        isClickCollectOrder,
        orderPlacedAfterClickCollectCutOff,
        oldCutOffTime,
        showSaveOrderPrompt: action.result.saveOrderPrompt?.visible,
        orderInformation: action.result.saveOrderPrompt?.orderInformation,
        expiredSignedIn: action.result.expiredSignedIn || false,
        showTradeInBoxInOrderConfirmation: !!get(action, 'result.order.tradeInQuotes', []).length,
      };
    }

    case `${SUBMIT_ORDER}.FAILED`:
      return {
        ...state,
        orderPlacedAfterClickCollectCutOff: false,
      };

    case LOGIN_RESET:
    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${HAND_OVER}.SUCCESS`: {
      return INITIAL_STATE;
    }

    case `${AUTH0_REGISTRATION_CALLBACK}.LOADING`: {
      return {
        ...state,
        getOrderConfirmationPageApiCallComplete: false,
      };
    }

    default:
      return state;
  }
}

export const getGiftOptionsRemainingBalance = (state = {}) => {
  const payments = get(state, 'confirmation.order.payments', []);
  const { remainingBalance } =
    payments.find(({ remainingBalance, type }) => (
      type === paymentTypeConstants.GIFT_VOUCHER && !isZeroAmount(remainingBalance)
    )) || {};

  return remainingBalance;
};
