export const GIFT_OPTIONS = {
  GIFT_CARD: 'Gift card / e-gift card',
  GIFT_VOUCHER: 'Gift voucher / e-voucher',
  ONE_4_ALL: 'One4All gift card',
  PROMO_CODE: 'Promo code',
};

export const ANIMATION_TYPES = {
  WHOLE_SECTION: 'section',
  NEW_ITEM: 'topitem',
};
