// lodash
import get from 'lodash/get';
// jl-design-system
import { validateAddressBookRecords } from './validateAddressBookRecords';
import { getDefaultDeliveryAddressAvailableForDelivery } from './getDefaultDeliveryAddress';
import mergeAddressBook from './mergeAddressBookRecords';

export default function initAddressBook(state, action) {
  const rawAddressBook = get(action, 'result.customer.addressBook', []);
  const mergedAddressBook = mergeAddressBook(state.addressBook, rawAddressBook);
  const addressBook = validateAddressBookRecords(mergedAddressBook);

  const stateSelectedDeliveryAddressId = get(state.selectedDeliveryAddress, 'id');
  const stateSelectedDeliveryAddress = stateSelectedDeliveryAddressId ?
    addressBook?.find(addressRecord => addressRecord.id === stateSelectedDeliveryAddressId) :
    state.selectedDeliveryAddress;

  // We need to validate the selected delivery address as this is used for users with no data / guests
  // and if the basket has been edited in another Tab it may now be ineligible for delivery
  const validatedSelectedDeliveryAddress = !stateSelectedDeliveryAddressId && stateSelectedDeliveryAddress ?
    validateAddressBookRecords([stateSelectedDeliveryAddress])[0] : undefined;

  const defaultAddress = getDefaultDeliveryAddressAvailableForDelivery(addressBook);

  const selectedDeliveryAddress = validatedSelectedDeliveryAddress || stateSelectedDeliveryAddress || defaultAddress;

  const notAvailableForDelivery = get(selectedDeliveryAddress, 'notAvailableForDelivery', false);

  return {
    addressBook,
    selectedDeliveryAddress,
    canUseSelectedDeliveryAddress: !notAvailableForDelivery,
  };
}
