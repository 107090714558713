import { createSelector } from 'reselect';

// Config
import { getSessionIdSelector } from '../../../../redux/reducers/bff/bffSelector';
import { getLoqateApiKeySelector } from '../../../../redux/reducers/config/configSelector';
import {
  getOrderFormIsClickCollectOrderSelector,
  getOrderFormDeliveryAddressSelector,
  getUseDeliveryAddressAsBillingAddressSelector,
} from '../../../../redux/reducers/order-form/orderFormSelector';
import { getSelectedPaymentTypeSelector } from '../../../../redux/reducers/payment/paymentSelector';
import { getPreviewSelectedBillingAddressSelector, getSelectedBillingAddressSelector } from '../../../../redux/reducers/user/userSelector';
import isSavedPaymentCard from '../../../../utils/payment/isSavedPaymentCard';

const addBillingAddressState = createSelector(
  [
    getUseDeliveryAddressAsBillingAddressSelector,
    getPreviewSelectedBillingAddressSelector,
    getOrderFormIsClickCollectOrderSelector,
    getOrderFormDeliveryAddressSelector,
    getLoqateApiKeySelector,
    getSessionIdSelector,
    getSelectedPaymentTypeSelector,
    getSelectedBillingAddressSelector,
  ],
  (
    useDeliveryAddressAsBillingAddress,
    previewSelectedBillingAddress,
    isClickCollectOrder,
    deliveryAddress,
    loqateApiKey,
    sessionId,
    selectedPaymentType,
    selectedBillingAddress,
  ) => ({
    deliveryAddress,
    isClickCollectOrder,
    isSavedPayment: isSavedPaymentCard(selectedPaymentType) && !!selectedBillingAddress,
    loqateApiKey,
    previewSelectedBillingAddress,
    sessionId,
    useDeliveryAddressAsBillingAddress: useDeliveryAddressAsBillingAddress && !previewSelectedBillingAddress,
  }),
);

export default addBillingAddressState;
