import { pecrCheck } from '../pecr/pecr';

type StorageItem = {
  checkForPermission?: boolean;
  key: string;
  local?: boolean;
  value?: boolean | string | null;
};


function getStorageType(local: boolean): Storage {
  return (!local) ? window.sessionStorage : window.localStorage;
}

/**
 * Checks if sessionStorage setItem is supported
 * setItem fails silently in Safari private mode and stops script execution
 * https://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an
 * @returns {boolean}
 */
export function isStorageSupported(local: boolean): boolean {
  try {
    const storage = getStorageType(local);
    const testKey = local ? 'testLocalStorage' : 'testSessionStorage';
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

export function getStorageItem(item: string, local: boolean = false): string | null {
  if (isStorageSupported(local)) {
    const storage = getStorageType(local);
    return storage.getItem(item);
  }
  return '';
}

export function setStorageItem({
  key,
  value,
  local = false,
  checkForPermission,
}: StorageItem): void {
  if (isStorageSupported(local)) {
    const storageObject = getStorageType(local);
    const storageValue = value === null || value === undefined ? '' : String(value);

    if (checkForPermission) {
      const storageType = local ? 'localStorage' : 'sessionStorage';
      if (pecrCheck(storageType, key)) {
        storageObject.setItem(key, storageValue);
      }
    } else {
      storageObject.setItem(key, storageValue);
    }
  }
}

export function removeStorageItem(item: string, local: boolean): void {
  if (isStorageSupported(local)) {
    const storage = getStorageType(local);
    storage.removeItem(item);
  }
}

export function clearStorage({
  local = false,
  preservedValues = [],
}: { local?: boolean; preservedValues?: { key: string; local: boolean }[] } = {}): void {
  if (isStorageSupported(local)) {
    const storage = getStorageType(local);
    let preservedDataMap;

    if (preservedValues) {
      preservedDataMap = preservedValues.map((item) => {
        const value = getStorageItem(item.key, item.local);
        return {
          key: item.key,
          value,
          local: item.local,
        };
      });
    }

    storage.clear();

    if (preservedDataMap) {
      preservedDataMap.forEach((item) => {
        setStorageItem(item);
      });
    }
  }
}
