// lodash
import get from 'lodash/get';
import {
  APPS_ACCESS_TOKEN_PROVIDED,
  APPS_AUTHENTICATED_HANDOVER,
  APPS_CLEANUP_BASKET,
  APPS_REFRESH_ACCESS_TOKEN_REQUESTED,
  APPS_REGISTER_CALLBACKS,
  GET_ORDER_CONFIRMATION_PAGE,
  REDUX_HYDRATE,
  REFRESHING_APPS_ACCESS_TOKEN,
} from '../../../constants/actionConstants';
import { getOrderData } from '../../actions/mobileApps/applePayAppsActions';

export const INITIAL_STATE = {
  onOrderPlaced: undefined,
  triggerAnalytics: undefined,
  requestAccessToken: undefined,
  auth0AccessToken: undefined,
  isRefreshAccessTokenRequested: false,
  requestPayload: undefined,
  updateBasketId: undefined,
  refreshingAppsAccessToken: false,
};

export default function mobileAppsReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case REDUX_HYDRATE: {
      const payload = get(action, 'payload.mobileApps', {});

      if (!payload.auth0AccessToken && payload.requestPayload?.accessToken) {
        return {
          ...state,
          auth0AccessToken: payload.requestPayload.accessToken,
        };
      }

      return state;
    }

    case REFRESHING_APPS_ACCESS_TOKEN: {
      return {
        ...state,
        refreshingAppsAccessToken: true,
      };
    }

    case APPS_REGISTER_CALLBACKS: {
      return {
        ...state,
        onOrderPlaced: action.callbacks.orderPlaced,
        triggerAnalytics: action.callbacks.triggerAnalytics,
        requestAccessToken: action.callbacks.requestAccessToken,
        updateBasketId: action.callbacks.updateBasketId,
      };
    }
    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      if (state.onOrderPlaced) {
        state.onOrderPlaced();
        if (state.triggerAnalytics) {
          state.triggerAnalytics(getOrderData(action.result.order));
        }
      }
      return state;
    }

    case APPS_CLEANUP_BASKET: {
      if (state.onOrderPlaced) {
        state.onOrderPlaced();
      }
      return state;
    }

    case APPS_ACCESS_TOKEN_PROVIDED: {
      return {
        ...state,
        auth0AccessToken: action.token,
        refreshingAppsAccessToken: false,
      };
    }

    case APPS_REFRESH_ACCESS_TOKEN_REQUESTED: {
      return {
        ...state,
        auth0AccessToken: undefined,
        isRefreshAccessTokenRequested: true,
      };
    }
    case `${APPS_AUTHENTICATED_HANDOVER}.LOADING`: {
      return {
        ...state,
        requestPayload: action.requestPayload,
      };
    }

    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`: {
      return {
        ...state,
        isRefreshAccessTokenRequested: false,
      };
    }

    default:
      return state;
  }
}
