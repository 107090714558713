import {
  SET_CARD_TYPE,
  CLEAR_CARD_TYPE,
  LOGIN_RESET,
} from '../../../constants/actionConstants';

export const INITIAL_STATE = {
  name: '',
  securityCodeLength: 3,
  cardNumberMaxLength: 19,
  cardNumberMinLength: 12,
};

export default function cardTypeReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_CARD_TYPE: {
      return {
        ...state,
        name: action.cardType.name,
        securityCodeLength: action.cardType.securityCodeLength,
        cardNumberMaxLength: action.cardType.cardNumberMaxLength,
        cardNumberMinLength: action.cardType.cardNumberMinLength,
      };
    }
    case CLEAR_CARD_TYPE:
    case LOGIN_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
