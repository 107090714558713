type FetchOptionsWithTimeout = RequestInit & { timeout: number };

export default async function fetchWithTimeout(resource: RequestInfo | URL, options: FetchOptionsWithTimeout) {
  const controller = new AbortController();
  const abortTimer = setTimeout(() => controller.abort(), options.timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(abortTimer);

  return response;
}
