import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Design System
import { PrimaryLink } from 'jl-design-system/elements/link/Link';
import { scrollTo } from 'jl-design-system/utils/scroll/scrollTo';

// Config
import sessionExpiredState from './SessionExpired.state';
import setPageTitle from '../../utils/helpers/pageTitle';

// Components
import BodyText from '../../components/body-text';
import Container from '../../components/container';
import Heading from '../../components/heading';

const SessionExpired = () => {
  const { canonicalURL, showBackToPDP, showBackToBasket } = useSelector(sessionExpiredState);

  useEffect(() => {
    setPageTitle('Session expired');

    const timer = setTimeout(() => {
      scrollTo(0);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      maxWidth="sm"
      paddingX="2"
      textAlign="center"
    >
      <Heading
        label="Sorry, your session has expired"
        marginBottom="2"
        tag="h2"
        type="s"
      />
      <BodyText marginBottom="3">
        We signed you out to keep your information secure. Please return to the{' '}
        {showBackToPDP ? 'product page' : 'basket'} to continue with your order.
      </BodyText>

      {(showBackToBasket || showBackToPDP) && (
        <PrimaryLink
          external
          inline
          to={`${window.publicJLSiteDomain}/${showBackToPDP ? canonicalURL : 'basket'}`}
        >
          {showBackToPDP ? 'Return to your product' : 'Return to basket'}
        </PrimaryLink>
      )}
    </Container>
  );
};

export default SessionExpired;
