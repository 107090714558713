// Config
import { internationalPhoneNumberLazy } from './lazyMainBundle';
import { getPhoneNumberDropdownOptions } from '../address/addressHelpers';

export default function getInternationalPhoneNumberConfig({
  instruction,
  isClickCollectOrder,
  showDefaultInstruction,
}: { instruction?: string; isClickCollectOrder?: boolean; showDefaultInstruction?: boolean } = {}) {
  const INTERNATIONAL_PHONE_NUMBER = internationalPhoneNumberLazy;
  const config = INTERNATIONAL_PHONE_NUMBER();

  let fieldInstruction;

  if (instruction) {
    fieldInstruction = instruction;
  } else if (showDefaultInstruction) {
    if (isClickCollectOrder) {
      fieldInstruction = 'We’ll use this number to send text updates or if we have any queries with your order';
    } else {
      fieldInstruction = 'We’ll use this number if we have any queries with your payment';
    }
  }

  const PHONE_NUMBER = config?.component ? {
    ...config,
    props: {
      options: getPhoneNumberDropdownOptions(),
    },
    ...fieldInstruction && { instruction: fieldInstruction },
  } : config;

  return PHONE_NUMBER;
}
