// utils
import { getStorageItem } from '../storage/storage';
// constants
import appConstants from '../../constants/appConstants';

export function getCountryNameFromCode(countryCode) {
  const country =
    JSON.parse(getStorageItem(appConstants.COUNTRIES))?.find(countryRecord => countryRecord.value === countryCode);
  return country ? country.label : countryCode;
}

export default getCountryNameFromCode;
