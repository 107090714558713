export default function getCookieValue(value: string) {
  const cookies = document.cookie.split('; ');

  const foundCookie = cookies.find((cookie) => {
    const [key] = cookie.split('=');
    return key === value;
  });

  if (foundCookie) {
    const [, ...valParts] = foundCookie.split('=');
    return decodeURIComponent(valParts.join('='));
  }

  return '';
}
