import React from 'react';
import { useSelector } from 'react-redux';

// Types
import { UserEmailAddressProps } from './UserEmailAddress.types';
import { DesignSystemSpacingTypes } from '../design-system/DesignSystem.types';

// Config
import { getUserEmailAddressSelector } from '../../redux/reducers/user/userSelector';

// Components
import BodyText from '../body-text';
import Container from '../container';
import Heading from '../heading';

export const UserEmailAddress = ({
  headingTag = 'h4',
  isClickAndCollect = false,
  isMarginApplied = false,
  noContainerTopMargin = false,
}: UserEmailAddressProps) => {
  const email = useSelector(getUserEmailAddressSelector);

  const marginBottom: DesignSystemSpacingTypes = isMarginApplied ? '1' : '0';
  let marginTop: DesignSystemSpacingTypes = isClickAndCollect ? '1' : '0';
  if (!noContainerTopMargin) {
    marginTop = '2';
  }

  return email && (
    <Container marginTop={marginTop} testId="user-email-container">
      <Heading
        label="Email"
        marginBottom="1"
        tag={headingTag}
        type="xs"
      />
      <BodyText marginBottom={marginBottom} maskText>
        {email}
      </BodyText>
    </Container>
  );
};

export default UserEmailAddress;
