import { createSelector } from 'reselect';

// Config
import { getIsApplicationSelector, getIsExpressCheckoutSelector } from '../../redux/reducers/app/appSelector';
import { getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';

const getCanonicalURLSelector = createSelector(
  [getOrderFormItemsSelector],
  items => items?.[0]?.canonicalURL ?? '',
);

const sessionExpiredState = createSelector(
  [getCanonicalURLSelector, getIsExpressCheckoutSelector, getIsApplicationSelector],
  (canonicalURL, isExpressCheckout, isApps) => ({
    canonicalURL,
    showBackToPDP: isExpressCheckout,
    showBackToBasket: !isApps,
  }),
);

export default sessionExpiredState;
