import React from 'react';
import { useSelector } from 'react-redux';

// Design System
import { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { SidebarProps } from './Sidebar.types';

// Config
import sidebarState from './Sidebar.state';
import urlConstants from '../../constants/urlConstants';

// Components
import Container from '../container';
import MyAccountLink from '../my-account-link';
import OrderSummary, { OrderSummaryTitle } from '../order-summary';
import OrderTotals from '../order-totals';
import PaymentDetails from '../payment-details';
import { MyJohnLewis } from '../my-john-lewis';
import MembershipNotification from '../my-john-lewis/myjl-member-notification';
import PricePromise from '../price-promise/PricePromise';

// Styles
import styles from './sidebar.scss';

export const SidebarContent = ({
  isDeliveryPage = false,
  isOrderConfirmationPage = false,
}: SidebarProps) => {
  const {
    expiredSignedIn,
    isApplication,
    isGuest,
    items = [],
    order,
  } = useSelector(sidebarState);
  if (isOrderConfirmationPage) {
    const showMyAccountLink = !isGuest && !isApplication && !expiredSignedIn;

    return (
      <Container testId="sidebar-content">
        <Container className={styles.wrapper} marginBottom="2">
          <OrderSummaryTitle
            label="Order Summary"
            totalQuantity={items.length}
          />
          <OrderTotals confirmationPage sidebarView />
          <PaymentDetails
            payments={order?.payments}
          />
        </Container>
        <Container className={styles.actions}>
          <PrimaryLink
            className={styles.primaryLink}
            data-action="handback"
            data-testid="sidebar-continue-shopping"
            to={urlConstants.HOME_PAGE_URL}
          >
            Continue shopping
          </PrimaryLink>

          {showMyAccountLink && (
            <MyAccountLink
              className={styles.secondaryLink}
              level="secondary"
              text="View online orders"
            />
          )}
        </Container>
      </Container>
    );
  }

  const renderOrderTotals = () => {
    return (
      <OrderTotals
        isDeliveryPage={isDeliveryPage}
        sidebarView
      />
    );
  };

  return (
    <div className={styles.wrapper} data-testid="sidebar-content">
      <OrderSummary
        items={items}
        sidebarView
      />
      {renderOrderTotals()}
      <PricePromise isSidebarView />
    </div>
  );
};

const Sidebar = ({
  isDeliveryPage = false,
  isOrderConfirmationPage = false,
}: SidebarProps) => {
  const {
    isMyJLMember,
    joinMyJLFailed,
    joinMyJLSuccess,
    showMyJLAccountPrompt,
  } = useSelector(sidebarState);

  return (
    <aside
      className={styles.container}
      data-testid="sidebar-container"
    >
      <div className={styles.innerContainer}>
        {isMyJLMember && (
          <MembershipNotification />
        )}
        <SidebarContent
          isDeliveryPage={isDeliveryPage}
          isOrderConfirmationPage={isOrderConfirmationPage}
        />

        {
          !joinMyJLFailed && showMyJLAccountPrompt && isOrderConfirmationPage &&
          <MyJohnLewis
            joinMyJLSuccess={joinMyJLSuccess}
            sidebarView
          />
        }
      </div>
    </aside>
  );
};

export default Sidebar;
