import React from 'react';
import cx from 'classnames';

// Types
import { CircleLoaderProps } from './CircleLoader.types';

// Config
import PROGRESS_STATE from '../../constants/progressStateConstants';

// Styles
import styles from './circle-loader.scss';

const { NONE, LOADING, FAILED, SUCCESS } = PROGRESS_STATE;

const CircleLoader = ({
  className = '',
  large = false,
  placeholder = false,
  progress = NONE,
}: CircleLoaderProps) => {
  const isProgress = (checkState: string) => checkState === progress;

  return (
    <div
      className={cx(className, styles.circleLoader, {
        [styles.loadFailed]: isProgress(FAILED),
        [styles.loading]: isProgress(LOADING) || isProgress(SUCCESS),
        [styles.thick]: large,
        [styles.placeholder]: placeholder,
      })}
      data-testid="circle-loader"
    >
      <div
        className={cx({
          [styles.cross]: isProgress(FAILED),
          // eslint-disable-next-line css-modules/no-undef-class
          [styles.crossDraw]: isProgress(FAILED),
          [styles.visible]: isProgress(SUCCESS) || isProgress(FAILED),
          [styles.thick]: large,
        })}
        data-testid="inner-circle-loader"
      />
    </div>
  );
};

export default CircleLoader;
