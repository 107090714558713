// Config
import { getCookieGroupPermission } from '../pecr/pecr';

export function shouldRemoveAndSaveForLater({
  isChildItem = false,
  isExpressCheckout = false,
  isM2M = false,
  isOutlet = false,
  isShipFromStore = false,
  isSignedIn = false,
  isTradeIn = false,
}: {
  isChildItem?: boolean;
  isExpressCheckout?: boolean;
  isM2M?: boolean;
  isOutlet?: boolean;
  isShipFromStore?: boolean;
  isSignedIn?: boolean;
  isTradeIn?: boolean;
}): boolean {
  const functionalCookiesEnabled = getCookieGroupPermission('Functional') === 'allow';
  const shouldSaveForLater = !isChildItem
    && !isExpressCheckout
    && !isOutlet
    && !isShipFromStore
    && (isSignedIn || functionalCookiesEnabled)
    && !isTradeIn
    && !isM2M;

  return shouldSaveForLater;
}
