import { getTextAreaLinesArray } from '../string/textAreaUtils';
import GIFT_MESSAGE from '../form/configs/giftMessage';
import stripWhitespace from '../string/stripWhitespace';

export function getGiftMessageLines({ giftReceipt = false, giftMessage = '' }) {
  let giftMessageLines;

  if (giftReceipt) {
    const rawGiftMessage = stripWhitespace(giftMessage);
    const { props: { maxLines, maxCharsLine } } = GIFT_MESSAGE;
    giftMessageLines = rawGiftMessage ?
      getTextAreaLinesArray({ string: giftMessage, maxLines, maxCharsLine }) : ['This is a gift receipt'];
  }

  return giftMessageLines;
}

export default getGiftMessageLines;
