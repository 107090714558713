import { createSelector } from 'reselect';

// Config
import { getFormSelector } from '../../redux/reducers/form/formSelector';
import {
  getIsPosCreditSubmissionErrorSelector,
  getOrderFormAmendItemsFailedSelector,
  getOrderFormAmendQuantitiesApiCallActiveSelector,
  getOrderFormLimitedStockItemsSelector,
  getOrderFormOrderNeedsToBeRebatchedSelector,
} from '../../redux/reducers/order-form/orderFormSelector';
import { getRouterLocationPathname } from '../../redux/reducers/router/routerSelector';

const limitedStockModalState = createSelector(
  [
    getOrderFormAmendItemsFailedSelector,
    getOrderFormAmendQuantitiesApiCallActiveSelector,
    getFormSelector,
    getIsPosCreditSubmissionErrorSelector,
    getOrderFormLimitedStockItemsSelector,
    getOrderFormOrderNeedsToBeRebatchedSelector,
    getRouterLocationPathname,
  ],
  (
    amendItemsFailed,
    amendQuantitiesApiCallActive,
    form,
    isPosCreditSubmissionError,
    limitedStockItems,
    orderNeedsToBeRebatched,
    pathname,
  ) => ({
    amendItemsFailed,
    amendQuantitiesApiCallActive,
    form,
    isPosCreditSubmissionError,
    limitedStockItems,
    orderNeedsToBeRebatched,
    pathname,
  }),
);

export default limitedStockModalState;
