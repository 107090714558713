import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { AppDispatch } from 'types/RootState.types';
import { BillingDetailsProps } from './BillingDetails.types';

// Config
import billingDetailsState from './BillingDetails.state';
import scrollConstants from '../../../../constants/scrollConstants';
import { showBillingAddressBookModal } from '../../../../redux/actions/payment/paymentActions';
import { useWindowSize } from '../../../../context/window/WindowSizeContext';

// Components
import AddBillingAddress from './AddBillingAddress';
import CardholdersDetailsAddressBook from './CardholdersDetailsAddressBook';
import PaymentCardDelete from './PaymentCardDelete';
import Heading from '../../../heading';
import ScrollAnchor from '../../../scroll-anchor';

// Styles
import styles from './billing-details.scss';

const BillingDetails = ({
  disabled,
  hideTitle,
  showPaymentCardDelete,
}: BillingDetailsProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { billingAddressExists, isLoadingLazyComponent, isSignedInWithData } = useSelector(billingDetailsState);
  const { isDesktop: hideDeleteButtonResolution } = useWindowSize();

  return (
    <div className={styles.container} data-testid="payment-billing-details-container">
      <ScrollAnchor scrollId={scrollConstants.BILLING_DETAILS_HEADING} />
      {!hideTitle && (
        <Heading
          className={styles.title}
          label="Billing address"
          marginBottom="1"
          tag="h3"
          testId="billing-details-heading"
          type="xs"
        />
      )}
      <div className={styles.billingAddressWrapper}>
        {billingAddressExists && isSignedInWithData && (
          <>
            <CardholdersDetailsAddressBook showChangeAddressButton={false} />
            <TertiaryButton
              className={styles.editAddressButton}
              data-testid="edit-address-button"
              disabled={disabled}
              onClick={() => dispatch(showBillingAddressBookModal())}
              small
              submitting={isLoadingLazyComponent}
            >
              Change address
            </TertiaryButton>
            {showPaymentCardDelete && !hideDeleteButtonResolution && (
              <div className={styles.deleteButton}>
                <PaymentCardDelete disabled={disabled} />
              </div>
            )}
          </>
        )}
      </div>
      {(!billingAddressExists || !isSignedInWithData) && (
        <AddBillingAddress />
      )}
    </div>
  );
};

export default BillingDetails;
