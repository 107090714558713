// Design System
import { DATE_OF_BIRTH } from 'jl-design-system/form/fields';

// Components
import AgeRestrictedMessage from '../../../components/age-restricted-message';

export const AGE_CHECK_FORM_ID = 'ageCheckForm';

export default function ageCheckFormConfig(isSignedIn, configExtras) {

  const { submitButtonExtra } = configExtras;

  return {
    id: AGE_CHECK_FORM_ID,
    submitButton: 'Continue',
    submitButtonExtra,
    fields: [
      {
        ...DATE_OF_BIRTH,
        className: 'fieldLargeInputMedium',
        required: true,
        validationMessageKeys: {
          ...DATE_OF_BIRTH.validationMessageKeys,
          dateFormat: 'Please enter your date of birth',
          required: 'Please enter your date of birth',
        },
        instruction: isSignedIn ?
          'We’ll save this information for future orders so you won’t have to enter it again.' : '',
      },
      {
        component: AgeRestrictedMessage,
        id: 'challenge-25',
        name: 'challenge-25',
        props: {
          isSignedIn,
        },
      },
    ],
  };
}
