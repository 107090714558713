import isEmpty from 'lodash/isEmpty';

// Design System
import validate from 'jl-design-system/form/validation/validate';

// Config
import { updateBillingAddress } from './cardPaymentActions';
import {
  handleExpressFailure,
  isFailure,
  setPartialDeliveryAddress,
} from './expressPaymentActions';
import { postPayments, triggerPlaceOrderAndPayAnalytics } from './paymentActions';
import { submitOrderAndRedirect } from './submitOrderActions';
import { putDeliveryDetails, postPayPalExpressDelivery } from '../delivery/deliveryActions';
import { isWeb } from '../../reducers/app/appReducer';
import { isFeatureActive } from '../../reducers/config/configReducer';
import {
  AUTHENTICATE_PAYPAL_PAYMENT,
  CREATE_PAYPAL_PAYMENT,
  GET_PAYPAL_CLIENT_TOKEN,
  SET_PAYPAL_SERVICE_UNAVAILABLE,
  PAYPAL_PAYMENT_CANCEL,
  PAYPAL_PAYMENT_FAILED,
  UPDATE_BILLING_ADDRESS,
  PAYPAL_STUBS_MODAL_CLOSE,
  PAYPAL_STUBS_MODAL_OPEN,
  PAYPAL_PAYMENT_STARTED,
  PAYPAL_PAYMENT_ENDED,
  SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS,
  UPDATE_SHIPPING_ADDRESS,
  GET_PAYPAL_EXPRESS_PAYMENT_REQUEST,
  SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE,
} from '../../../constants/actionConstants';
import {
  URL_AUTHENTICATE_PAYPAL_PAYMENT,
  URL_GET_PAYPAL_CLIENT_TOKEN,
  URL_PAYPAL_EXPRESS_REQUEST,
} from '../../../constants/endpointConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import featureConstants from '../../../constants/featureConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import {
  countryCodeIsInternational,
} from '../../../utils/address/addressHelpers';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';
import { getBillingAddress, getShippingAddress, mapFullName } from '../../../utils/address/addressHelpers';
import getPayPalAddressDetailsFormConfig from '../../../utils/form/configs/payPalAddressDetails';

export const isExpressPaypalV2 = state => isFeatureActive(state, featureConstants.ENABLE_PAYPAL_EXPRESS);

export const getPayPalClientToken = (showError = true) => ({
  type: GET_PAYPAL_CLIENT_TOKEN,
  request: client => client({ path: URL_GET_PAYPAL_CLIENT_TOKEN, config: { method: 'GET' } }),
  showError,
});

export const getPayPalExpressPaymentRequest = () => ({
  type: GET_PAYPAL_EXPRESS_PAYMENT_REQUEST,
  request: client => client({ path: URL_PAYPAL_EXPRESS_REQUEST, config: { method: 'GET' } }),
});

export const authenticatePayPalPayment = body => ({
  type: AUTHENTICATE_PAYPAL_PAYMENT,
  request: client => client({ path: URL_AUTHENTICATE_PAYPAL_PAYMENT, config: { method: 'POST', body } }),
});

export const getPayPalPaymentRequest = () => async (dispatch, getState) => {
  const state = getState();
  const express = state?.payment?.selectedPaymentType === paymentTypeConstants.PAYPAL_EXPRESS;
  const payPalPaymentProcessing = state?.payment?.payPalPaymentProcessing;

  if (payPalPaymentProcessing) dispatch({ type: PAYPAL_PAYMENT_ENDED });

  if (express) {
    const payPalPaymentRequest = await dispatch(getPayPalExpressPaymentRequest());
    return payPalPaymentRequest.result ?? {};
  }
  dispatch({ type: CREATE_PAYPAL_PAYMENT });

  return state?.payment?.payPalPaymentRequest ?? {};
};

export const setPayPalServiceUnavailable = () => ({
  type: SET_PAYPAL_SERVICE_UNAVAILABLE,
});

export const setPayPalPayLaterIsNotEligible = () => ({
  type: SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE,
});

export const setCanMakePayPalPayExpressPayments = () => (dispatch, getState) => {
  if (!isExpressPaypalV2(getState())) return;

  dispatch(getPayPalClientToken());

  dispatch({
    type: SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS,
  });
};

export const canMakePayPalPayExpressPayments = () => async (dispatch, getState) => {
  const state = getState();
  const compatibilityChecksComplete = state?.payment?.payPalPayExpressCompatibilityChecksComplete;

  if (compatibilityChecksComplete) {
    return;
  }

  if (isWeb(state)) {
    dispatch(setCanMakePayPalPayExpressPayments());
  }
};

export const returnFormattedAddressDetails = (addressRecord) => {
  // addressLine3 and addressLine4 can only come from the invalid address modal when customer clicks 'add lines'
  const {
    address: {
      addressLine1: line1,
      addressLine2: line2,
      addressLine3: line3,
      addressLine4: line4,
      countryCode,
      countyStateOrProvince: state,
      postcode: postalCode,
      townOrCity: city,
    } = {},
  } = addressRecord ?? {};

  return { line1, line2, line3, line4, city, postalCode, countryCode, state };
};

export const payPalPaymentOnApprove =
  ({
    billingAddress, firstName, lastName, nonce, phoneNumber, shippingAddress = {}, shippingOptionId = {},
  }) => async (dispatch, getState) => {
    const state = getState();
    const payPalPaymentProcessing = state?.payment?.payPalPaymentProcessing;

    if (payPalPaymentProcessing) return;

    const express = state?.payment?.selectedPaymentType === paymentTypeConstants.PAYPAL_EXPRESS;

    const billingAddressRequest = {
      addressee: { firstName, lastName },
      address: {
        addressLine1: billingAddress.line1,
        addressLine2: billingAddress.line2,
        addressLine3: billingAddress.line3,
        addressLine4: billingAddress.line4,
        townOrCity: billingAddress.city,
        postcode: billingAddress.postalCode,
        countryCode: billingAddress.countryCode,
        countyStateOrProvince: billingAddress.state,
      },
      phoneNumber,
    };

    const hasShippingAddress = Object.keys(shippingAddress).length > 0;
    let shippingAddressRequest;
    if (hasShippingAddress) {
      const addressee = shippingAddress.recipientName
        ? mapFullName(shippingAddress.recipientName)
        : { firstName, lastName };
      shippingAddressRequest = {
        addressee,
        address: {
          addressLine1: shippingAddress.line1,
          addressLine2: shippingAddress.line2,
          addressLine3: shippingAddress.line3,
          addressLine4: shippingAddress.line4,
          townOrCity: shippingAddress.city,
          postcode: shippingAddress.postalCode,
          countryCode: shippingAddress.countryCode,
          countyStateOrProvince: shippingAddress.state,
        },
        phoneNumber,
      };
    }

    dispatch({
      type: PAYPAL_PAYMENT_STARTED,
      billingAddressRequest,
      shippingAddressRequest,
    });

    if (hasShippingAddress) {
      const validateShippingAddressResult = validate({
        ...shippingAddressRequest.address,
        ...shippingAddressRequest.addressee,
        phoneNumber: shippingAddressRequest.phoneNumber,
      }, {
        config: getPayPalAddressDetailsFormConfig({
          actions: {},
          countryCode: shippingAddressRequest.address.countryCode,
          enableGBCounty: !!shippingAddressRequest.address?.countyStateOrProvince,
          configExtras: {
            addressType: 'shipping',
          },
        }),
      });

      if (!isEmpty(validateShippingAddressResult)) {
        dispatch({
          type: `${UPDATE_SHIPPING_ADDRESS}.FAILED`,
          error: {
            code: errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS,
          },
          shippingAddressFromPaypal: shippingAddressRequest,
          paypalNonce: nonce,
        });

        dispatch({ type: PAYPAL_PAYMENT_ENDED });
        return;
      }

      if (express && countryCodeIsInternational(shippingAddressRequest?.address?.countryCode)) {
        dispatch({
          type: `${UPDATE_SHIPPING_ADDRESS}.FAILED`,
          error: {
            code: errorCodeConstants.INVALID_ADDRESS_DETAILS,
          },
          shippingAddressFromPaypal: shippingAddressRequest,
          paypalNonce: nonce,
        });
        dispatch({ type: PAYPAL_PAYMENT_ENDED });
        return;
      }
    }

    const validateBillingAddressResult = validate({
      ...billingAddressRequest.address,
      ...billingAddressRequest.addressee,
      phoneNumber: billingAddressRequest.phoneNumber,
    }, {
      config: getPayPalAddressDetailsFormConfig({
        actions: {},
        countryCode: billingAddressRequest.address.countryCode,
        enableGBCounty: !!billingAddressRequest.address?.countyStateOrProvince,
        configExtras: {
          addressType: 'billing',
        },
      }),
    });

    if (!isEmpty(validateBillingAddressResult)) {
      dispatch({
        type: `${UPDATE_BILLING_ADDRESS}.FAILED`,
        error: {
          code: errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS,
        },
        billingAddressFromPaypal: billingAddressRequest,
        paypalNonce: nonce,
      });

      dispatch({ type: PAYPAL_PAYMENT_ENDED });
      return;
    }

    const putBillingAddress = await dispatch(updateBillingAddress({ body: billingAddressRequest, nonce }));
    if (isFailure(putBillingAddress)) {
      dispatch({ type: PAYPAL_PAYMENT_ENDED });
      dispatch(handleExpressFailure(putBillingAddress));
      return;
    }

    if (express) {
      const body = {
        addressDetails: {
          ...shippingAddressRequest,
        },
      };

      const postDeliveryAddressResponse =  await dispatch(postPayPalExpressDelivery({body}));

      if (isFailure(postDeliveryAddressResponse)) {
        dispatch({ type: PAYPAL_PAYMENT_ENDED });
        dispatch(handleExpressFailure(postDeliveryAddressResponse));
        return;
      }

      const {
        delivery: {
          deliveries = [],
        } = {},
      } = getState() ?? {};

      const deliveryDetailsPayload = [{
        deliveryId: deliveries[0]?.id,
        fulfilment: {
          fulfilmentOfferId: shippingOptionId,
        },
      }];

      const putDeliveryDetailsResponse = await dispatch(putDeliveryDetails({ deliveryDetailsPayload, express: true }));
      if (isFailure(putDeliveryDetailsResponse)) {
        dispatch({ type: PAYPAL_PAYMENT_ENDED });
        dispatch(handleExpressFailure(putDeliveryDetailsResponse));
        return;
      }

      const postPaymentsResponse = await dispatch(postPayments());
      if (isFailure(postPaymentsResponse)) {
        dispatch({ type: PAYPAL_PAYMENT_ENDED });
        dispatch(handleExpressFailure(postPaymentsResponse));
        return;
      }
    }

    const authenticationResponse = await dispatch(authenticatePayPalPayment({
      nonce,
      ...express && { express },
    }));

    await dispatch(triggerPlaceOrderAndPayAnalytics(authenticationResponse?.result?.jlppr));

    if (authenticationResponse.type === `${AUTHENTICATE_PAYPAL_PAYMENT}.SUCCESS`) {
      dispatch(submitOrderAndRedirect({ express }));
    }

    if (authenticationResponse.type === `${AUTHENTICATE_PAYPAL_PAYMENT}.FAILED`) {
      dispatch({ type: PAYPAL_PAYMENT_FAILED });
    }
  };

export const resubmitPayPalAddress = ({ addressType = 'billing', nonce }) => async (dispatch, getState) => {
  const state = getState();
  const stateBillingAddress = addressType === 'shipping'
    ? state?.payment?.payPalPaymentBillingAddressRequest
    : getBillingAddress(state);
  const stateShippingAddress = addressType === 'shipping'
    ? getShippingAddress(state)
    : state?.payment?.payPalPaymentShippingAddressRequest;

  const billingAddress = returnFormattedAddressDetails(stateBillingAddress);
  const firstName = stateBillingAddress?.addressee?.firstName;
  const lastName = stateBillingAddress?.addressee?.lastName;
  const phoneNumber = stateBillingAddress?.phoneNumber;

  let shippingAddress = {};
  if (stateShippingAddress) {
    shippingAddress = returnFormattedAddressDetails(stateShippingAddress);
    shippingAddress.recipientName = `${stateShippingAddress?.addressee?.firstName} ${stateShippingAddress?.addressee?.lastName}`;
  }

  return dispatch(payPalPaymentOnApprove({
    billingAddress,
    firstName,
    lastName,
    nonce,
    phoneNumber,
    shippingAddress,
  }));
};

export const openPayPalStubsModal = () => async (dispatch) => {
  await dispatch(getPayPalPaymentRequest());
  dispatch({ type: PAYPAL_STUBS_MODAL_OPEN });
};

export const closePayPalStubsModal = () => ({
  type: PAYPAL_STUBS_MODAL_CLOSE,
});

export const payPalPaymentOnCancel = () => async (dispatch, getState) => {
  const state = getState();
  const payPalStubsModalOpen = state?.payment?.payPalStubsModalOpen;
  const sessionId = state?.bff?.sessionId ?? '';

  sendNewRelicCustomEvent('payPayCancelled', { sessionId });

  if (payPalStubsModalOpen) {
    dispatch(closePayPalStubsModal());
  }

  dispatch({ type: PAYPAL_PAYMENT_CANCEL });
};

export const payPalPaymentOnError = () => async (dispatch, getState) => {
  const state = getState();
  const payPalStubsModalOpen = state?.payment?.payPalStubsModalOpen;
  const sessionId = state?.bff?.sessionId ?? '';

  sendNewRelicCustomEvent('payPayFailed', { sessionId });

  if (payPalStubsModalOpen) {
    dispatch(closePayPalStubsModal());
  }

  dispatch({ type: PAYPAL_PAYMENT_FAILED });
};

export const shippingAddressChange = (data) => async (dispatch) => {
  const {city, countryCode, postalCode} = data?.shippingAddress || {}


  const payload = {
    townOrCity: city,
    postcode: postalCode,
    countryCode: countryCode,
  };

  if (!payload.townOrCity || !payload.postcode || !payload.countryCode) {
    await dispatch(payPalPaymentOnError());
    return;
  }

  await dispatch(setPartialDeliveryAddress(payload))
}

/**
// mock functions ----------------------------------------------------------------------------------------
**/

const getMockPayPalExpressShippingAddress = (state) => {
  if (state.payment?.selectedPaymentType === paymentTypeConstants.PAYPAL_EXPRESS) {
    return {
      recipientName: 'Recipient Name',
      line1: 'shippingAddressLine1',
      line2: 'shippingAddressLine2',
      city: 'shippingTownOrCity',
      postalCode: 'N75PQ',
      countryCode: 'GB',
      state: 'London',
    };
  }
  return null;
};

export const mockPayPalIncompleteBillingAddress = () => (dispatch, getState) => {
  dispatch(closePayPalStubsModal());

  const shippingAddress = getMockPayPalExpressShippingAddress(getState());


  dispatch(payPalPaymentOnApprove({
    firstName: 'James',
    lastName: 'Ellroy',
    phoneNumber: '07777777777',
    billingAddress: {
      line1: '新登镇',
      line2: 'An unusually long line to trigger line length errors',
      postalCode: 'N75PQ',
      countryCode: 'GB',
      state: 'London',
    },
    ...(shippingAddress && { shippingAddress }),
    nonce: 'nonce',
  }));
};

export const mockPayPalIncompleteShippingAddress = () => (dispatch) => {
  dispatch(closePayPalStubsModal());

  dispatch(payPalPaymentOnApprove({
    firstName: 'James',
    lastName: 'Ellroy',
    phoneNumber: '07777777777',
    billingAddress: {
      line1: 'addressLine1',
      line2: 'addressLine2',
      city: 'townOrCity',
      postalCode: 'SW1E 5NN',
      countryCode: 'GB',
      state: 'countyStateOrProvince',
    },
    shippingAddress: {
      recipientName: 'Barry White',
      line1: '新登镇',
      line2: 'An unusually long line to trigger line length errors',
      postalCode: 'N75PQ',
      countryCode: 'GB',
      state: 'London',
    },
    nonce: 'nonce',
  }));
};

export const mockPayPalApprove = (nonce = 'nonce', shippingOptionId) => async (dispatch, getState) => {
  dispatch(closePayPalStubsModal());

  const shippingAddress = getMockPayPalExpressShippingAddress(getState());

  dispatch(payPalPaymentOnApprove({
    firstName: 'James',
    lastName: 'Ellroy',
    phoneNumber: '07777777777',
    billingAddress: {
      line1: 'addressLine1',
      line2: 'addressLine2',
      city: 'townOrCity',
      postalCode: 'SW1E 5NN',
      countryCode: 'GB',
      state: 'countyStateOrProvince',
    },
    ...(shippingAddress && { shippingAddress }),
    ...(shippingOptionId && { shippingOptionId }),
    nonce,
  }));
};

export const mockPayPalApproveUS = (nonce = 'nonce') => async (dispatch, getState) => {
  dispatch(closePayPalStubsModal());

  const shippingAddress = getMockPayPalExpressShippingAddress(getState());


  dispatch(payPalPaymentOnApprove({
    firstName: 'James',
    lastName: 'Ellroy',
    phoneNumber: '+1650-555-5555',
    billingAddress: {
      line1: '1600 Amphitheatre Parkway',
      line2: 'Mountain View',
      city: 'CA',
      postalCode: '94 043',
      countryCode: 'US',
      state: 'NJ',
    },
    ...(shippingAddress && { shippingAddress }),
    nonce,
  }));
};

export const mockPayPalOnShippingAddressChange = () => async (dispatch, getState) => {
  const state = getState()
  if (state.payment?.selectedPaymentType === paymentTypeConstants.PAYPAL_EXPRESS) {

    await dispatch(shippingAddressChange({
      shippingAddress: {
        city: 'London',
        postalCode: 'SW1E',
        countryCode: 'GB',
      },
    }))
    const payload =  await dispatch(getPayPalExpressPaymentRequest())
    return payload?.result?.shippingOptions[0].id
  }
  return null;
}
