import deliveryConstants from '../../constants/deliveryConstants';

export function orderContainsMixedDeliveryTypes(deliveries) {
  if (deliveries) {
    const containsOneMan = deliveries.some(d => d.type === deliveryConstants.ONE_MAN);
    const containsSupplierDirect = deliveries.some(d => d.type === deliveryConstants.SUPPLIER_DIRECT);
    const containsOtherTypes = deliveries.some(
      d => d.type !== deliveryConstants.SUPPLIER_DIRECT && d.type !== deliveryConstants.ONE_MAN,
    );

    return containsOneMan && containsSupplierDirect && !containsOtherTypes;
  }

  return false;
}

export function shouldShowCollectionHelpText(params) {
  const {
    deliveries: paramDeliveries,
    id,
    isChildItem,
    orderComplete,
    state = {},
  } = params;

  const deliveries = paramDeliveries || state.delivery?.deliveries;
  if (!deliveries) return false;

  if (orderComplete || state.app?.orderComplete) return false;

  if (isChildItem) return false;

  const supplierDirectDeliveries = deliveries.filter(d => d.type === deliveryConstants.SUPPLIER_DIRECT);
  const supplierDirectItemIds = supplierDirectDeliveries.reduce((acc, delivery) => [
    ...acc,
    ...delivery.items.map(d => d.id),
  ], []);
  if (!supplierDirectItemIds.includes(id)) return false;

  const showCollectionHelp = orderContainsMixedDeliveryTypes(deliveries);
  if (!showCollectionHelp) return false;

  return true;
}
