import {
  APPS_ACCESS_TOKEN_ERROR,
  AUTH0_CALLBACK,
  AUTH0_UNAVAILABLE,
  HAND_OVER,
} from '../../../constants/actionConstants';
import { getNonGlobalError } from '../../../utils/error/parseError';

export const INITIAL_STATE = {
  callbackError: undefined,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case `${AUTH0_CALLBACK}.SUCCESS`: {
      return {
        ...state,
        apiCallActive: false,
      };
    }
    case `${AUTH0_CALLBACK}.LOADING`:
      return {
        ...state,
        apiCallActive: true,
      };
    case AUTH0_UNAVAILABLE:
    case APPS_ACCESS_TOKEN_ERROR:
    case `${AUTH0_CALLBACK}.FAILED`:
      return {
        ...state,
        apiCallActive: false,
        callbackError: getNonGlobalError(action, state.callbackError),
      };
    case `${HAND_OVER}.SUCCESS`:
      return INITIAL_STATE;
    default:
      return state;
  }
}
