import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import Form from 'jl-design-system/components/form/Form';
import { Message } from 'jl-design-system/components/message/v2/Message';
import { Modal } from 'jl-design-system/components/modal/Modal';
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import Link, { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { AppDispatch } from 'types/RootState.types';
import { AgeCheckModalProps } from './AgeCheckModal.types';

// Config
import ageCheckModalState from './AgeCheckModal.state';
import { handbackTo } from '../../redux/actions/data-action-link/dataActionLinkActions';
import { removeItemsFromBasket } from '../../redux/actions/edit-basket/editBasketActions';
import { checkAgeRestrictionDate } from '../../redux/actions/user/userActions';
import ageCheckFormConfig from '../../utils/form/configs/ageCheck';
import { disabledEscKeyHandler } from '../../utils/helpers/eventKeyActions';
import { pluraliseItemWord } from '../../utils/helpers/pluraliseItem';
import { useWindowSize } from '../../context/window/WindowSizeContext';
import thumbnail from '../../utils/image/thumbnail';

// Components
import Container from '../container';
import MyAccountLink from '../my-account-link';

// Styles
import styles from './age-check-modal.scss';

const AgeCheckModal = ({ showStepIndicator }: AgeCheckModalProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    ageCheckError,
    ageCheckMinimumAge,
    ageRestrictedProducts = [],
    ageRestrictedProductsCanRemove,
    basketUrl,
    hasSavedDob,
    isApplication,
    isSignedIn,
    removeBasketItemsApiCallActive,
    removeBasketItemsFailed,
    rebatchOrderApiCallActive,
    showModal,
  } = useSelector(ageCheckModalState);

  if (!showModal) {
    return null;
  }

  const { desktopResolution, isModalSmall } = useWindowSize();

  useEffect(() => {
    document.addEventListener('keydown', disabledEscKeyHandler);

    return () => {
      document.removeEventListener('keydown', disabledEscKeyHandler);
    };
  }, []);

  const onCloseHandler = () => {
    dispatch(handbackTo(basketUrl));
  };

  const onFormSubmit = (values: { dateOfBirth: any }) => {
    const { dateOfBirth } = values;
    return dispatch(checkAgeRestrictionDate(dateOfBirth, true));
  };

  const onRemoveItemsButtonClick = () => {
    const itemIds = ageRestrictedProducts.map(item => (item.id));
    dispatch(removeItemsFromBasket({ itemIds }));
  };

  const isSignedInWithDob = isSignedIn && hasSavedDob;

  const formConfig = ageCheckFormConfig(isSignedIn, {
    submitButtonExtra: {
      extra: true,
      name: 'age-check-return-to-basket-link',
      component: Link,
      props: {
        to: basketUrl,
        'data-action': 'handback',
        'data-testid': 'age-check-modal-return-to-basket',
        children: 'Return to basket',
        className: styles.link,
      },
    },
  });

  const heading = isSignedInWithDob && ageCheckError ?
    `You must be over ${ageCheckMinimumAge} to continue` : 'Age verification';

  const singleItem = ageRestrictedProducts.length === 1;
  const ageRestrictionWithSavedDobBody =
    `Based on the information we have for you, you are not old enough to purchase the following ${pluraliseItemWord(singleItem)}.`;
  const ageRestrictionBody = singleItem ? 'The item below is age-restricted. Please confirm your age to continue.' :
    'The items below are age-restricted. Please confirm your age to continue.';

  let messageBody;
  let messageTitle = isSignedInWithDob ? ageRestrictionWithSavedDobBody : ageRestrictionBody;
  let modalProps;
  if (showStepIndicator && !isSignedInWithDob) {
    messageBody = "Please enter your date of birth to confirm you're over 18.";
    messageTitle = 'Further items in your basket are age restricted';

    modalProps = {
      headerCount: 'Step 2 of 2',
    };
  }

  return (
    <Modal
      className={styles.modal}
      footer={false}
      height={isModalSmall ? 'fixed' : 'hug'}
      isOpen
      onClose={onCloseHandler}
      title={heading}
      {...modalProps}
    >
      <Container
        className={styles.content}
        testId="age-check-modal"
      >
        <Container marginBottom="3">
          <Message
            body={messageBody}
            dataTestId="age-check-modal-message"
            title={messageTitle}
            type="warning"
          />
        </Container>

        <Container
          borderTop="grey-15"
          marginBottom={desktopResolution ? '3' : '2'}
          renderIf={ageRestrictedProducts.length}
        >
          {ageRestrictedProducts.map((product, i) => {
            const key = `product-${i}`;
            const imageSrc = product.imageUrl ? thumbnail(product.imageUrl) : '';
            return (
              <Container
                key={key}
                borderBottom="grey-15"
                className={styles.product}
                paddingY="2"
                testId="age-check-modal-product"
              >
                <Container marginRight="2">
                  {imageSrc && (
                    <img
                      alt={product.name}
                      src={imageSrc}
                    />
                  )}
                </Container>
                <p>{product.name}</p>
              </Container>
            );
          })}
        </Container>

        <Container renderIf={!!ageCheckError} testId="age-check-modal-errors">
          <Container marginBottom="2" renderIf={!isSignedInWithDob && !removeBasketItemsFailed}>
            <Message
              body={ageCheckError?.body}
              dataTestId="age-check-error"
              title={ageCheckError?.message}
              type="error"
            />
          </Container>

          <Container marginBottom="2" renderIf={removeBasketItemsFailed}>
            <Message
              dataTestId="age-check-remove-failed"
              title={`Sorry, we couldn't remove the item${singleItem ? '' : 's'}. Please return to basket.`}
              type="error"
            />
          </Container>

          <Container
            className={styles.buttonWrap}
            testId="age-check-modal-errors-button-wrap"
          >
            {(ageRestrictedProductsCanRemove && !removeBasketItemsFailed) && (
              <Link
                className={styles.link}
                data-action="handback"
                data-testid="age-check-modal-return-to-basket"
                disabled={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
                to={basketUrl}
              >
                Return to basket
              </Link>
            )}

            {(!ageRestrictedProductsCanRemove || (ageRestrictedProductsCanRemove && removeBasketItemsFailed)) && (
              <PrimaryLink
                className={styles.button}
                data-action="handback"
                data-testid="age-check-modal-return-to-basket"
                disabled={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
                to={basketUrl}
              >
                Return to basket
              </PrimaryLink>
            )}

            {(ageRestrictedProductsCanRemove && !removeBasketItemsFailed) && (
              <PrimaryButton
                className={styles.button}
                data-testid="age-check-modal-remove-items"
                onClick={onRemoveItemsButtonClick}
                submitting={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
              >
                {singleItem ? 'Remove item and continue' : 'Remove items and continue'}
              </PrimaryButton>
            )}
          </Container>

          {isSignedInWithDob && (
            <Container marginTop="2">
              <p className={styles.footer}>
                {isApplication &&
                  <>If you feel the date of birth we have for you is incorrect, you can review this in My Account.</>}
                {!isApplication &&
                  <>
                    If you feel the date of birth we have for you is incorrect,
                    you can review this in <MyAccountLink />.
                  </>}
              </p>
            </Container>
          )}
        </Container>

        <Container renderIf={!ageCheckError}>
          <Form
            config={formConfig}
            data-testid="age-check-modal-form"
            form={formConfig.id}
            multiFormValidation
            onSubmit={onFormSubmit}
            useV2Message
          />
        </Container>
      </Container>
    </Modal>
  );
};

export default AgeCheckModal;
