export default {
  SMALL_MOBILE_BREAKPOINT: '370px',
  MOBILE_BREAKPOINT: '568px',
  CONTENT_SQUARE_MASK_ATTR: 'data-cs-mask',
  PERCY_MASK_ATTR: 'data-percy-mask',
  TEST_ID_ATTR: 'data-testid',
  MODAL_SMALL: '599px',
  DESKTOP_BREAKPOINT: '768px',
  DESKTOP_SM_BREAKPOINT: '1024px',
  SIDEBAR_MIN_BREAKPOINT: '1160px',
  DESKTOP_LG_BREAKPOINT: '1280px',
  TITLE_DEFAULT: 'Checkout | John Lewis & Partners',
  TITLE_EXPIRY_WARNING: '** Session expiring **',
  ROOT: 'page-root',
  COUNTRIES: 'countries',
  DELIVERY_INSTRUCTIONS: [{
    label: 'Additional directions',
  }, {
    label: 'Lift or stairs access',
  }, {
    label: 'Access restrictions',
  }, {
    label: 'Someone else accepting delivery',
  }, {
    label: 'I need extra time',
  }, {
    label: 'I have vision impairments',
  }, {
    label: 'I have hearing impairments',
  }, {
    label: 'Other',
  }],
  CALL_CENTRE_NUMBERS: {
    standard: '03456 049 049',
    enhanced: '03456 100 383',
    partnership: '0330 175 9829',
  },
  BFF_TIMEOUT: 35000,
  AUTH0_TOKEN_TIMEOUT: 10,
  CHECKOUT_AUTH0_TOKEN_TIMEOUT: 10000,
};
