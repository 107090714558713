import routeConstants from '../../constants/routeConstants';

export function isDeliveryPage({
  editBasketModalBaseRoutePath = '',
  leaveCheckoutModalBaseRoutePath = '',
  pathname,
}: { editBasketModalBaseRoutePath?: string; leaveCheckoutModalBaseRoutePath?: string; pathname: string }): boolean {
  if (
    pathname.includes(routeConstants.DELIVERY)
    || pathname.includes(routeConstants.CLICK_AND_COLLECT)
    || (
      pathname.includes(routeConstants.EDIT_BASKET)
      && (
        editBasketModalBaseRoutePath.includes(routeConstants.DELIVERY)
        || editBasketModalBaseRoutePath.includes(routeConstants.CLICK_AND_COLLECT)
      ))
    || (
      pathname.includes(routeConstants.LEAVE_CHECKOUT)
      && (
        leaveCheckoutModalBaseRoutePath.includes(routeConstants.DELIVERY) ||
        leaveCheckoutModalBaseRoutePath.includes(routeConstants.CLICK_AND_COLLECT)
      ))
  ) {
    return true;
  }

  return false;
}

export function isPaymentPage({
  editBasketModalBaseRoutePath = '',
  leaveCheckoutModalBaseRoutePath = '',
  pathname,
}: { editBasketModalBaseRoutePath?: string; leaveCheckoutModalBaseRoutePath?: string; pathname: string }): boolean {
  if (
    pathname.includes(routeConstants.PAYMENT)
    || pathname.includes(routeConstants.PAYMENT_POS_CREDIT_CALLBACK)
    || (
      pathname.includes(routeConstants.EDIT_BASKET)
      && editBasketModalBaseRoutePath.includes(routeConstants.PAYMENT)
    )
    || (
      pathname.includes(routeConstants.LEAVE_CHECKOUT)
      && leaveCheckoutModalBaseRoutePath.includes(routeConstants.PAYMENT)
    )
  ) {
    return true;
  }

  return false;
}

export function isOrderConfirmationPage(pathname: string): boolean {
  return pathname.includes(routeConstants.ORDER_CONFIRMATION);
}
