// Design System
import visaLogo from 'jl-design-system/assets/images/payment-icons/visa.svg';
import mastercardLogo from 'jl-design-system/assets/images/payment-icons/mastercard.svg';
import amexLogo from 'jl-design-system/assets/images/payment-icons/amex.svg';
import partnership2022Logo from 'jl-design-system/assets/images/payment-icons/partnership-2022.png';
import maestroLogo from 'jl-design-system/assets/images/payment-icons/maestro.svg';
import businessAccountLogo from 'jl-design-system/assets/images/payment-icons/business-account.png';

// Config
import supportedCreditCardTypes from '../../constants/supportedCreditCardTypes';

export default function cardLogos(cardType: string) {
  let logo;

  switch (cardType) {
    case (supportedCreditCardTypes.AMERICAN_EXPRESS):
      logo = amexLogo;
      break;
    case (supportedCreditCardTypes.BUSINESS_ACCOUNT):
      logo = businessAccountLogo;
      break;
    case (supportedCreditCardTypes.MAESTRO):
      logo = maestroLogo;
      break;
    case (supportedCreditCardTypes.ANY_DAY):
    case (supportedCreditCardTypes.MASTERCARD):
      logo = mastercardLogo;
      break;
    case (supportedCreditCardTypes.NEW_DAY):
      logo = partnership2022Logo;
      break;
    case (supportedCreditCardTypes.VISA):
    case (supportedCreditCardTypes.VISA_DEBIT):
      logo = visaLogo;
      break;
    default:
      logo = undefined;
  }

  return logo;
}

export function cardTypeText(cardType: string) {
  let text;

  switch (cardType) {
    case (supportedCreditCardTypes.AMERICAN_EXPRESS):
      text = 'American Express';
      break;
    case (supportedCreditCardTypes.BUSINESS_ACCOUNT):
      text = 'Business Account';
      break;
    case (supportedCreditCardTypes.MAESTRO):
      text = 'Maestro';
      break;
    case (supportedCreditCardTypes.ANY_DAY):
    case (supportedCreditCardTypes.MASTERCARD):
      text = 'Mastercard';
      break;
    case (supportedCreditCardTypes.NEW_DAY):
      text = 'Partnership Card';
      break;
    case (supportedCreditCardTypes.VISA):
    case (supportedCreditCardTypes.VISA_DEBIT):
      text = 'Visa';
      break;
    default:
      text = '';
  }

  return text;
}
