import UserEmailAddress from '../../../components/user-email-address';

const USER_EMAIL_ADDRESS = {
  component: UserEmailAddress,
  id: 'userEmailAddress',
  name: 'userEmailAddress',
  props: {
    isClickAndCollect: true,
  },
};

export default USER_EMAIL_ADDRESS;
