// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import deliveryConstants, { fulfilmentTypes } from '../../constants/deliveryConstants';
import appConstants from '../../constants/appConstants';

export function isGreenVanFleet(deliveryType) {
  return deliveryType === deliveryConstants.GREEN_VAN_FLEET;
}

export function isGreenVanFleetExCatchment(deliveryType, fulfilment) {
  return isGreenVanFleet(deliveryType) &&
    get(fulfilment, 'bookingType', '') === deliveryConstants.NIGHTFREIGHT;
}

export function isGreenVanFleetUnavailable(deliveryType, fulfilment) {
  return !!(isGreenVanFleet(deliveryType) && !fulfilment.deliveryInfo?.date);
}

export function isMadeToMeasureDelivery(deliveryType) {
  return deliveryType === deliveryConstants.MADE_TO_MEASURE;
}

export function isOneManPremiumDelivery(delivery) {
  return delivery.type === deliveryConstants.ONE_MAN && !!get(delivery.fulfilment, 'deliveryInfo.date');
}

export function isOneManMorningDelivery(delivery) {
  return isOneManPremiumDelivery(delivery) &&
    delivery.fulfilment.fulfilmentType === fulfilmentTypes.ONE_PERSON_NAMED_DAY_MORNING;
}

export function isOneManEveningDelivery(delivery) {
  return isOneManPremiumDelivery(delivery) &&
    delivery.fulfilment.fulfilmentType === fulfilmentTypes.ONE_PERSON_NAMED_DAY_EVENING;
}

export function isOneManDayDelivery(delivery) {
  return isOneManPremiumDelivery(delivery) &&
    delivery.fulfilment.fulfilmentType === fulfilmentTypes.ONE_PERSON_NAMED_DAY;
}

export function isClickCollect(delivery) {
  return !!get(delivery, 'fulfilment.collectionInfo');
}

export function getDeliveryInstructionDropdownOptions(
  selectedDeliveryMethodId,
  deliveryMethods,
) {
  const deliveryInstructions = appConstants.DELIVERY_INSTRUCTIONS;

  if (!isEmpty(deliveryMethods) && selectedDeliveryMethodId) {
    const filteredDeliveryInstructions = deliveryInstructions;

    const availableOptions = filteredDeliveryInstructions
      .map(item => ({
        ...item,
        value: item.label,
        label: item.label,
        prefix: `${item.label}:`,
      }));

    availableOptions.unshift({
      label: 'Select an option:',
      value: '',
    });

    return availableOptions;
  }

  return [];
}
