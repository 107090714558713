// Types
import { RootState } from 'types/RootState.types';

export const getConfirmationExpiredSignedInSelector =
    (state: RootState) => state.confirmation?.expiredSignedIn || false;
export const getConfirmationPageApiCallActiveSelector =
    (state: RootState) => state.confirmation?.getOrderConfirmationPageApiCallActive || false;
export const getConfirmationPageApiCallCompleteSelector =
    (state: RootState) => state.confirmation?.getOrderConfirmationPageApiCallComplete || false;
export const getConfirmationIsClickCollectOrderSelector =
    (state: RootState) => state.confirmation?.isClickCollectOrder || false;
export const getConfirmationOldCutOffTimeSelector = (state: RootState) => state.confirmation?.oldCutOffTime || '';
export const getConfirmationOrderSelector = (state: RootState) => state.confirmation?.order;
export const getConfirmationOrderAmountsSelector = (state: RootState) => state.confirmation?.order?.amounts;
export const getConfirmationOrderDeliveriesSelector = (state: RootState) => state.confirmation?.order?.deliveries;
export const getConfirmationOrderDetailsSelector = (state: RootState) => state.confirmation?.orderDetails;
export const getConfirmationOrderPlacedAfterClickCollectCutOffSelector =
    (state: RootState) => state.confirmation?.orderPlacedAfterClickCollectCutOff || false;
export const getConfirmationShowCreateAccountPromptSelector =
    (state: RootState) => state.confirmation?.showCreateAccountPrompt || false;
export const getConfirmationShowSaveOrderPromptSelector =
    (state: RootState) => state.confirmation?.showSaveOrderPrompt || false;
export const getConfirmationShowTradeInBoxInOrderConfirmationSelector =
    (state: RootState) => state.confirmation?.showTradeInBoxInOrderConfirmation || false;
export const getConfirmationIsMultiBatchSelector =
    (state: RootState) => state.confirmation?.isMultiBatch || false;
export const getConfirmationPromoCodeAppliedSelector = (state: RootState) => state.confirmation?.promoCodeApplied;
