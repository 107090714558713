//
import {
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED,
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED,
} from '../../../constants/actionConstants';

export const triggerAnalyticsEvent = (type, payload) => ({
  type,
  ...payload,
});

export const triggerAnalyticsForUncompletedActions = uncompletedActions => (dispatch) => {
  if (uncompletedActions.includes('linkOrderToAccount') && uncompletedActions.includes('savePersonalDetails')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED));
  } else if (uncompletedActions.includes('linkOrderToAccount')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED));
  } else if (uncompletedActions.includes('savePersonalDetails')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED));
  } else if (uncompletedActions.includes('savePaymentCard')) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED));
  }
};

export default triggerAnalyticsEvent;
