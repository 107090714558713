import { createSelector } from 'reselect';

// Config
import { getLoadingLazyComponentSelector } from '../../redux/reducers/app/appSelector';
import { getConfirmationShowTradeInBoxInOrderConfirmationSelector } from '../../redux/reducers/confirmation/orderConfirmationSelector';
import {
  getBatchesDeliveryMethodsApiCallActiveSelector,
  getDeliveryProceedingToPaymentSelector,
  getPostAgeVerificationApiCallsActiveSelector,
  getPutDeliveryAddressApiCallActiveSelector,
} from '../../redux/reducers/delivery/deliverySelector';
import { getTradeInQuotesSelector } from '../../redux/reducers/order-form/orderFormSelector';
import {
  getInitPaymentPageCallActiveSelector,
  getPaymentProcessingSelector,
} from '../../redux/reducers/payment/paymentSelector';
import { getRouterLocationPathname } from '../../redux/reducers/router/routerSelector';

const orderSummaryState = createSelector(
  [
    getBatchesDeliveryMethodsApiCallActiveSelector,
    getInitPaymentPageCallActiveSelector,
    getLoadingLazyComponentSelector,
    getRouterLocationPathname,
    getPaymentProcessingSelector,
    getPostAgeVerificationApiCallsActiveSelector,
    getDeliveryProceedingToPaymentSelector,
    getPutDeliveryAddressApiCallActiveSelector,
    getConfirmationShowTradeInBoxInOrderConfirmationSelector,
    getTradeInQuotesSelector,
  ],
  (
    getBatchesDeliveryMethodsApiCallActive,
    initPaymentPageCallActive,
    loadingLazyComponent,
    pathname,
    paymentProcessing,
    postAgeVerificationApiCallsActive,
    proceedingToPayment,
    putDeliveryAddressApiCallActive,
    showTradeInBoxInOrderConfirmation,
    tradeInQuotes,
  ) => ({
    getBatchesDeliveryMethodsApiCallActive,
    initPaymentPageCallActive,
    isLoadingLazyComponent: loadingLazyComponent === 'EditBasketModal',
    pathname,
    paymentProcessing,
    postAgeVerificationApiCallsActive,
    proceedingToPayment,
    putDeliveryAddressApiCallActive,
    showTradeInBoxInOrderConfirmation,
    tradeInQuotes,
  }),
);

export default orderSummaryState;
