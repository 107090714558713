import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//
import env from 'jl-design-system/utils/env/env';
import { ALPHA__Toast as Toast } from 'jl-design-system/components/toast/ALPHA/Toast';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';
//
import { shouldShowUserActiveContent } from '../../utils/app/shouldShowUserActiveContent';
import {
  getIsRewardApplicableSelector,
  getRewardsSelector,
} from '../../redux/reducers/order-form/orderFormSelector';
import {
  getDeliveryPageApiCallCompleteSelector,
  getShowAgeVerificationSaveForLaterToastSelector,
} from '../../redux/reducers/delivery/deliverySelector';
import { getHasToastBeenShown } from '../../redux/reducers/app/appSelector';
import { markToastAsShown } from '../../redux/actions/app/appActions';
import { getShowAgeCheckModalSelector } from '../../redux/reducers/user/userSelector';
import { getMyJLRewardsMessage } from '../../utils/myjl/getMyJLRewardsMessage';

const commonToastProps = {
  isOpen: true,
  shouldCloseOnTimeout: true,
  timeoutDuration: env.isClientNonProd ? 3000 : 8000,
  type: 'success',
};

const RewardsToast = ({ onClose }: {
  onClose: () => void;
}) => {
  const rewards = useSelector((state: RootState) => getRewardsSelector(state));
  if (!rewards) return null;

  return (
    <Toast
      {...commonToastProps}
      message={getMyJLRewardsMessage(rewards)}
      onClose={onClose}
    />
  );
};

const AgeVerificationSaveForLaterToast = ({ onClose }: {
  onClose: () => void;
}) => (
  <Toast
    {...commonToastProps}
    message={<>You can find your items in your basket under<br />&apos;Saved for later&apos;</>}
    onClose={onClose}
  />
);

const ToastRack = () => {
  const dispatch: AppDispatch = useDispatch();
  const generalConditionsPassed = useSelector((state: RootState) => {
    const showUserActiveContent = shouldShowUserActiveContent(state);
    const hasToastbeenShown = getHasToastBeenShown(state);
    const ageCheckModalIsVisible = getShowAgeCheckModalSelector(state);

    return showUserActiveContent && !hasToastbeenShown && !ageCheckModalIsVisible;
  });

  const onCloseHandler = () => dispatch(markToastAsShown());

  const showRewardsToast = useSelector((state: RootState) => {
    const isRewardApplicable = getIsRewardApplicableSelector(state);
    const getDeliveryPageApiCallComplete = getDeliveryPageApiCallCompleteSelector(state);

    return isRewardApplicable && getDeliveryPageApiCallComplete;
  });

  const showAgeVerificationSaveForLaterToast = useSelector((
    state: RootState,
  ) => getShowAgeVerificationSaveForLaterToastSelector(state));

  if (!generalConditionsPassed) return null;

  if (showAgeVerificationSaveForLaterToast) {
    return <AgeVerificationSaveForLaterToast onClose={onCloseHandler} />;
  }

  if (showRewardsToast) {
    return <RewardsToast onClose={onCloseHandler} />;
  }

  return null;
};

export default ToastRack;
