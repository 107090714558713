import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
// jl-design-system
import { UnderlinedButton } from 'jl-design-system/elements/button/Button';
// lodash
import get from 'lodash/get';
// components
import BodyText from '../body-text';
import Heading from '../heading';
import SaveAsDefaultForm from '../save-as-default-form/SaveAsDefaultForm';
import ClickAndCollectStoreIcon from '../click-and-collect-store-icon';
// styles
import styles from './display-address.scss';

class DisplayAddress extends PureComponent {
  static propTypes = {
    address: PropTypes.objectOf(PropTypes.string),
    addressTitle: PropTypes.string,
    addressee: PropTypes.objectOf(PropTypes.string),
    collectionAddressTitle: PropTypes.string,
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    displayAddressTitle: PropTypes.string,
    displayStoreNameInline: PropTypes.bool,
    hideMoreDetailsLink: PropTypes.bool,
    highlightName: PropTypes.bool,
    inline: PropTypes.bool,
    isBillingAddress: PropTypes.bool,
    isClickCollectOrder: PropTypes.bool,
    isContactAddress: PropTypes.bool,
    isPaymentAddress: PropTypes.bool,
    isUndeliverableAddress: PropTypes.bool,
    openMoreDetailsModal: PropTypes.func,
    phoneNumber: PropTypes.string,
    renderPostcodeLast: PropTypes.bool,
    saveAddressError: PropTypes.shape({}),
    saveAsDefaultObject: PropTypes.shape({}),
    saveAsDefaultType: PropTypes.string,
    selectedCollectionPoint: PropTypes.shape({}),
  };

  static defaultProps = {
    'data-testid': '',
    address: {},
    addressTitle: '',
    addressee: {},
    collectionAddressTitle: '',
    disabled: false,
    displayAddressTitle: '',
    displayStoreNameInline: false,
    hideMoreDetailsLink: false,
    highlightName: false,
    inline: false,
    isBillingAddress: false,
    isClickCollectOrder: false,
    isContactAddress: false,
    isPaymentAddress: false,
    isUndeliverableAddress: false,
    openMoreDetailsModal: window.defaultFunc,
    phoneNumber: '',
    renderPostcodeLast: false,
    saveAddressError: undefined,
    saveAsDefaultObject: {},
    saveAsDefaultType: '',
    selectedCollectionPoint: undefined,
  };

  static renderAddressItem(address, key, showComma) {
    return (
      <BodyText key={key} maskText={(key !== 'townOrCity' && key !== 'postcode')} noTheme tag="span" testId={`address-${kebabCase(key)}`}>
        {`${address[key]}${showComma}`}
      </BodyText>
    );
  }

  transformAddress = () => {
    const {
      address,
      address: {
        addressLine1 = '',
        addressLine2,
      },
      collectionAddressTitle,
      displayStoreNameInline,
    } = this.props;

    const startsWithNumber = addressLine1.match(/^[0-9]+$/);

    return ({
      storeName: displayStoreNameInline ? collectionAddressTitle : null,
      ...address,
      addressLine1: startsWithNumber && addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1,
      addressLine2: startsWithNumber ? '' : addressLine2,
    });
  };

  getAddressType = () => {
    const {
      isContactAddress,
      isPaymentAddress,
      isBillingAddress,
    } = this.props;

    if (isContactAddress) {
      return (
        <span
          className={styles.addressType}
          data-testid="display-address-type-contact"
        >
          (Contact address)
        </span>
      );
    }

    if (isBillingAddress) {
      return (
        <span
          className={styles.addressType}
          data-testid="display-address-type-payment"
        >
          (Billing address)
        </span>
      );
    }

    if (isPaymentAddress) {
      return (
        <span
          className={styles.addressType}
          data-testid="display-address-type-payment"
        >
          (Payment address)
        </span>
      );
    }

    return null;
  }

  render() {
    const {
      addressee,
      displayAddressTitle,
      phoneNumber,
      inline,
      isClickCollectOrder,
      renderPostcodeLast,
      collectionAddressTitle,
      openMoreDetailsModal,
      hideMoreDetailsLink,
      highlightName,
      addressTitle,
      'data-testid': dataTest,
      saveAsDefaultObject,
      saveAsDefaultType,
      disabled,
      isUndeliverableAddress,
      displayStoreNameInline,
      selectedCollectionPoint,
      saveAddressError,
    } = this.props;

    const transformedAddress = this.transformAddress();
    const titleClassName = inline ? styles.inlineTitleArea : styles.titleArea;
    const addressClassName = inline ? styles.inlineAddress : classNames(styles.address, {
      [styles.addressWithIcon]: isClickCollectOrder,
    });
    const comma = inline ? ',' : '';
    const addressKeys = Object.keys(transformedAddress);

    const countyKeyExists = !!get(transformedAddress, 'countyStateOrProvince', null);
    if (renderPostcodeLast || countyKeyExists) {
      addressKeys.push(addressKeys.splice(addressKeys.indexOf('postcode'), 1)[0]);
    }

    const title = isClickCollectOrder ? collectionAddressTitle : displayAddressTitle;
    const addresseeTitle = addressee?.title ? `${addressee.title} ` : '';

    return (
      <div
        className={classNames(styles.container, {
          [styles.disabled]: disabled,
        })}
        data-testid={dataTest}
      >

        { this.getAddressType() }

        {
          (title && !displayStoreNameInline) &&
          <div className={titleClassName}>
            <Heading
              label={title}
              marginBottom="1"
              tag="h3"
              testId="display-address-title"
              type="xs"
            />
          </div>
        }

        <div className={addressClassName}>
          {
            isClickCollectOrder &&
            <ClickAndCollectStoreIcon store={selectedCollectionPoint} />
          }

          <>
            {addressTitle && (
              <Heading
                label={addressTitle}
                marginBottom="2"
                tag="h3"
                testId="display-address-address-title"
                type="xs"
              />
            )}
            {
              !isClickCollectOrder && (addressee.title || addressee.firstName || addressee.lastName) && (
                <BodyText maskText noTheme tag={highlightName ? 'strong' : 'span'} testId="addressee">
                  {`${addresseeTitle}${addressee.firstName} ${addressee.lastName}${comma}`}
                </BodyText>
              )
            }
            {
              addressKeys.map((key, i) => {
                const nextKey = addressKeys[i + 1];
                const comma = inline && nextKey in transformedAddress ? ',' : '';

                if (!transformedAddress[key] || key === 'countryCode') {
                  return '';
                }

                return DisplayAddress.renderAddressItem(transformedAddress, key, comma);
              })
            }
            {
              transformedAddress.countryCode && transformedAddress.countryCode !== 'GB' &&
              DisplayAddress.renderAddressItem(transformedAddress, 'countryCode', comma)
            }
            {
              !isClickCollectOrder && phoneNumber && (
                <BodyText maskText noTheme tag="span" testId="address-phone">
                  {phoneNumber}
                </BodyText>
              )
            }
          </>
        </div>
        {
          isClickCollectOrder && !hideMoreDetailsLink &&
          <UnderlinedButton
            className={styles.moreDetailsButton}
            data-testid="more-details-button"
            onClick={openMoreDetailsModal}
          >
            Map and opening times
          </UnderlinedButton>
        }

        {
          !isUndeliverableAddress &&
          !saveAddressError &&
          <SaveAsDefaultForm
            saveAsDefaultObject={saveAsDefaultObject}
            type={saveAsDefaultType}
          />
        }

      </div>
    );
  }
}

export default DisplayAddress;
