import {
  APPS_ACCESS_TOKEN_PROVIDED,
  APPS_REGISTER_CALLBACKS,
} from '../../../constants/actionConstants';
import {
  applePayEligibleOnDevice,
  applePayPaymentSuccess,
  applePayPaymentCancel,
  applePayPaymentFailure,
  getOrderData,
  getOrderDataIOS,
} from './applePayAppsActions';
import {
  googlePayEligibleOnDevice,
  onGooglePayPaymentSuccess,
  onGooglePayPaymentFailure,
  onGooglePayPaymentCancel,
} from './googlePayAppsActions';

export const registerAppsCallbacks = callbacks => ({
  type: APPS_REGISTER_CALLBACKS,
  callbacks,
});

export const accessTokenProvided = token => ({
  type: APPS_ACCESS_TOKEN_PROVIDED,
  token,
});

const registerCallbacks = store => async (callbacks) => {
  store.dispatch(registerAppsCallbacks(callbacks));
};

const setAccessToken = store => async (token) => {
  store.dispatch(accessTokenProvided(token));
};

export default store => ({
  getOrderData: getOrderData(store),
  registerCallbacks: registerCallbacks(store),
  setAccessToken: setAccessToken(store),
  getOrderDataIOS: getOrderDataIOS(store),
  setCanMakeApplePayPayments: applePayEligibleOnDevice(store),
  applePayPaymentSuccess: applePayPaymentSuccess(store),
  applePayPaymentCancel: applePayPaymentCancel(store),
  applePayPaymentFailure: applePayPaymentFailure(store),
  setCanMakeGooglePayPayments: googlePayEligibleOnDevice(store),
  googlePayPaymentSuccess: onGooglePayPaymentSuccess(store),
  googlePayPaymentFailure: onGooglePayPaymentFailure(store),
  googlePayPaymentCancel: onGooglePayPaymentCancel(store),
});
