import React from 'react';

// Types
import { AgeRestrictedMessageProps as LazyComponentProps } from './AgeRestrictedMessage.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "ageRestrictedMessage" */ './AgeRestrictedMessage')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'AgeRestrictedMessage');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as AgeRestrictedMessage };
