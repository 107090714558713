import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reportErrorToNewRelic } from '../../utils/logging/logging-utils';

import styles from './error-boundary.scss';
import TechnicalError from '../../components/technical-error';

export class ErrorBoundary extends Component {

  static propTypes = {
    children: PropTypes.node,
    reportErrorToNewRelic: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    reportErrorToNewRelic: window.defaultFunc,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const { reportErrorToNewRelic } = this.props;

    reportErrorToNewRelic({
      error,
      errorDescription: 'checkout-ui error',
      errorInfo,
    });
  }

  render() {
    const {
      children,
    } = this.props;

    const { error } = this.state;

    if (error) {
      return (
        <div className={styles.app}>
          <main className={styles.main} id="main">
            <TechnicalError error={error} />
          </main>
        </div>
      );
    }

    return children;
  }
}

export function mapDispatchToProps() {
  return { reportErrorToNewRelic };
}

export default connect(null, mapDispatchToProps())(ErrorBoundary);
