import React from 'react';
import { useSelector } from 'react-redux';

// Design System
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import Link, { SecondaryLink } from 'jl-design-system/elements/link/Link';
import reload from 'jl-design-system/utils/reload/reload';

// Types
import { TechnicalErrorProps } from './TechnicalError.types';

// Config
import { getBasketUrlSelector } from '../../redux/reducers/bff/bffSelector';
import urlConstants from '../../constants/urlConstants';

// Components
import BodyText from '../body-text';
import Container from '../container';
import Heading from '../heading';

const TechnicalError = ({
  pageNotFoundTechnicalError = false,
}: TechnicalErrorProps) => {
  const basketUrl = useSelector(getBasketUrlSelector) || urlConstants.BASKET_PAGE_URL;
  let LinkTag = SecondaryLink;
  let message;
  let title;

  if (pageNotFoundTechnicalError) {
    message = 'Please return to basket and try again.';
    title = 'Sorry, this page is unavailable';
  } else {
    LinkTag = Link;
    message = 'Please reload checkout and try again, or return to your basket if the problem persists.';
    title = "Sorry, we've had a technical problem";
  }

  return (
    <Container
      maxWidth="sm"
      paddingX="2"
      textAlign="center"
    >
      <Heading
        label={title}
        marginBottom="2"
        type="s"
      />
      <BodyText marginBottom="3">{message}</BodyText>
      {!pageNotFoundTechnicalError && (
        <Container>
          <PrimaryButton
            inline
            onClick={reload}
          >
            Reload checkout
          </PrimaryButton>
        </Container>
      )}
      <LinkTag
        data-action="handback"
        to={basketUrl}
      >
        Return to basket
      </LinkTag>
    </Container>
  );
};

export default TechnicalError;
