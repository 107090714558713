import {
  COLLECT_PLUS_OWNER_ID,
  ROYAL_MAIL_OWNER_ID,
  CO_OP_MIDCOUNTIES_OWNER_ID,
  EVRI_OWNER_ID,
  SHELL_OWNER_ID,
} from '../../constants/clickCollectConstants';

export const isExtendedNetworkStore = ownerId => [
  COLLECT_PLUS_OWNER_ID,
  CO_OP_MIDCOUNTIES_OWNER_ID,
  EVRI_OWNER_ID,
  ROYAL_MAIL_OWNER_ID,
  SHELL_OWNER_ID,
].includes(ownerId);

export default function isCollectionPointAvailable(collectionPoint = {}, deliveryOptions = {}) {
  const collectPlusAvailable = deliveryOptions?.collectPlusAvailable;

  if (!collectPlusAvailable && isExtendedNetworkStore(collectionPoint.ownerId)) return false;

  return collectionPoint.isAvailable;
}
