import React from 'react';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "editBasketModal" */ './EditBasketModal')));

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'EditBasketModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as EditBasketModal };
