import stripWhitespace from '../../string/stripWhitespace';
import supportedCreditCardTypes from '../../../constants/supportedCreditCardTypes';

export function getCardNumberInputClassName(cardType) {
  const isSupportedCardType = Object.values(supportedCreditCardTypes).includes(cardType);
  const formattedCardType = isSupportedCardType ? stripWhitespace(cardType) : '';
  return `cardNumberField${formattedCardType}`;
}

export function getCvvClassName(cardType) {
  if (!cardType) {
    return 'securityCodeField';
  }

  return (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS)
    ? 'securityCodeFieldAmex' : 'securityCodeFieldNonAmex';
}
