// Types
import { ProductProps } from 'types/Product.types';

export const itemIsM2M = (type?: string): boolean | undefined => type?.startsWith('m2m');

export const itemsContainM2M = (items: ProductProps[] = []): boolean => items.some(item => itemIsM2M(item.type));

export default function isItemFabric(item: ProductProps = {}): boolean {
  const { type, uom } = item;

  return !!(uom && !itemIsM2M(type));
}
