import isEmpty from 'lodash/isEmpty';

// Types
import { ProductProps } from 'types/Product.types';

// Config
import deliveryConstants from '../../constants/deliveryConstants';

export default function getUnavailableItems(items: ProductProps[] = []): {
  allItemsInBasketUnavailable: boolean;
  unavailableItemsInBasket: ProductProps[];
} {
  const unavailableItemsInBasket = items.filter(
    item => item.availability?.availabilityStatus === deliveryConstants.UNAVAILABLE,
  );

  const allItemsInBasketUnavailable = !!(
    !isEmpty(unavailableItemsInBasket) && unavailableItemsInBasket.length === items.length
  );

  return {
    allItemsInBasketUnavailable,
    unavailableItemsInBasket,
  };
}
