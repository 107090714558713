import { createSelector } from 'reselect';

// Config
import { getOrderCompleteSelector } from '../../redux/reducers/app/appSelector';
import { getDeliveriesSelector } from '../../redux/reducers/delivery/deliverySelector';

const orderItemState = createSelector(
  [
    getOrderCompleteSelector,
    getDeliveriesSelector,
  ],
  (
    orderComplete,
    deliveries,
  ) => ({
    orderComplete,
    deliveries,
  }),
);

export default orderItemState;
