import {
  APPS_ACCESS_TOKEN_ERROR,
  GET_LOGIN_PAGE,
  HAND_OVER_RESET,
  LOGIN_CREDS,
  ROUTER_LOCATION_CHANGE,
  AUTH0_CALLBACK,
  AUTH0_UNAVAILABLE,
  SHOW_EMAIL_VALIDATION_ERROR,
} from '../../../constants/actionConstants';
import loginTypeConstants from '../../../constants/loginTypeConstants';

export const INITIAL_STATE = {
  apiCallActive: false,
  getLoginPageComplete: false,
  loginType: undefined,
  showEmailValidationError: false,
};

export default function loginReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {

    case `${GET_LOGIN_PAGE}.LOADING`:
      return {
        ...state,
        getLoginPageComplete: false,
      };
    case `${GET_LOGIN_PAGE}.FAILED`:
      return {
        ...state,
        getLoginPageComplete: false,
      };
    case `${GET_LOGIN_PAGE}.SUCCESS`:
      return {
        ...state,
        getLoginPageComplete: true,
      };

    case `${LOGIN_CREDS}.LOADING`:
      return {
        ...state,
        apiCallActive: true,
      };
    case `${LOGIN_CREDS}.FAILED`:
      return {
        ...state,
        apiCallActive: false,
      };
    case `${LOGIN_CREDS}.SUCCESS`: {
      return {
        ...state,
        apiCallActive: false,
        loginType: loginTypeConstants.WEB_LEGACY,
        showEmailValidationError: false,
      };
    }
    case ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        apiCallActive: false,
      };
    case HAND_OVER_RESET:
      return {
        ...state,
        loginType: undefined,
      };

    case `${AUTH0_CALLBACK}.SUCCESS`:
      return {
        ...state,
        loginType: loginTypeConstants.WEB_AUTH0,
      };

    case APPS_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        loginType: loginTypeConstants.CUSTOMER_APPS_FALLBACK,
      };
    case AUTH0_UNAVAILABLE:
      return {
        ...state,
        loginType: loginTypeConstants.WEB_AUTH0_FALLBACK,
      };
    case SHOW_EMAIL_VALIDATION_ERROR:
      return {
        ...state,
        showEmailValidationError: true,
      };
    default:
      return state;
  }
}
