import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';
import ChevronRight24PxOutlinedIcon from 'jl-design-system/elements/icons-jb/ChevronRight24PxOutlined';

// Types
import { OrderSummaryProps, OrderSummaryTitleProps } from './OrderSummary.types';

// Config
import orderSummaryState from './OrderSummary.state';
import { triggerExpandBasketButtonClickedAnalytics } from '../../redux/actions/app/appActions';
import { showEditBasketModal } from '../../redux/actions/edit-basket/editBasketActions';
import { getFormId } from '../../utils/form/configs/itemQuantity';
import { pluraliseItemWord } from '../../utils/helpers/pluraliseItem';

// Components
import Container from '../container';
import ExpandableContent from '../expandable-content';
import Heading from '../heading';
import LoadingWrapper from '../loading-wrapper';
import OrderItem from '../order-item';
import TradeInDetails from '../trade-in-details';

// Styles
import styles from './order-summary.scss';

const itemsNeededToEnableExpander = 3;

export const OrderSummaryTitle = ({
  button,
  id,
  isLoading = false,
  label,
  tag = 'div',
  totalQuantity = 1,
}: OrderSummaryTitleProps) => {
  const heading = `(${totalQuantity} ${pluraliseItemWord(totalQuantity === 1)})`;

  return (
    <Container
      borderBottom="grey-15"
      className={styles.sidebarHeading}
      paddingX="3"
      paddingY="1.5"
      tag={tag}
    >
      <LoadingWrapper isLoading={isLoading} type="dark">
        <Heading
          className={styles.headingTitle}
          id={id}
          tag="h2"
          testId="order-summary-header"
          type="s"
        >{label} <span>{heading}</span>
        </Heading>
      </LoadingWrapper>

      {button && (
        <LoadingWrapper isLoading={isLoading}>
          {button}
        </LoadingWrapper>
      )}
    </Container>
  );
};

const OrderSummary = ({
  classNames = {},
  collapsed = false,
  displayHeaders = true,
  form,
  hideChildItems = false,
  hideEditBasket = false,
  hideOutOfStockText = false,
  hideQuantities = false,
  hideSubtotals = false,
  isMultiBatch = false,
  isOrderConfirmation = false,
  items = [],
  modalView = false,
  preventEditing = false,
  showReducedQuantityDetails = false,
  sidebarView = false,
}: OrderSummaryProps) => {
  const dispatch = useDispatch<any>();
  const {
    pathname,
    initPaymentPageCallActive,
    isLoadingLazyComponent,
    putDeliveryAddressApiCallActive,
    proceedingToPayment,
    getBatchesDeliveryMethodsApiCallActive,
    paymentProcessing,
    postAgeVerificationApiCallsActive,
    tradeInQuotes = [],
    showTradeInBoxInOrderConfirmation,
  } = useSelector(orderSummaryState);
  const elRef = useRef<HTMLDivElement>(null);

  const isLoading = items?.length === 0;

  const handleShowEditBasketModalClick = () => {
    dispatch(showEditBasketModal(pathname));
  };

  const setInlineHeightStyles = () => {
    const orderSummaryItems = elRef?.current?.querySelectorAll('[data-index^="order-summary-item"]');

    if (orderSummaryItems && !collapsed && !isLoading) {
      if (orderSummaryItems.length >= itemsNeededToEnableExpander) {
        const heightContracted = orderSummaryItems[0].clientHeight + orderSummaryItems[1].clientHeight;
        setContainerHeight(heightContracted);
      } else {
        removeContainerHeight();
      }
    }
  };

  useEffect(() => {
    if (sidebarView) setInlineHeightStyles();
  });

  const getContainerElement = () => elRef.current?.querySelector<HTMLDivElement>('[data-testid="order-summary-item-container"]');

  const removeContainerHeight = () => {
    const containerElement = getContainerElement();
    if (containerElement) {
      containerElement.style.removeProperty('height');
    }
  };

  const setContainerHeight = (height: number) => {
    const containerElement = getContainerElement();
    if (containerElement) {
      containerElement.style.height = `${height}px`;
    }
  };

  const disableEditButton = () => initPaymentPageCallActive ||
    putDeliveryAddressApiCallActive ||
    proceedingToPayment ||
    getBatchesDeliveryMethodsApiCallActive ||
    paymentProcessing ||
    postAgeVerificationApiCallsActive;

  let totalQuantity = 0;
  let totalRenderedItems = 0;

  // eslint-disable-next-line @typescript-eslint/default-param-last
  const orderItems = items.map((item = {}, index) => {
    const isItemFabric = item.uom !== '';
    totalRenderedItems += 1;

    if (!isItemFabric) {
      totalQuantity += item.quantity || 0;
    } else {
      totalQuantity += 1;
    }

    const reducedQuantity = showReducedQuantityDetails ? Number(item?.availability?.stockLevel) : undefined;

    const additionalServicesText = isOrderConfirmation ?
      <strong>Additional services</strong>
      : <p>Additional services</p>;

    const itemContainerClassnames = cx(styles.itemContainer, classNames.item);
    const isLast = index === (items?.length ?? 0) - 1;

    return (
      <Container
        key={item.id}
        borderBottom={isLast && isOrderConfirmation ? '' : 'grey-15'}
        className={itemContainerClassnames}
        data-index={`order-summary-item-${totalQuantity}`}
        marginX={modalView ? '0' : '3'}
        paddingY="2"
        testId="order-summary-item"
      >
        <OrderItem
          displayImage={!!item.imageUrl}
          hideOutOfStockText={hideOutOfStockText}
          hideQuantities={hideQuantities}
          hideSubtotals={hideSubtotals}
          isOrderConfirmation={isOrderConfirmation}
          modal={modalView}
          reducedQuantity={reducedQuantity}
          removeItem={handleShowEditBasketModalClick}
          sidebar={sidebarView}
          {...item}
        />
        {!hideChildItems && !!item.childItems?.length &&
          // eslint-disable-next-line @typescript-eslint/default-param-last
          item.childItems.map((childItem = {}, indexChild) => {
            const selectedParentQuantity = get(form, `${getFormId(item.id)}.values.itemQuantity`, reducedQuantity);
            let childItemReducedQuantity;

            if (selectedParentQuantity && childItem.quantity) {
              childItemReducedQuantity = selectedParentQuantity < childItem.quantity ?
                selectedParentQuantity :
                undefined;
            }

            totalQuantity += childItem.quantity || 0;

            const isLastChild = indexChild === (item.childItems?.length ?? 0) - 1;

            return (
              <Container
                key={childItem.id}
                marginBottom={isLastChild ? '0' : '2'}
              >
                {(sidebarView || isOrderConfirmation) &&
                  indexChild === 0 &&
                  additionalServicesText}
                <OrderItem
                  child
                  displayImage={false}
                  hideQuantities={hideQuantities}
                  hideSubtotals={hideSubtotals}
                  isOrderConfirmation={isOrderConfirmation}
                  modal={modalView}
                  preventEditing={preventEditing}
                  reducedQuantity={childItemReducedQuantity}
                  sidebar={sidebarView}
                  {...childItem}
                />
              </Container>
            );
          })}
      </Container>
    );
  });

  const enableScrollableContainer = sidebarView && totalRenderedItems >= itemsNeededToEnableExpander;

  const itemsContainerClassnames = cx({
    [styles.scrollBar]: enableScrollableContainer,
  });

  totalQuantity += tradeInQuotes.length;

  const headingId = 'order-summary-heading';

  const editBasketProps = {
    'aria-describedby': headingId,
    'data-testid': 'edit-basket-button',
    disabled: disableEditButton(),
    onClick: handleShowEditBasketModalClick,
    size: 'small',
  };

  const containerClassnames = cx(
    styles.container,
    classNames.container,
    {
      [styles.containerCollapsed]: collapsed,
    },
  );

  return (
    <section
      ref={elRef}
      className={containerClassnames}
      data-testid="order-summary-container"
    >
      {displayHeaders && (
        <OrderSummaryTitle
          button={!hideEditBasket && (
            <TertiaryButton
              {...editBasketProps}
              IconComponent={ChevronRight24PxOutlinedIcon}
              className={styles.editBasketButton}
              small
              submitting={isLoadingLazyComponent}
            >
              {collapsed ? 'View' : 'Edit'}
            </TertiaryButton>
          )}
          id={headingId}
          isLoading={isLoading}
          label="Basket"
          tag="header"
          totalQuantity={totalQuantity}
        />
      )}

      {!collapsed && (
        <>
          {!isMultiBatch && (
            <TradeInDetails
              showTradeInBoxInOrderConfirmation={showTradeInBoxInOrderConfirmation}
              tradeInItems={tradeInQuotes}
            />
          )}
          <Container relative>
            <Container
              className={itemsContainerClassnames}
              relative
              testId="order-summary-item-container"
              width="full"
            >
              <LoadingWrapper
                componentType="orderItem"
                isLoading={isLoading}
                totalItems={items?.length}
              >
                <ExpandableContent
                  classNames={{ button: 'mx-3' }}
                  containerCanBeContracted={!enableScrollableContainer && !modalView}
                  onExpanded={() => dispatch(triggerExpandBasketButtonClickedAnalytics())}
                >
                  {orderItems}
                </ExpandableContent>
              </LoadingWrapper>
            </Container>
            {enableScrollableContainer && !modalView && (
              <div className={styles.fade} data-testid="scrollbar-fade" />
            )}
          </Container>
        </>
      )}
    </section>
  );
};

export default OrderSummary;
