// Types
import { RootState } from 'types/RootState.types';

import {
  getSessionExpiredSelector,
  getSubmitOrderTimeoutSelector,
  getShowEmptyBasketNotificationSelector,
  getPageNotFoundSelector,
  getOrderCompleteSelector,
} from '../../redux/reducers/app/appSelector';
import { getErrorSelector } from '../../redux/reducers/error/errorSelector';
import { getRouterLocationPathname } from '../../redux/reducers/router/routerSelector';
//
import routeConstants from '../../constants/routeConstants';

export const shouldCenterVertical = (state: RootState) => {
  const pathname = getRouterLocationPathname(state);
  const isPathExcluded = ![
    routeConstants.ORDER_CONFIRMATION,
    routeConstants.CALLBACK_ORDER_CLAIM,
    routeConstants.CALLBACK_CREATE_ACCOUNT,
    routeConstants.JOIN_MY_JL,
  ].some(routeConstant => pathname.includes(routeConstant));

  const orderComplete = getOrderCompleteSelector(state);
  const showOrderCompleteNotification = orderComplete && isPathExcluded;

  const sessionExpired = getSessionExpiredSelector(state);
  const globalError = getErrorSelector(state);
  const submitOrderTimeout = getSubmitOrderTimeoutSelector(state);
  const showEmptyBasketNotification = getShowEmptyBasketNotificationSelector(state);
  const pageNotFound = getPageNotFoundSelector(state);

  return (
    !!globalError
      || showEmptyBasketNotification
      || showOrderCompleteNotification
      || submitOrderTimeout
      || sessionExpired
      || pageNotFound
  );
};
