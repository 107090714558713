// lodash
import get from 'lodash/get';
import formatDayPickerDays from 'jl-design-system/utils/dayPicker/dayPicker';
import { getDay } from 'date-fns';

export function buildAvailabilityByDayString(deliveryMethod, daysToShowPerPage) {

  if (deliveryMethod.dates) {

    const formattedDates = formatDayPickerDays(deliveryMethod.dates, daysToShowPerPage, true);

    const range = [...get(formattedDates, '[0]', []), ...get(formattedDates, '[1]', [])];

    const weekDays = ['mon:', 'tue:', 'wed:', 'thu:', 'fri:', 'sat:', 'sun:'];

    // Determine if the date range starts on an available or unavailable date and
    // align date information with weekDays accordingly
    const firstActiveDayIndex = range.findIndex(item => item.active);
    const firstActiveDayOfWeek = getDay(range[firstActiveDayIndex]?.date);
    let targetWeekDay = firstActiveDayIndex > 0 ? 0 : firstActiveDayOfWeek - 1;

    range.forEach((date) => {
      const endOfWeekReached = targetWeekDay >= 7;
      if (endOfWeekReached) {
        targetWeekDay -= 7;
      }

      const code = date.active ? 'a' : 'u';
      weekDays[targetWeekDay] = `${weekDays[targetWeekDay]}${code}`;
      targetWeekDay += 1;
    });

    return weekDays.join('_');
  }

  return undefined;
}

export function getCalendarAnalytics({
  deliveryMethods = [],
  selectedDeliveryMethodId,
  calendarPageIndexObject,
  daysToShowPerPage,
}) {
  const mappedAnalytics = deliveryMethods.map((deliveryMethod) => {

    const calendarPageIndex = calendarPageIndexObject[deliveryMethod.id];

    if (!deliveryMethod.dates) {
      return {
        message: deliveryMethod.description,
        selected: selectedDeliveryMethodId === deliveryMethod.id ? 1 : 0,
      };
    }

    const analytics = {
      dateRange: (calendarPageIndex + 1).toString(),
      availabilityByDay: buildAvailabilityByDayString(deliveryMethod, daysToShowPerPage),
      selected: selectedDeliveryMethodId === deliveryMethod.id ? 1 : 0,
      message: deliveryMethod.description,
    };

    return analytics;
  });

  return mappedAnalytics;
}
