import get from 'lodash/get';
import { replace } from 'connected-react-router';
import { SET_PARTNER_DISCOUNT, SET_PARTNER_DISCOUNT_PROCESSING, REBATCH_ORDER } from '../../../constants/actionConstants';
import { URL_PARTNER_DISCOUNT } from '../../../constants/endpointConstants';
import routeConstants from '../../../constants/routeConstants';
import { findCollectionPoint, selectCollectionPoint } from '../click-and-collect/clickAndCollectActions';
import { getDeliveryPage, refreshDeliveryMethods } from '../delivery/deliveryActions';
import { rebatchOrder, getItems } from '../edit-basket/editBasketActions';
import { applyRewards } from '../payment/rewardsActions';

export const togglePartnerDiscount = (
  enable,
  shouldFetchPage = true,
  shouldRebatch = true,
) => async (dispatch, getState) => {
  const callPartnerDiscountMethod = enable ? 'POST' : 'DELETE';

  const response = await dispatch({
    type: SET_PARTNER_DISCOUNT,
    request: client => client({ path: URL_PARTNER_DISCOUNT, config: { method: callPartnerDiscountMethod } }),
    partnerDiscountEnabled: enable,
    savedPaymentCards: get(getState().user, 'savedPaymentCards', []),
  });

  if (response.type === `${SET_PARTNER_DISCOUNT}.SUCCESS` && shouldFetchPage) {
    const pathname = get(getState(), 'router.location.pathname', '');
    const address = get(getState(), 'delivery.confirmedDeliveryAddress');

    let rebatchorderResponse;

    if (shouldRebatch) {
      await dispatch(getItems());
      rebatchorderResponse = await dispatch(rebatchOrder(address));
    }

    const successfullyRebatched = shouldRebatch && rebatchorderResponse.type === `${REBATCH_ORDER}.SUCCESS`;
    if (!shouldRebatch || successfullyRebatched) {
      if (pathname.includes(routeConstants.DELIVERY)) {
        await dispatch(getDeliveryPage({
          partnerDiscountEnabled: enable,
        }));

        if (successfullyRebatched) dispatch(applyRewards());

        if (address) {
          await dispatch(refreshDeliveryMethods());
        }
      }

      const {
        collectionPointSearchTerm,
        selectedCollectionPoint,
        collectionPointSearchComplete,
      } = getState().clickAndCollect || {};

      if (pathname.includes(routeConstants.CLICK_AND_COLLECT)) {

        await dispatch(getDeliveryPage({
          partnerDiscountEnabled: enable,
        }));

        if (successfullyRebatched) dispatch(applyRewards());

        if (collectionPointSearchTerm && collectionPointSearchComplete) {
          await dispatch(findCollectionPoint(collectionPointSearchTerm, true));
        }
      }

      if (selectedCollectionPoint) {
        await dispatch(selectCollectionPoint({
          collectionPoint: selectedCollectionPoint,
          refresh: true,
        }));
      }
    }
  }

  dispatch({
    type: `${SET_PARTNER_DISCOUNT_PROCESSING}.FALSE`,
  });

};

export const handleInvalidPartnerDiscountCardNumber = () => async (dispatch, getState) => {
  await dispatch(togglePartnerDiscount(false));
  const state = getState();
  if (state.orderForm.isClickCollectOrder) {
    dispatch(replace(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED));
  } else {
    dispatch(replace(routeConstants.DELIVERY));
  }
};
