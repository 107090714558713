import React from 'react';
import { useSelector } from 'react-redux';

// Config
import { getSessionExpiringAnnouncedSelector } from '../../redux/reducers/app/appSelector';

// Components
import SessionTimeoutModal from '../session-timeout-modal';
import SessionTimeoutPageTitle from '../session-timeout-page-title';

const SessionTimeoutWarning = () => {
  const sessionExpiringAnnounced = useSelector(getSessionExpiringAnnouncedSelector);

  return sessionExpiringAnnounced && (
    <>
      <SessionTimeoutModal />
      <SessionTimeoutPageTitle />
    </>
  );
};

export default SessionTimeoutWarning;
