import React from 'react';

// Components
import withSuspense, { lazyRetry } from '../../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "yotiModalConfirmation" */ './YotiModalConfirmation')));

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'YotiModalConfirmation');

export default LazyComponentWithSuspense;
