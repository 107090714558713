import { push, replace } from 'connected-react-router';
import { scrollTo } from 'jl-design-system/utils/scroll/scrollTo';

import { getAddressFromFormValues } from '../../../utils/address/addressHelpers';
import {
  ANALYTICS_JOIN_MY_JOHN_LEWIS,
  ANALYTICS_JOIN_MY_JOHN_LEWIS_CANCELLED,
  JOIN_MY_JOHN_LEWIS,
  SET_MYJL_MODAL_OPEN,
  SET_MYJL_MODAL_CONTENT_INDEX,
  USE_FULL_SCREEN_SIGNUP_FLOW,
} from '../../../constants/actionConstants';
import {
  URL_JOIN_MY_JOHN_LEWIS,
} from '../../../constants/endpointConstants';
import routeConstants from '../../../constants/routeConstants';
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import shouldShowFullScreenSignUp from '../../../utils/myjl/fullScreenSignUp';

export const joinMyJohnLewis = formValues => async (dispatch) => {
  const response = await dispatch({
    type: JOIN_MY_JOHN_LEWIS,
    request: client => client({
      path: URL_JOIN_MY_JOHN_LEWIS,
      config: {
        method: 'POST',
        body: getAddressFromFormValues(formValues),
      },
    }),
  });

  if (response.type === `${JOIN_MY_JOHN_LEWIS}.SUCCESS`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_JOIN_MY_JOHN_LEWIS, { status: response.result.status }));
  }

  dispatch(replace(routeConstants.ORDER_CONFIRMATION));

  return response;
};

export const setMyJLModalOpen = open => ({
  type: SET_MYJL_MODAL_OPEN,
  open,
});

export const setMyJLModalContentIndex = index => ({
  type: SET_MYJL_MODAL_CONTENT_INDEX,
  index,
});

export const enterNewDetails = () => async (dispatch, getState) => {
  const route = shouldShowFullScreenSignUp(getState()) ?
    routeConstants.JOIN_MY_JL_NEW :
    routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL_NEW;

  dispatch(setMyJLModalContentIndex(2));
  dispatch(push(route));
};

export const toggleFullScreenSignUp = enabled => async (dispatch) => {
  dispatch({
    type: USE_FULL_SCREEN_SIGNUP_FLOW,
    enabled,
  });

  if (!enabled) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_JOIN_MY_JOHN_LEWIS_CANCELLED));
    scrollTo(0);
    dispatch(replace(routeConstants.ORDER_CONFIRMATION));
  }
};
