// Config
import appConstants from '../../constants/appConstants';
import { isAndroidApp } from '../../redux/reducers/app/appReducer';

export function androidAppSaveSessionStorage(state = {}): void {
  if (!isAndroidApp(state)) return;

  if (typeof window?.CheckoutHandler?.persistSessionStorage !== 'function') return;

  if (!window.sessionStorage) return;

  setTimeout(() => {
    const key = `persist:${appConstants.ROOT}`;
    const pageRoot = window.sessionStorage.getItem(key);
    if (pageRoot) window.CheckoutHandler.persistSessionStorage(key, pageRoot);
  }, 500);
}
