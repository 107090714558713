// lodash
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';

import { actionsConfig } from '../index';


const evalCondition = (action, actionConfig) => {
  const condition = get(actionConfig, 'condition');

  if (condition) {
    const { prop, value } = condition;
    const actionPropValue = get(action, prop);

    if (isPlainObject(value)) {
      const newActionConfig = value[actionPropValue] || value.default;
      return evalCondition(action, newActionConfig);
    }

    return (actionPropValue === value) ? evalCondition(action, omit(actionConfig, 'condition')) : undefined;
  }

  return actionConfig;
};


export default function getConfig(action, config = actionsConfig) {
  // console.warn('analyticsConfig:', action);

  let actionConfig;

  if (isPlainObject(action)) {
    const { type } = action;
    actionConfig = config[type];

    if (actionConfig) {

      if (isArray(actionConfig.conditions)) {
        actionConfig = actionConfig.conditions.reduce((acc, config) => {
          const actionConfig = evalCondition(action, config);
          return actionConfig || acc;
        }, undefined);
      } else if (actionConfig.condition) {
        actionConfig = evalCondition(action, actionConfig);
      }
    }
  }

  return actionConfig;
}
