import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';

import addressSearchReducer from 'jl-design-system/redux/reducers/addressSearch/addressSearchReducer';
import appReducer from './app/appReducer';
import configReducer from './config/configReducer';
import bffReducer from './bff/bffReducer';
//
import loginReducer from './login/loginReducer';
import userReducer from './user/userReducer';
//
import deliveryReducer from './delivery/deliveryReducer';
//
import paymentReducer from './payment/paymentReducer';
import orderFormReducer from './payment/orderFormReducer';
import cardTypeReducer from './payment/cardTypeReducer';
import giftCardReducer from './payment/giftCardReducer';
import giftVoucherReducer from './payment/giftVoucherReducer';
//
import orderConfirmationReducer from './confirmation/orderConfirmationReducer';
//
import analyticsReducer from './analytics/analyticsReducer';
import errorReducer from './error/errorReducer';
//
import diagnosticsReducer from './diagnostics/diagnosticsReducer';
import createAccountReducer from './create-account/createAccountReducer';
import clickAndCollectReducer from './click-and-collect/clickAndCollectReducer';
//
import residentialAddressReducer from './address/residentialAddressReducer';
import deliveryAddressReducer from './address/deliveryAddressReducer';
import billingAddressReducer from './address/billingAddressReducer';
import myjlAddressReducer from './address/myjlAddressReducer';
import addressBookAddressReducer from './address/addressBookAddressReducer';
import addressBookReducer from './addressBook/addressBookReducer';
import mobileAppsReducer from './mobileApps/mobileAppsReducer';
//
import auth0CallbackReducer from './auth0-callback/auth0CallbackReducer';


const rootReducer = history => combineReducers({
  diagnostics: diagnosticsReducer,
  config: configReducer,
  app: appReducer,
  router: connectRouter(history),
  form: reduxFormReducer,
  confirmation: orderConfirmationReducer,
  bff: bffReducer,
  login: loginReducer,
  analytics: analyticsReducer,
  cardType: cardTypeReducer,
  delivery: deliveryReducer,
  orderForm: orderFormReducer,
  payment: paymentReducer,
  createAccount: createAccountReducer,
  error: errorReducer,
  user: userReducer,
  giftCard: giftCardReducer,
  giftVoucher: giftVoucherReducer,
  clickAndCollect: clickAndCollectReducer,
  deliveryAddress: deliveryAddressReducer,
  billingAddress: billingAddressReducer,
  myjlAddress: myjlAddressReducer,
  addressBookAddress: addressBookAddressReducer,
  addressBook: addressBookReducer,
  mobileApps: mobileAppsReducer,
  auth0Callback: auth0CallbackReducer,
  addressSearch: addressSearchReducer,
  residentialAddress: residentialAddressReducer,
});

export default rootReducer;
