import { RootState } from 'types/RootState.types';

export default function shouldSaveDefaultCollectionPoint(state: RootState) {
  const {
    user: {
      collectionPoints = [],
      isSignedIn,
    } = {},
  } = state;

  const defaultCollectionPoint = collectionPoints.find(point => point.isDefault);

  return !defaultCollectionPoint && isSignedIn;
}
