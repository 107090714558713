import {
  googlePayOnCancel,
  handleGooglePayFailure,
  handleGooglePaySuccess,
  setCanMakeGooglePayPayments,
} from '../payment/googlePayActions';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';
import googlePayAllowedAuthMethods from '../../../utils/payment/googlePayAllowedAuthMethods';

export const googlePayEligibleOnDevice = store => (canMakeGooglePayPayments) => {
  sendNewRelicCustomEvent('googlePayEligibleOnDeviceCalled', {
    canMakeGooglePayPayments,
  });

  if (canMakeGooglePayPayments) {
    const state = store.getState();
    const googlePayConfiguration = state.config.googlePayConfiguration;

    const allowedAuthMethods = googlePayAllowedAuthMethods(state, googlePayConfiguration.allowedAuthMethods);

    const baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods,
        allowedCardNetworks: googlePayConfiguration.allowedCardNetworks,
        assuranceDetailsRequired: true,
        billingAddressRequired: true,
        billingAddressParameters: {
          format: 'FULL',
          phoneNumberRequired: true,
        },
      },
    };

    const cardPaymentMethod = {
      ...baseCardPaymentMethod,
      tokenizationSpecification: googlePayConfiguration.tokenizationSpecification,
    };

    sendNewRelicCustomEvent('callingSetCanMakeGooglePayPayments', {
      canMakeGooglePayPayments,
    });

    store.dispatch(setCanMakeGooglePayPayments(baseCardPaymentMethod, cardPaymentMethod));
  }
};

export const onGooglePayPaymentSuccess = store => async (paymentData) => {
  sendNewRelicCustomEvent('onGooglePayPaymentSuccess', {
    paymentData,
  });

  store.dispatch(handleGooglePaySuccess(paymentData));
};

export const onGooglePayPaymentFailure = store => () => {
  sendNewRelicCustomEvent('onGooglePayPaymentFailure', {
  });

  store.dispatch(handleGooglePayFailure());
};

export const onGooglePayPaymentCancel = store => () => {
  sendNewRelicCustomEvent('onGooglePayPaymentCancel', {
  });

  store.dispatch(googlePayOnCancel());
};
