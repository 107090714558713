// lodash
import get from 'lodash/get';

import {
  GET_FEATURES,
  HAND_OVER,
  REDUX_HYDRATE,
  UPDATE_FEATURE,
  GET_CONFIGURATION,
  AUTH0_CALLBACK,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
} from '../../../constants/actionConstants';
import appConstants from '../../../constants/appConstants';
import unionWithDevFeatures from '../../../utils/features/feature-utils';
import { setStorageItem } from '../../../utils/storage/storage';

export function setFeatureOverrideCookie(features) {
  const value = features.map(({ id, enabled }) => `${id}:${enabled}`);
  document.cookie = `feature_override_cookie=${value.toString()}`;
}

const getFeatureById = (state, featureId) => {
  const features = get(state, 'config.features', []);
  const feature = features.find(feature => feature.id === featureId);

  return feature;
};

export const isFeatureActive = (state, featureId, defaultValue = false, variant) => {
  const feature = getFeatureById(state, featureId);

  if (feature) {
    if (variant) {
      return feature.variant && feature.variant.toLowerCase() === variant.toLowerCase() && feature.enabled === true;
    }

    return feature.enabled === true;
  }

  return defaultValue;
};

export const getFeatureVariant = (state, featureId) => {
  const feature = getFeatureById(state, featureId);

  if (feature && feature.enabled === true && feature.variant) {
    return feature.variant;
  }

  return '';
};

export const INITIAL_STATE = {
  content: {},
  countries: [],
  features: [],
  creditCardTypePatterns: [],
  applePayConfiguration: {},
  frontendKeys: {
    googleMapsApiKey: '',
    loqateApiKey: '',
  },
};
export default function configReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case `${GET_CONFIGURATION}.SUCCESS`: {
      const response = action.result;

      const countries = get(response, 'countries', []);
      const applePayConfiguration = get(response, 'applePayConfiguration', {});
      const frontendKeys = get(response, 'frontendKeys', 'googleMapsApiKey');
      const sessionWarningAfterTime = get(response, 'sessionWarningAfterTime');
      const clickAndCollectCutOffWindowInMinutes = get(response, 'clickAndCollectCutOffWindowInMinutes');
      const creditCardTypes = get(response, 'creditCardTypes', []);
      const googlePayConfiguration = get(response, 'googlePayConfiguration', {});

      setStorageItem({
        key: appConstants.COUNTRIES,
        value: JSON.stringify(countries),
        local: false,
        checkForPermission: false,
      });

      return {
        ...state,
        countries: [...countries],
        creditCardTypes,
        applePayConfiguration,
        frontendKeys: { ...frontendKeys },
        sessionWarningAfterTime,
        clickAndCollectCutOffWindowInMinutes,
        googlePayConfiguration,
      };
    }

    case REDUX_HYDRATE: {
      return {
        ...state,
        ...action?.payload?.config,
      };
    }

    case UPDATE_FEATURE: {
      const features = state.features.map((feature) => {
        if (action.id === feature.id) {
          return { ...feature, enabled: action.enabled };
        }
        return feature;
      });

      setFeatureOverrideCookie(features);

      return {
        ...state,
        features,
      };
    }

    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${AUTH0_CALLBACK}.SUCCESS`:
    case `${GET_FEATURES}.SUCCESS`: {
      const bffFeatures = get(action.result, 'features', []);
      const features = unionWithDevFeatures(bffFeatures);

      const updatedState = {
        ...state,
        features,
      };

      return updatedState;
    }

    case `${HAND_OVER}.SUCCESS`: {
      const stateConfig = get(action.result, 'auth0ClientConfiguration');
      return {
        ...state,
        auth0ClientConfiguration: stateConfig && {
          domain: stateConfig.domain,
          clientId: stateConfig.client_id,
          authorizationParams: {
            audience: stateConfig.audience,
            redirect_uri: stateConfig.redirect_uri,
            scope: stateConfig.scopes,
            initialScreen: stateConfig.initialScreen,
            auth0_password_reset: stateConfig.auth0_password_reset,
            express: stateConfig.express,
            guest_checkout_url: stateConfig.guest_checkout_url,
            passwordless_checkout: stateConfig.passwordless_checkout,
          },
        },
      };
    }

    default:
      return state;
  }
}
