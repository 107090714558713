import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
//
import { getItemsNeedingAgeVerification } from '../../actions/age-verification/ageVerificationActions';
import getUndeliverableItems from '../../../utils/delivery/getUndeliverableItems';
import setConfirmedDeliveryAddress from './setConfirmedDeliveryAddress';
import getSelectedFulfilmentOption from './getSelectedFulfilmentOption';
import {
  deliveryContainsIncompatibleServices,
  deliveryContainsUndeliverableServices,
  getServicesThatNeedRemoving,
} from './deliveryContainsUndeliverableServices';

function getDeliveriesState(state, action) {

  const orderForm = get(action, 'result.orderForm', {});
  const deliveries = get(orderForm, 'deliveries', (state.deliveries ?? []));
  const items = get(orderForm, 'items', (state.items ?? []));
  const deliveryOptions = get(orderForm, 'deliveryOptions', (state.deliveryOptions ?? {}));

  const itemsNeedingAgeVerification = getItemsNeedingAgeVerification(items);
  const undeliverableItemsInBasket = getUndeliverableItems(deliveries, items);
  const confirmedDeliveryAddress = setConfirmedDeliveryAddress(state, action);
  const selectedDeliveryChoiceId = getSelectedFulfilmentOption({
    deliveryOptions,
    selectedDeliveryChoiceId: state.selectedDeliveryChoiceId,
    action,
  });

  const commonValues = {
    itemsNeedingAgeVerification,
    confirmedDeliveryAddress,
    deliveryOptions,
    selectedDeliveryChoiceId,
    items,
  };

  const deliveryContainsIncompatibleServicesResult = deliveryContainsIncompatibleServices(orderForm);
  const deliveryContainsUndeliverableServicesResult = deliveryContainsUndeliverableServices(orderForm);

  if (
    !isEmpty(undeliverableItemsInBasket) &&
    !deliveryContainsIncompatibleServicesResult &&
    !deliveryContainsUndeliverableServicesResult
  ) {
    return {
      ...state,
      ...commonValues,
      undeliverableItemsInBasket,
    };
  }

  const servicesThatNeedRemoving = getServicesThatNeedRemoving(deliveries, items);

  return {
    ...state,
    ...commonValues,
    undeliverableItemsInBasket: [],
    deliveryContainsIncompatibleServices: deliveryContainsIncompatibleServicesResult,
    servicesThatNeedRemoving,
  };
}

export default getDeliveriesState;
