import addressReducer from 'jl-design-system/redux/reducers/address/addressHigherOrderReducer';
import { MYJL_ADDRESS } from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { getNonGlobalError } from '../../../utils/error/parseError';

export const INITIAL_STATE = {};

export default addressReducer({
  CUSTOM_STATE: INITIAL_STATE,
  ADDRESS_ID: MYJL_ADDRESS,
  ADDRESS_SEARCH_ERROR: getNonGlobalError({
    error: { code: errorCodeConstants.LOQATE_SEARCH_ERROR },
  }),
});
