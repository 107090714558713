export default {
  AUTH0: 'AUTH0_LOGIN',
  WEB_AUTH0_FALLBACK: 'WEB_AUTH0_FALLBACK',
  APPS: 'MOBILE_APP_LOGIN',
  CUSTOMER_APPS: 'CUSTOMER_APPS',
  CUSTOMER_APPS_FALLBACK: 'CUSTOMER_APPS_FALLBACK',
  RESET_PASSWORD: 'RESET_PASSWORD',
  WEB_AUTH0: 'WEB_AUTH0',
  WEB_LEGACY: 'WEB_LEGACY',
};
