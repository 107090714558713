export async function fetchFragment(src: string) {
  try {
    return await fetch(src).then(status => status).then(response => response.text());
  } catch (error) {
    return null;
  }
}

export const generateWrapperId = ({ id, src }: {
  id: string;
  src: string;
}) => {
  // Use the src prop to generate an id attribute for the wrapping element
  const regex = /\/?([\w-/]*)/;
  let wrapperId = '';
  if (src) {
    const source = src.match(regex);
    wrapperId = `${source?.[1]}-${id}`
      .replace(/\//g, '-') // change / to -
      .replace(/-+/g, '-') // remove duplicate -
      .toLowerCase();
  }

  return wrapperId;
};

export const selectInjectedHtml = (wrapperId: string, esiId: string) => {
  if (wrapperId) {
    const wrapperEl = document.getElementById(wrapperId);
    if (wrapperEl) {
      const esiEl = wrapperEl.querySelector(`#${esiId}`);
      return esiEl ? null : wrapperEl.innerHTML;
    }
  }
  return null;
};

export const separateScriptTags = (htmlString = '') => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = htmlString;
  const unparsedScripts = Array.from(wrapper.getElementsByTagName('script'));
  const javaScripts = unparsedScripts.filter((script) => {
    // Filter out scripts that are not executed by browsers, i.e.
    // any scripts without the type "application/javascript", "text/javascript", or no type specified
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#JavaScript_types
    switch (script.getAttribute('type')) {
      case 'application/javascript':
      case 'text/javascript':
      case 'module':
      case '':
      case null:
        return true;
      default:
        return false;
    }
  });

  javaScripts.forEach(script => script?.parentNode?.removeChild(script));

  const newScripts = javaScripts.map((script) => {
    const newScript = document.createElement('script');
    if (script.hasAttributes()) {
      Array.from(script.attributes).forEach((_, i) => {
        const { name, value } = script.attributes[i];
        newScript.setAttribute(name, value);
      });
    }
    newScript.innerHTML = script.innerHTML;
    return newScript;
  });

  return {
    scripts: newScripts,
    htmlWithoutScripts: wrapper.innerHTML,
  };
};
