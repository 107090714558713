import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';
import { ProductProps } from 'types/Product.types';

// Config
import { shouldShowAgeVerificationCheck } from '../../redux/actions/age-verification/ageVerificationActions';
import { getItemsNeedingAgeVerificationSelector } from '../../redux/reducers/delivery/deliverySelector';
import {
  getAgeRestrictedProductsInBasketSelector,
  getIsSignedInSelector,
  getShowAgeCheckModalSelector,
  getShowYotiConfirmationModalSelector,
  getYotiCheckSuccessSelector,
  getYotiSessionIdSelector,
} from '../../redux/reducers/user/userSelector';
import { orderFormContainsBladedItemsOnlySelector, getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';
import getItemQuantityById from '../../utils/orderform/getQuantityById';

const getAgeVerificationFeatureActiveSelector = (state: RootState) => shouldShowAgeVerificationCheck(state);

export const ageVerificationProductsSelector = createSelector(
  [
    getYotiCheckSuccessSelector,
    getOrderFormItemsSelector,
    getItemsNeedingAgeVerificationSelector,
    getAgeRestrictedProductsInBasketSelector,
  ],
  (
    yotiCheckSuccess,
    orderFormItems = [],
    itemsNeedingVerification = [],
    restrictedProducts = [],
  ) => {
    if (yotiCheckSuccess || itemsNeedingVerification?.length === 0) return [];

    return restrictedProducts?.reduce((acc: ProductProps[], product: ProductProps) => {
      if (product?.bladedArticle) {
        acc.push({
          ...product,
          quantity: getItemQuantityById(product.id, orderFormItems),
        });
      }
      return acc;
    }, []);
  },
);

const ageCheckerState = createSelector(
  [
    ageVerificationProductsSelector,
    orderFormContainsBladedItemsOnlySelector,
    getIsSignedInSelector,
    getShowAgeCheckModalSelector,
    getYotiSessionIdSelector,
    getShowYotiConfirmationModalSelector,
    getAgeVerificationFeatureActiveSelector,
    getYotiCheckSuccessSelector,
    getItemsNeedingAgeVerificationSelector,
  ],
  (
    ageVerificationProducts,
    bladedItemsOnly,
    isSignedIn,
    showAgeCheckModal,
    yotiSessionId,
    showYotiConfirmationModal,
    featureYotiActive,
    yotiCheckSuccess,
    itemsNeedingVerification = [],
  ) => ({
    ageVerificationProducts,
    bladedItemsOnly,
    featureYotiActive,
    isSignedIn,
    itemsNeedingVerification,
    showAgeCheckModal,
    showYotiConfirmationModal,
    yotiCheckSuccess,
    yotiSessionId,
  }),
);

export default ageCheckerState;
