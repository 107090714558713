// jl-design-system
import { destroyForms } from 'jl-design-system/redux/actions/form/formActions';
import { resetAddressSearch } from 'jl-design-system/redux/actions/address/addressActions';

import { clearStorage } from '../../../utils/storage/storage';
import {
  DELIVERY_ADDRESS,
  BILLING_ADDRESS,
  MYJL_ADDRESS,
  ADDRESS_BOOK_ADDRESS,
  RESIDENTIAL_ADDRESS,
} from '../../../constants/actionConstants';
import { AGE_CHECK_FORM_ID } from '../../../utils/form/configs/ageCheck';
import { GUEST_FORM_ID } from '../../../utils/form/configs/login';
import appConstants from '../../../constants/appConstants';

export const resetApp = type => async (dispatch, getState) => {

  clearStorage({
    preservedValues: [{
      key: appConstants.COUNTRIES,
      local: false,
    }],
  });

  // destroy addressWithSearchForm reducers
  dispatch(resetAddressSearch({ addressId: DELIVERY_ADDRESS }));
  dispatch(resetAddressSearch({ addressId: BILLING_ADDRESS }));
  dispatch(resetAddressSearch({ addressId: MYJL_ADDRESS }));
  dispatch(resetAddressSearch({ addressId: ADDRESS_BOOK_ADDRESS }));
  dispatch(resetAddressSearch({ addressId: RESIDENTIAL_ADDRESS }));

  const excludeKeys = [
    GUEST_FORM_ID,
    AGE_CHECK_FORM_ID,
  ];

  // destroy all forms
  const allFormIds = Object.keys(getState().form || {}).reduce((acc, key) => (
    !excludeKeys.includes(key) ? [...acc, key] : acc
  ), []);
  dispatch(destroyForms(allFormIds));

  // let other reducers know so the can do bespoke resets
  dispatch({ type });

};
