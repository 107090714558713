// Types
import { AppDispatch, RootState } from 'types/RootState.types';

// Config
import { RAVELIN_INSTANCE_LOADED } from '../../constants/actionConstants';
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export const initRavelin = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const featureActive = isFeatureActive(getState(), featureConstants.ENABLE_RAVELINJS);
  const key = window.env?.RAVELIN_KEY;

  if (featureActive && key) {
    const Ravelin = (await import('ravelinjs/core+track+encrypt+promise')).default;
    const payload = new Ravelin({ key });

    dispatch({
      type: RAVELIN_INSTANCE_LOADED,
      payload,
    });
  }
};
