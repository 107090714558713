import { setFormSubmitFailed, destroyForm } from 'jl-design-system/redux/actions/form/formActions';
import getErrorMessageObject from '../../../utils/error/getErrorMessageObject';
import {
  APPLY_PROMO_CODE,
  REMOVE_PROMO_CODE,
  SET_PROMO_CODE_ERROR,
} from '../../../constants/actionConstants';
import { URL_PROMOTIONS, URL_PROMOTIONS_V2, URL_REMOVE_PROMOTION } from '../../../constants/endpointConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import handleInconsistentOrderFormState from '../../../utils/orderform/handleInconsistentOrderFormState';
import determineSelectedPaymentType from './selectedPaymentTypeActions';
import { PROMO_CODE_FORM_ID } from '../../../utils/form/configs/promoCode';
import { getPayments } from './paymentActions';

export const postPromoCodeV2 = body => ({
  type: APPLY_PROMO_CODE,
  request: client => client({ path: URL_PROMOTIONS_V2, config: { body, method: 'POST' } }),
  ...body,
});

export const postPromoCode = (body, isUndo = false) => ({
  type: APPLY_PROMO_CODE,
  request: client => client({ path: URL_PROMOTIONS, config: { body, method: 'POST' } }),
  isUndo,
  ...body,
});

export const setPromoCodeError = (message, body, orderSubmissionFailure) => ({
  type: SET_PROMO_CODE_ERROR,
  message,
  body,
  ...orderSubmissionFailure && { orderSubmissionFailure },
});

export const resetPromoCodeForm = () => async (dispatch) => {
  await dispatch(determineSelectedPaymentType());
  await dispatch(destroyForm(PROMO_CODE_FORM_ID));
};

export const silentlyApplyPromoCode = promoCode => async (dispatch) => {
  await dispatch(postPromoCodeV2({ code: promoCode, silent: true }));
};

export const applyPromoCode = ({ promoCode, isUndo = false, isOnClose = false }) => async (dispatch, getState) => {

  if (!promoCode) {
    dispatch(setPromoCodeError('Please enter a valid promo code'));
    return dispatch((setFormSubmitFailed(PROMO_CODE_FORM_ID)));
  }

  const body = {
    code: promoCode,
  };

  const result = await dispatch(postPromoCode(body, isUndo));

  if (result.type === `${APPLY_PROMO_CODE}.SUCCESS`) {
    await dispatch(resetPromoCodeForm());
  }

  if (result.type === `${APPLY_PROMO_CODE}.FAILED`) {
    if (isOnClose) {
      await dispatch(resetPromoCodeForm());
    }
    const code = result?.error?.code;
    if (
      code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE ||
      code === errorCodeConstants.INCOMPLETE_DELIVERY_DETAILS
    ) {
      await dispatch(handleInconsistentOrderFormState());
      return null;
    }
  }

  if (result.error) {
    // Handle a scenario where order capture has removed the reward before applying a promo code,
    // but the promo code itself fails. We refresh payments to make sure the UI is up to date
    // with whatever's happened

    const rewardApplied = !!getState().orderForm?.amounts?.rewardsDiscountTotal;
    if (rewardApplied) dispatch(getPayments());

    const { message, body } = getErrorMessageObject({ error: result.error });
    dispatch(setPromoCodeError(message, body));
    return dispatch((setFormSubmitFailed(PROMO_CODE_FORM_ID)));
  }

  return null;
};

export const removePromoCode = item => async (dispatch) => {
  const response = await dispatch({
    type: REMOVE_PROMO_CODE,
    request: client => client({ path: URL_REMOVE_PROMOTION(item.id), config: { method: 'DELETE' } }),
  });

  if (response.type === `${REMOVE_PROMO_CODE}.FAILED`) {

    const code = response?.error?.code;
    if (
      code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE ||
      code === errorCodeConstants.INCOMPLETE_DELIVERY_DETAILS
    ) {
      await dispatch(handleInconsistentOrderFormState());
    }
  } else {
    dispatch(determineSelectedPaymentType());
  }
};
