export default {
  BILLING_DETAILS_HEADING: 'billing-details-heading',
  CLICK_COLLECT_SEARCH_RESULTS_TOP: 'click-collect-search-results-top',
  CLICK_COLLECT_TOP: 'click-collect-top',
  DELIVERY_CHOICE_SELECT_CLICK_AND_COLLECT: 'delivery-choice-select-click-and-collect',
  DELIVERY_CHOICE_SELECT_DELIVERY: 'delivery-choice-select-delivery',
  DELIVERY_DETAILS_TOP: 'delivery-details-top',
  MY_JOHN_LEWIS: 'my-john-lewis',
  PARTNERSHIP_DISCOUNT_TOGGLE: 'partner-discount-toggle',
  POS_TERMS_CONDITIONS: 'pos-terms-conditions',
  NEW_DELIVERY_FLOW_CONTINUE_BUTTON: 'new-delivery-flow-continue-to-payment-button',
  STREAMLINED_PAYMENTS_CONTAINER: 'streamlined-payments-container',
  THREEDSECURE_MESSAGE: 'threedsecure-message',
  DELIVERY_TOP: 'delivery-top',
  PAYMENT_TOP: 'payment-top',
  DELIVERY_CHOICE: 'delivery-choice',
};
