import React from 'react';

// Design System
import Tooltip from 'jl-design-system/components/tooltip/Tooltip';

// Types
import { SecuredCardInputTooltipProps } from './SecuredCardInputTooltip.types';

// Config
import supportedCreditCardTypes from '../../../../constants/supportedCreditCardTypes';

const DEFAULT_TOOLTIP_MESSAGE = 'This is the last 3 digits on the back of your card';
const AMERICAN_EXPRESS_TOOLTIP_MESSAGE = 'This is the 4 digits on the front of the card above the long card number';

const SecuredCardInputTooltip = ({ cardType }: SecuredCardInputTooltipProps) => (
  <Tooltip
    anchor="left"
    data-testid="secured-card-input-tooltip"
    icon
    id="clickSecuredCardInputTooltip"
  >
    <p data-testid="secured-card-instruction">
      {cardType === supportedCreditCardTypes.AMERICAN_EXPRESS ?
        AMERICAN_EXPRESS_TOOLTIP_MESSAGE : DEFAULT_TOOLTIP_MESSAGE}
    </p>
  </Tooltip>
);

export default SecuredCardInputTooltip;
