// Design System
import {
  storageCanBeSet,
  cookiePrefix,
} from '@JohnLewisPartnership/pecr-util';

// Config
import deleteCookie from '../helpers/cookie/deleteCookie';
import getCookie from '../helpers/cookie/getCookie';
import setCookie from '../helpers/cookie/setCookie';
import storageManifest from '../../storageManifest.json';

type GroupName = 'Functional' | 'Advertising';
type StorageType = 'localStorage' | 'sessionStorage';
type StorageManifest = {
  [storageType: string]: {
    [key: string]: any;
  };
};

export function pecrCheck(storageType: StorageType, key: string) {
  const manifest = storageManifest as StorageManifest;

  return storageCanBeSet(manifest[storageType][key]);
}

export function getCookieGroupPermission(groupName: GroupName): 'nopref' | 'allow' | 'refuse' {
  const cookieGroupPermission = getCookie(`${cookiePrefix}${groupName}`);

  if (!cookieGroupPermission) {
    return 'nopref';
  }

  return cookieGroupPermission === '1' ? 'allow' : 'refuse';
}

export function allowCookieGroup(groupName: GroupName): void {
  setCookie({ name: `${cookiePrefix}${groupName}`, value: 1, session: true });
}

export function refuseCookieGroup(groupName: GroupName): void {
  setCookie({ name: `${cookiePrefix}${groupName}`, value: 0, session: true });
}

export function removeCookieGroupPreference(groupName: GroupName): void {
  deleteCookie({ name: `${cookiePrefix}${groupName}` });
}
