// Config
import errorCodeConstants from '../../constants/errorCodeConstants';

const getErrorMessageObject = ({ error, vars = {}, doNotReturnDefault = false }) => {
  // If your error message body requires markup, use `bodyLookup: code` and errorMessageBodyLookup.js

  const code = error?.code;
  const { isItemFabric, quantityLimit, stockLevel } = vars;

  switch (code) {
    case errorCodeConstants.CLIENT_FORM_VALIDATION_ERRORS:
      return {
        code,
        message: 'There are errors below, please correct them and try again',
      };

    case errorCodeConstants.SERVER_INTERNAL_ERROR:
    case errorCodeConstants.BFF_SERVER_INTERNAL_ERROR:
    case errorCodeConstants.CUSTOMER_SYNC_FAILED:
    case errorCodeConstants.BFF_UNKNOWN_API_ERROR:
    case errorCodeConstants.HANDOVER_TOKEN_BAD_SIGNATURE:
    case errorCodeConstants.HANDOVER_TOKEN_EXPIRED:
    case errorCodeConstants.HANDOVER_TOKEN_MALFORMED:
    case errorCodeConstants.CLIENT_UNKNOWN_TECHNICAL_ERROR:
    case errorCodeConstants.SUBMIT_ORDER_SERVER_INTERNAL_ERROR:
      return {
        code,
        message: "Sorry, we've had a technical problem",
        bodyLookup: code,
      };

    case errorCodeConstants.CARD_PAYMENT_INVALID_FORMAT:
      return {
        code,
        message: 'Please check your card number and try again',
      };

    case errorCodeConstants.SESSION_EXPIRED:
      return {
        code,
        message: 'Sorry, your session has expired',
        bodyLookup: code,
      };

    case errorCodeConstants.ORDER_FORM_EMPTY:
    case errorCodeConstants.ORDER_FORM_NOT_FOUND:
    case errorCodeConstants.CODE_UNAUTHORIZED_TOKEN:
    case errorCodeConstants.INSUFFICIENT_STOCK:
      return {
        code,
        message: 'Sorry, something went wrong',
        bodyLookup: code,
      };

    case errorCodeConstants.PAYPAL_SESSION_INTERNAL_ERROR:
    case errorCodeConstants.PAYPAL_UNAVAILABLE:
      return {
        code,
        message: "Sorry, we've been unable to connect to PayPal",
        body: 'Please consider using an alternative payment method',
      };

    case errorCodeConstants.PAYMENT_POS_CREDIT_DECLINED_ERROR:
      return {
        code,
        message: 'Sorry, your credit application was unsuccessful',
        body: 'Please try another payment method.',
      };

    case errorCodeConstants.PAYMENT_POS_CREDIT_CANCELLED_ERROR:
      return {
        code,
        message: 'We have cancelled your credit application',
        body: 'Please try another payment method.',
      };

    case errorCodeConstants.PAYMENT_POS_CREDIT_OPTIONS_CHANGED:
      return {
        code,
        message: 'Your available credit offers have changed',
        body: 'Please review your payment options before continuing.',
      };

    case errorCodeConstants.PAYMENT_POS_CREDIT_APPLICATION_INCOMPLETE:
      return {
        code,
        message: 'You haven’t successfully completed your payment plan',
        bodyLookup: code,
      };

    case errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED:
    case errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED_WITH_RETRY_PERMITTED:
    case errorCodeConstants.PAYMENT_SERVICE_PAYPAL_AUTHORISATION_FAILED:
      return {
        code,
        message: 'Sorry, your PayPal payment was unsuccessful',
        body: 'Please try again or try using another payment method',
      };

    case errorCodeConstants.PAYMENT_SERVICE_PAYPAL_AUTHORISATION_UNAVAILABLE:
    case errorCodeConstants.PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_UNAVAILABLE:
    case errorCodeConstants.PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_FAILED:
      return {
        code,
        message: 'Sorry, your PayPal payment was unsuccessful',
        body: 'Please try another payment method',
      };

    case errorCodeConstants.INVALID_ADDRESS_DETAILS:
    case errorCodeConstants.POSTCODE_OUT_OF_AREA:
      return {
        code,
        message: "Sorry, we can't provide this service/delivery to ${postcode}",
        bodyLookup: code,
      };

    case errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS:
      return {
        code,
        message: 'Sorry, something went wrong',
        body: 'Please enter your address below.',
      };

    case errorCodeConstants.CARD_PAYMENT_SOFT_DECLINED_DO_NOT_RETRY:
    case errorCodeConstants.CARD_PAYMENT_NOT_AUTHORISED:
      return {
        code,
        message: "Sorry, we've been unable to process your payment",
        bodyLookup: code,
      };

    case errorCodeConstants.CARD_PAYMENT_NO_RETRY:
      return {
        code,
        message: "Sorry, we've been unable to process your payment",
        body: 'Please use another payment method.',
      };

    case errorCodeConstants.CARD_PAYMENT_ALREADY_EXISTS:
      return {
        code,
        message: 'Sorry, we had a problem processing your order',
        bodyLookup: code,
      };

    case errorCodeConstants.GIFT_CARD_REDEEM_FAILED:
      return {
        code,
        message: 'Sorry, your gift card payment was unsuccessful',
        body: 'You can try applying it again or alternatively, proceed with your order and save your gift card for another time.',
      };

    case errorCodeConstants.GIFT_CARD_OR_VOUCHER_REPLAYED_REQUEST: {
      const { giftOptionType } = vars;

      return {
        code,
        message: `Sorry, we've had a problem adding your ${giftOptionType}`,
        body: `You can try applying it again, or proceed with your order and save your ${giftOptionType} for another time.`,
      };
    }

    case errorCodeConstants.GIFT_CARD_SERVICE_UNAVAILABLE:
    case errorCodeConstants.UNKNOWN_GIFT_CARD_SERVICE_RESPONSE_CODE:
      return {
        code,
        message: "Sorry, we've had a problem adding your gift card",
        bodyLookup: code,
      };

    case errorCodeConstants.GIFT_VOUCHER_SERVICE_UNAVAILABLE:
      return {
        code,
        message: 'Sorry, the gift voucher service is temporarily unavailable',
        body: 'You can try applying it again or alternatively, proceed with your order and save your gift voucher for another time.',
      };

    case errorCodeConstants.RESERVATION_FAILED: {
      const { isPosCreditSubmissionError } = vars;
      const body = 'Please try again. If the problem persists, please try again later.';

      if (isPosCreditSubmissionError) {
        return {
          code,
          message: 'Sorry, we had a problem processing your order. We have cancelled your payment plan with Creation Consumer Finance Ltd.',
          body,
        };
      }

      return {
        code,
        message: 'Sorry, we had a problem processing your order',
        body,
      };
    }

    case errorCodeConstants.PROMO_CODE_NOT_APPLICABLE:
      return {
        code,
        message: 'Sorry, this promo code is not applicable to the items in your basket',
      };

    case errorCodeConstants.PROMO_CODE_USE_APP:
      return {
        code,
        message: "The code you've entered is only valid on the app. Please try again using the app.",
      };

    case errorCodeConstants.PROMO_CODE_INVALID:
    case errorCodeConstants.PROMO_CODE_NOT_ACTIVE:
    case errorCodeConstants.PROMO_CODE_NOT_FOUND:
      return {
        code,
        message: 'Sorry, this promo code is invalid',
      };

    case errorCodeConstants.PROMO_CODE_INVALID_CHANNEL:
      return {
        code,
        message: 'The promo code was removed because it’s only valid on the app. Please use the app if you wish to receive the discount.',
      };

    case errorCodeConstants.PROMO_CODE_NOT_MYJL_CUSTOMER:
      return {
        code,
        message: 'Sorry, this promo code is only available to My John Lewis members',
      };

    case errorCodeConstants.PROMO_CODE_USED:
      return {
        code,
        message: 'Sorry, this promo code has already been redeemed',
      };

    case errorCodeConstants.PROMO_CODE_IN_THE_PAST:
      return {
        code,
        message: 'Sorry, this promo code has expired',
      };

    case errorCodeConstants.PROMO_CODE_IN_THE_FUTURE:
      return {
        code,
        message: "Sorry, this promotion hasn't started yet",
      };

    case errorCodeConstants.CLIENT_CARD_PAYMENT_ALREADY_EXISTS_OCP:
      return {
        code,
        message: 'Card payments temporarily disabled',
        body: 'Please pay using your credit or debit card through PayPal',
        type: 'error',
      };

    case errorCodeConstants.CLIENT_CREATE_ACCOUNT_ORDER_NOT_LINKED:
      return {
        code,
        message: 'Your order has not been linked to your account',
        bodyLookup: code,
        type: 'info',
      };

    case errorCodeConstants.CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED:
    case errorCodeConstants.CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED:
      return {
        code,
        message: 'Your order has not been linked to your account and your details have not been saved',
        bodyLookup: code,
        type: 'info',
      };

    case errorCodeConstants.CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED:
    case errorCodeConstants.CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED:
      return {
        code,
        message: 'Your personal details have not been saved',
        bodyLookup: code,
        type: 'info',
      };

    case errorCodeConstants.PROMO_CODE_ALREADY_APPLIED:
      return {
        code,
        message: 'This code has already been applied',
      };

    case errorCodeConstants.INCOMPLETE_DELIVERY_DETAILS:
      return {
        code,
        message: 'You need to submit your delivery details.',
        body: 'Please complete the details on the page before continuing to payment.',
      };

    case errorCodeConstants.LOQATE_SEARCH_ERROR:
      return {
        code,
        message: 'Sorry, there was a problem with our address finder',
        body: 'Please enter your address manually',
      };

    case errorCodeConstants.CLIENT_NO_COLLECTION_POINT_FOUND_ERROR:
      return {
        code,
        message: 'No collection points available',
        body: 'There are no collection points within a 50 mile radius of your search. Please try using a different location or choose delivery instead.',
      };

    case errorCodeConstants.CLIENT_PAYMENT_3DS_CANCELLED_ERROR:
    case errorCodeConstants.CLIENT_PAYMENT_OCP_3DS_INVALID_ERROR: {
      return {
        code,
        message: "Your bank's security check was not completed",
        bodyLookup: code,
      };
    }

    case errorCodeConstants.CLIENT_OCP_PAYMENT_ERROR:
    case errorCodeConstants.CLIENT_PREPAID_CARD_PAYMENT_ERROR:
      return {
        code,
        message: "Sorry, we've been unable to process your payment",
        body: 'Please try again or try using another payment method.',
      };

    case errorCodeConstants.SUBMIT_ORDER_INCONSISTENT_AUTHORIZED_AMOUNT:
    case errorCodeConstants.SUBMIT_ORDER_BALANCE_AMOUNT_MISMATCH:
    case errorCodeConstants.MISSING_CLIENT_DATA_CODE:
      return {
        code,
        message: "Sorry, we couldn't process your payment.",
        body: 'Please try again. If the issue persists try using a different card.',
      };

    case errorCodeConstants.CLIENT_GEO_LOCATION_NOT_SUPPORTED:
      return {
        code,
        message: 'Sorry, location services are not supported by your browser',
      };

    case errorCodeConstants.CLIENT_GEO_LOCATION_NOT_ENABLED:
      return {
        code,
        message: 'To use your current location please enable location services',
      };

    case errorCodeConstants.CLIENT_GEO_LOCATION_GET_POSITION_ERROR:
      return {
        code,
        message: "Sorry, we couldn't find your location",
        body: 'Please enter a location manually',
      };

    case errorCodeConstants.SAVE_ADDRESS_FAILED:
      return {
        code,
        message: 'Your delivery address has not been saved',
        body: 'This was due to a technical error when creating the address. You can proceed with your order as normal however for future orders you will need to enter the address again.',
      };

    case errorCodeConstants.APPLY_PARTNER_DISCOUNT_FAILED:
      return {
        code,
        message: 'Partner Discount is currently unavailable',
        body: 'You can complete your order without discount or try again later.',
      };

    case errorCodeConstants.INCOMPLETE_COLLECTION_SEARCH:
      return {
        code,
        message: 'Please enter a shop name or location',
      };

    case errorCodeConstants.DELIVERIES_INCONSISTENT:
      return {
        code,
        message: 'Sorry, something went wrong',
        bodyLookup: code,
      };

    case errorCodeConstants.PROMO_CODE_INTERNAL_SERVER_ERROR:
    case errorCodeConstants.PROMO_CODE_TECHNICAL_ERROR:
      return {
        code,
        message: 'Sorry, there was a technical problem with applying your promo code',
        body: 'Please try again. If the problem persists, please try again later.',
      };

    case errorCodeConstants.ITEM_QUANTITY_PURCHASE_LIMIT:
      return {
        code,
        message: `Sorry, we've limited this item to ${quantityLimit} per customer.`,
      };

    case errorCodeConstants.QUANTITY_REDUCED_TO_AVAILABLE_STOCK:
    case errorCodeConstants.ALL_AVAILABLE_STOCK_ALREADY_ADDED: {
      return {
        code,
        message: isItemFabric ?
          `Sorry, we only have ${stockLevel} metres available.` :
          `Sorry, we only have ${stockLevel} of these items available.`,
      };
    }

    case errorCodeConstants.ITEM_QUANTITY_INVALID: {
      return {
        code,
        message: `Please enter a number between 1 and ${stockLevel}.`,
      };
    }

    case errorCodeConstants.CLIENT_POS_CREDIT_PAYMENT_FAILED: {
      return {
        code,
        message: 'Sorry, we are unable to offer credit options at the moment',
        body: 'Please try an alternative payment method, or try again later',
      };
    }

    case errorCodeConstants.CLIENT_APPLE_PAY_BILLING_ADDRESS_MISSING: {
      return {
        code,
        message: 'Sorry, something went wrong with your address',
        body: "Please enter a valid address by selecting 'Change address' within the Apple Pay payment option.",
      };
    }

    case errorCodeConstants.AUTH0_UNAVAILABLE_ERROR:
      return {
        code,
        message: 'Sorry, something went wrong',
        body: 'Please try again.',
      };

    case errorCodeConstants.SAVE_ORDER_TECHNICAL_ERROR:
    case errorCodeConstants.AUTH0_ACCESS_TOKEN_ERROR:
    case errorCodeConstants.UNRECOGNIZED_CUSTOMER:
      return {
        code,
        message: 'Sorry, something went wrong',
        body: 'We were unable to save your order to your account due to a technical issue. Please try again.',
      };

    case errorCodeConstants.SAVE_ORDER_TECHNICAL_ERROR_NO_SESSION:
      return {
        code,
        message: 'Sorry, something went wrong',
        body: 'We were unable to save your order to your account due to a technical issue.',
      };

    case errorCodeConstants.SAVE_ORDER_INVALID_LINK:
      return {
        code,
        message: 'Sorry, something went wrong',
        body: 'Your link may be invalid, already used, or expired.',
      };

    case errorCodeConstants.CLIENT_CARD_TYPE_MISSING:
      return {
        code,
        message: 'Sorry, we do not accept this type of card',
        body: 'Please use another payment method.',
      };

    case errorCodeConstants.CLIENT_CARD_DELETE_FAILED:
      return {
        code,
        message: 'We were unable to delete your card',
        body: 'Please try again. If the problem persists, please try again later.',
      };

    case errorCodeConstants.CLEARPAY_PAYMENT_NOT_AUTHORISED:
    case errorCodeConstants.KLARNA_PAYMENT_NOT_AUTHORISED: {
      const paymentMethods = {
        [errorCodeConstants.CLEARPAY_PAYMENT_NOT_AUTHORISED]: 'Clearpay',
        [errorCodeConstants.KLARNA_PAYMENT_NOT_AUTHORISED]: 'Klarna',
      };
      const method = paymentMethods[code];

      return {
        code,
        message: `Sorry, we've been unable to process your ${method} payment`,
        body: `Please try ${method} again or select an alternative payment method.`,
      };
    }

    default: {

      if (doNotReturnDefault) {
        return undefined;
      }

      // An unknown error...
      return {
        ...code && { code },
        message: "Sorry, we've had a technical problem",
        bodyLookup: 'unknownError',
      };
    }
  }
};


export default getErrorMessageObject;
