import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
} from 'jl-design-system/form/fields';
import getAddressFieldsConfig from 'jl-design-system/form/configs/address';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export const APPLE_PAY_BILLING_ADDRESS_FORM_ID = 'applePayBillingDetailsForm';
export default function getApplePayBillingDetailsFormConfig(params) {

  const {
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
  } = params;

  const {
    isClickCollectOrder,
    submitButtonExtra,
  } = configExtras;

  const ADDRESS_FIELDS = getAddressFieldsConfig({
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
    allowAsciiOnly: true,
  });

  const PHONE_NUMBER = getInternationalPhoneNumberConfig({
    isClickCollectOrder,
    showDefaultInstruction: true,
  });

  return {
    submitButton: 'Continue with payment',
    submitButtonExtra,
    id: APPLE_PAY_BILLING_ADDRESS_FORM_ID,
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
    ],
  };
}
