// lodash
import get from 'lodash/get';
import isArray from 'lodash/isArray';
//
import getErrorMessageObject from '../../error/getErrorMessageObject';
import { validErrorTypes } from '../index';

export function getErrorMessageAndCode(errorMessage, action) {
  if (errorMessage) {
    return { message: errorMessage.toLowerCase() };
  }

  const { error, ...allOtherProps } = action;

  // get the ui error message
  const { message, code } = getErrorMessageObject({ error, vars: { ...allOtherProps } });
  return {
    message: message.toLowerCase(),
    ...code && { code },
  };
}


/**
 * Returns an array of error objects
 * @param type {string} : 'checkout:form' || 'checkout:system' || etc...
 * @param errorMessage {string} : 'a message to override getErrorMessageObject(action.error).message'
 * @param action {object} : { meta: { fields: [ 'field1', 'field2']  form: 'formId1' },
 *                            error: { message: 'Action Error Message'} }
 *                          OR
 *                          { meta: { fields: {
 *                                    formId1: [ 'field1', 'field2']
 *                                    formId2: [ 'field1', 'field2']
 *                                  },
 *                          }, error: { message: 'Action Error Message'} }
 * @param state {object} : { formValidationErrorMessages: {
 *                           formId1: { field1: 'message1', field2: 'message2' },
 *                           formId2: { field1: 'message1', field2: 'message2' },
 *                         }
 * @returns {array} : [{ type: 'checkout:system', message: 'action error message' }] OR
 *                    [{ type: 'checkout:form', field: 'field1' message: 'message1' },
 *                     { type: 'checkout:form', field: 'field2' message: 'message2' }] OR
 *                    [{ type: 'checkout:form', message: 'action error message' }]
 */
export default function getErrorArray(type, errorMessage, action = {}, state = {}, additionalProperties = {}) {

  let errorArray;

  const { message, code } = getErrorMessageAndCode(errorMessage, action);
  const fields = get(action, 'meta.fields');

  if (type.includes('checkout:form') && fields) {

    const formId = get(action, 'meta.form');

    const fieldsObject = isArray(fields) ? {
      [formId]: [...fields],
    } : fields;

    const formValidationErrorMessages = get(state, 'formValidationErrorMessages', {});

    errorArray = Object.keys(fieldsObject).reduce((acc, formId) => {

      const fields = fieldsObject[formId] || {};
      const messages = formValidationErrorMessages[formId] || {};

      return [
        ...acc,
        ...fields.reduce((acc, field) => {
          if (messages[field]) {
            return acc.concat({
              type,
              field,
              message: messages[field],
              ...code && { code },
            });
          }
          return acc;
        }, []),
      ];
    }, []);

  } else if (validErrorTypes?.includes(type)) {
    errorArray = [{
      type,
      message,
      ...code && { code },
      ...additionalProperties,
    }];
  }

  return errorArray;
}
