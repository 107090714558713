import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import { setPageNotFound } from '../../redux/actions/app/appActions';

// Components
import TechnicalError from '../../components/technical-error';

const NotFound = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageNotFound());
  }, [dispatch]);

  return (
    <TechnicalError pageNotFoundTechnicalError />
  );
};

export default NotFound;
