import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';
import sortCollectionPointsByDistance from '../../../utils/collection/sortCollectionPointsByDistance';

function getFilteredCollectionPoints(collectionPoints) {
  let filteredCollectionPoints = [];

  filteredCollectionPoints = filteredCollectionPoints.concat(collectionPoints.filter(collectionPoint => (
    !isCollectPlusRelatedType(collectionPoint.ownerId)
  )));

  let collectPlusPoints = collectionPoints.filter(collectionPoint => (
    isCollectPlusRelatedType(collectionPoint.ownerId)
  ));

  collectPlusPoints = collectPlusPoints.slice(0, 9);

  filteredCollectionPoints = filteredCollectionPoints.concat(collectPlusPoints);

  return sortCollectionPointsByDistance(filteredCollectionPoints);
}

export default getFilteredCollectionPoints;
