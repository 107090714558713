import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './views/error-boundary/ErrorBoundary';
import routes from './routes';

const Root = ({ store, persistor, history }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          {routes(persistor)}
        </ErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

Root.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

Root.defaultProps = {
  history: {},
  persistor: {},
  store: {},
};

export default Root;
