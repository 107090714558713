// Types
import { RootState } from 'types/RootState.types';

// Config
import routeConstants from '../../constants/routeConstants';

function shouldShowFullScreenSignUp(state: RootState): boolean {
  const myJlFullScreenSignUpFlowActive = state.createAccount?.myJlFullScreenSignUpFlowActive;
  const showMyJLAccountPrompt = state.user?.showMyJLAccountPrompt;
  const pathname = state?.router?.location?.pathname;
  const joinMyJLApiCallComplete = state?.createAccount?.joinMyJLApiCallComplete;

  if (
    !showMyJLAccountPrompt // don't show when customer is signed in and already has an account
    || joinMyJLApiCallComplete // don't show if any attempt to join has finished, successful or not
    || !myJlFullScreenSignUpFlowActive // don't show if the full screen flow has been abandoned
    || pathname?.includes(routeConstants.ORDER_CONFIRMATION) // don't show if we're on order confirmation page
  ) {
    return false;
  }

  return true;
}

export default shouldShowFullScreenSignUp;
