// Types
import { ErrorProps } from 'types/Error.types';

// Config
import { SUBMIT_ORDER } from '../../constants/actionConstants';
import errorCodeConstants from '../../constants/errorCodeConstants';

export default function submitOrderCanRetry(action: { error?: ErrorProps; type?: string }): boolean {
  const {
    error: { code } = {},
    type,
  } = action;

  return type === `${SUBMIT_ORDER}.FAILED` && code === errorCodeConstants.ORDERING_IN_PROGRESS;
}
