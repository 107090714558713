export default {
  VISA: 'visa',
  VISA_DEBIT: 'visadebit',
  BUSINESS_ACCOUNT: 'businessaccountcard',
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro',
  AMERICAN_EXPRESS: 'americanexpress',
  ANY_DAY: 'anydaycard',
  NEW_DAY: 'newdaycard',
};
