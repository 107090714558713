import {
  EMAIL,
} from 'jl-design-system/form/fields';

export const GUEST_FORM_ID = 'guestForm';
export const guestFormConfig = () => ({
  id: GUEST_FORM_ID,
  submitButton: 'Continue as guest',
  fields: [{
    ...EMAIL,
    instruction: 'For receipts & order information only',
  }],
});
