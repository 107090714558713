import env from 'jl-design-system/utils/env/env';
import get from 'lodash/get';
import {
  EMAIL_VALIDATION_SERVICE_INT,
  EMAIL_VALIDATION_SERVICE_PROD,
} from '../../constants/endpointConstants';
import appConstants from '../../constants/appConstants';
import fetchWithTimeout from '../helpers/fetchWithTimeout';

export default async function validateEmail(email, timeout) {
  if (!env.isClientLocal && !env.isClientDev) {
    const endpoint = get(window, 'location.host', '').includes('johnlewis') ?
      EMAIL_VALIDATION_SERVICE_PROD : EMAIL_VALIDATION_SERVICE_INT;

    try {
      const response = await fetchWithTimeout(endpoint, {
        method: 'POST',
        timeout: timeout || appConstants.BFF_TIMEOUT,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      return response.status === 204;
    } catch (error) {
      if (error.name === 'AbortError') {
        return true;
      }

      // For any other errors, we should still allow customers to proceed
      return true;
    }
  }

  return email !== 'test@test.comm';
}
