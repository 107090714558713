import deliveryConstants from '../../../constants/deliveryConstants';

function getSelectedFulfilmentOption(options) {
  const {
    deliveryOptions,
    selectedDeliveryChoiceId,
    action = {},
  } = options;

  const {
    clickCollectAvailable,
    collectPlusAvailable,
  } = deliveryOptions;

  if (!clickCollectAvailable && !collectPlusAvailable) {
    // only possible choice as collection is unavailable
    return deliveryConstants.DELIVERY;
  }

  if (!selectedDeliveryChoiceId) {
    if (action.preferredDeliveryChoice) {
      return deliveryConstants.DELIVERY;
    }

    return deliveryConstants.CLICK_AND_COLLECT;
  }

  // will return either an existing choice or the default state of undefined
  return selectedDeliveryChoiceId;
}

export default getSelectedFulfilmentOption;
