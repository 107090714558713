// lodash
import isEqual from 'lodash/isEqual';

import { getAddressFromFormValues } from './addressHelpers';

function isSameAddress({ formValues, address, addressToCheck }) {

  const newAddress = formValues ? getAddressFromFormValues(formValues) : address;

  return isEqual(newAddress, addressToCheck);
}

export default isSameAddress;
