import React from 'react';

// Types
import { YotiModalProps as LazyComponentProps } from './YotiModal.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "yotiModal" */ './YotiModal')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'YotiModal');

export default LazyComponentWithSuspense;
