// Config
import appConstants from '../../constants/appConstants';

export const getDataAttributes = ({
  maskContentSquare = false,
  maskPercy = false,
  maskText = false,
  testId = '',
}: { maskContentSquare?: boolean; maskPercy?: boolean; maskText?: boolean; testId?: string } = {}) => ({
  ...(maskText || maskContentSquare ? { [appConstants.CONTENT_SQUARE_MASK_ATTR]: '' } : {}),
  ...(maskText || maskPercy ? { [appConstants.PERCY_MASK_ATTR]: '' } : {}),
  ...(testId ? { [appConstants.TEST_ID_ATTR]: testId } : {}),
});
