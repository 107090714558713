// Design System
import {
  scrollToElement as designSystemScrollToElement,
} from 'jl-design-system/utils/scroll/scrollTo';

type AnimateConfigType = {
  align?: 'top' | 'bottom';
  duration?: number;
  offset?: number;
};

const DELAY_MSECS = 100;
const DEFAULT_SCROLL_ALIGN = 'top';
const DEFAULT_SCROLL_DURATION = 750;
const DEFAULT_SCROLL_MARGIN = 16;

// TODO: window.innerHeight does NOT take into account horizontal scrollbar height
export function getScrollConfig({ animateConfig, containerSelector, selector }: {
  animateConfig?: AnimateConfigType;
  containerSelector?: string;
  selector?: string;
}) {
  if (typeof selector === 'string') {
    const element = document.querySelector(selector);

    if (element) {

      if (typeof containerSelector === 'string') {

        const container = document.querySelector<HTMLElement>(containerSelector);

        if (container) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const {
            align = DEFAULT_SCROLL_ALIGN,
            offset: rawOffset = DEFAULT_SCROLL_MARGIN,
          } = animateConfig ?? {};
          const offset = Math.abs(rawOffset);

          if (align === 'bottom') {
            const { bottom } = elementRect;
            const isBottomOutOfView = (bottom + offset) > containerRect.bottom;

            if (isBottomOutOfView) {
              // if top of element will scroll out of view, switch to scroll element to top
              const willElementTopScrollOutOfView = (elementRect.height + offset) > containerRect.height;
              if (willElementTopScrollOutOfView) {
                return {
                  ...animateConfig,
                  align: 'top',
                  offset: DEFAULT_SCROLL_MARGIN,
                };
              }
              return animateConfig;
            }
            // console.warn('--------- ELEMENT IS ALREADY IN VIEW - NO SCROLL ---------');
            return undefined;
          }

          return animateConfig;

        }

      } else {
        const elementRect = element.getBoundingClientRect();
        const {
          align = DEFAULT_SCROLL_ALIGN,
          offset: rawOffset = DEFAULT_SCROLL_MARGIN,
        } = animateConfig ?? {};
        const offset = Math.abs(rawOffset);

        if (align === 'bottom') {
          const { bottom } = elementRect;
          const isBottomOutOfView = (bottom + offset) > window.innerHeight;

          if (isBottomOutOfView) {
            // if top of element will scroll off screen, switch to scroll element to top
            const willElementTopScrollOutOfView = (elementRect.height + offset) > window.innerHeight;
            if (willElementTopScrollOutOfView) {
              return {
                ...animateConfig,
                align: 'top',
                offset: DEFAULT_SCROLL_MARGIN,
              };
            }
            return animateConfig;
          }
          // console.warn('--------- ELEMENT IS ALREADY IN VIEW - NO SCROLL ---------');
          return undefined;
        }

        return animateConfig;
      }
    }
  }

  return undefined;
}

function scrollToElement({
  animate = {},
  containerId = undefined,
  focus = undefined,
  id,
}: {
  animate?: AnimateConfigType;
  containerId?: string;
  focus?: boolean | string;
  id: string;
}) {
  // TODO make this test an exported utility from focusManager.js in the Design System
  if (document.body.classList.contains('jl-focusmanager-show')) return {};

  const { align, duration, offset } = animate;

  const animateConfig = animate ? {
    align: align || DEFAULT_SCROLL_ALIGN,
    duration: duration || DEFAULT_SCROLL_DURATION,
    offset: Number.isFinite(offset) ? offset : DEFAULT_SCROLL_MARGIN,
  } : undefined;

  window.setTimeout(() => {
    const selector = `[data-scroll="${id}"]`;
    const containerSelector = containerId ? `[data-scroll-container="${containerId}"]` : undefined;
    const config = getScrollConfig({ animateConfig, containerSelector, selector });
    // console.warn('>>>>>> scrollToElement:', config, animate, animateConfig);

    if (config) {
      const container = containerId ? {
        containerSelector,
        containerContentSelector: `[data-scroll-content="${containerId}"]`,
      } : undefined;

      // console.warn('>>>>>> scrollToElement:', id, config, container);
      designSystemScrollToElement(selector, focus, config, container);
      return;
    }

    if (focus) {
      let element = document.querySelector<HTMLElement>(selector);
      if (typeof focus === 'string') {
        element = document.querySelector(focus);
      }
      if (element) {
        element.focus();
      }
    }

  }, DELAY_MSECS);

  return { type: 'SCROLL_TO_ELEMENT' };
}

export default scrollToElement;
