interface TextArea {
  selectionEnd?: number;
  selectionStart?: number;
  value?: string;
}

export function isAscii({ extended = false, value }: { extended?: boolean; value: any }): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  const regex = extended ? /[\x00-\xFF]/g : /[\x00-\x7F]/g;
  return regex.test(value);
}

export function replaceNonAsciiExtended(value: any): string {
  if (typeof value !== 'string') {
    return '';
  }

  const regex = /[^\x00-\xFF]/g;
  return value.replace(regex, '');
}

export function replaceLineBreaksWithSub(string: any, sub: string): string {
  if (typeof string !== 'string') {
    return string;
  }

  const regex = /[\n\r]/g;
  return string.replace(regex, sub);
}

export function getSoftWrapStringArraySimple({
  maxCharsLine,
  string = '',
  trimLineStartSpace,
}: { maxCharsLine: number; string?: string; trimLineStartSpace: boolean }): string[] {
  const arr: string[] = [];
  let startIndex = 0;
  let endIndex = 0;

  while (endIndex < string.length) {
    startIndex = endIndex;
    endIndex = startIndex + maxCharsLine;

    let line: string = string.substring(startIndex, endIndex);

    if (line.length === maxCharsLine) {
      const lastChar = string.charAt(line.length - 1);
      const nextChar = string.charAt(line.length);

      if (line.includes(' ') && lastChar !== ' ' && nextChar !== ' ') {
        const diff = line.length - line.lastIndexOf(' ') - 1;
        endIndex -= diff;
        line = string.substring(startIndex, endIndex);
      }

      if (trimLineStartSpace && nextChar === ' ') {
        endIndex += 1;
      }
    }

    arr.push(line);
  }

  return arr;
}

export function getSoftWrapStringArray({
  maxCharsLine,
  string = '',
}: { maxCharsLine: number; string?: string }): string[] {

  let line = '';
  const array: string[] = [];

  const wordsArray = string.split(' ');

  wordsArray.forEach((word, index) => {

    const remaining = maxCharsLine - line.length;

    if (word.length <= remaining) {
      if (word.length === remaining) {
        array.push(`${line}${word}`);
        line = '';
      } else {
        line = `${line}${word} `;
      }
    } else {
      if (line) {
        array.push(line);
        line = '';
      }

      if (word.length >= maxCharsLine) {
        const items = Math.ceil(word.length / maxCharsLine);
        const arr = [...Array(items)];
        arr.forEach((_val, index) => {
          const offset = index * maxCharsLine;
          const chunk = word.substring(offset, offset + maxCharsLine);
          if (chunk.length === maxCharsLine) {
            array.push(chunk);
          } else {
            line = `${line}${chunk} `;
          }
        });
      } else {
        line = `${word} `;
      }
    }

    if (index === wordsArray.length - 1) {
      line = line.trim();
      if (line) {
        array.push(line);
      }
    }
  });

  return array;
}

export function getTextAreaLinesArray({
  isTextArea = true,
  maxCharsLine,
  maxLines,
  string = '',
}: { isTextArea?: boolean; maxCharsLine?: number; maxLines?: number; string?: string } = {}): string[] {
  if (!string || !maxLines || !maxCharsLine) {
    return [];
  }

  const getSoftStringWrapArrayFunc = isTextArea ? getSoftWrapStringArray : getSoftWrapStringArraySimple;

  const raw = replaceLineBreaksWithSub(string, '*RT*');
  const lineBreakArray = raw.split('*RT*');

  const linesArray = lineBreakArray.reduce<string[]>((acc, line) => {
    if (acc.length < maxLines) {
      if (line.length <= maxCharsLine) {
        return acc.concat(line);
      }
      const chunk = getSoftStringWrapArrayFunc({ string: line, maxCharsLine, trimLineStartSpace: isTextArea });
      return acc.concat(chunk);
    }
    return acc;
  }, []);

  return linesArray;

}

export function getCaretLine({
  isTextArea,
  maxCharsLine,
  maxLines,
  prefix = '',
  textArea = {},
}: { isTextArea: boolean; maxCharsLine: number; maxLines: number; prefix?: string; textArea?: TextArea }): number {
  const { selectionStart = 0, value } = textArea;
  const caretIndex = selectionStart + prefix.length;
  let caretLine = 0;

  const parsedValue = `${prefix}${value}`;

  if (parsedValue) {
    const charsBeforeCaretArray = getTextAreaLinesArray({
      string: parsedValue.substring(0, caretIndex),
      maxLines,
      maxCharsLine,
      isTextArea,
    });
    caretLine = charsBeforeCaretArray.length - 1;
  }

  return caretLine;
}

export function getNextCharsInfo(textArea: TextArea = {}): {
  newLine: boolean;
  nextChar: string;
  prevChar: string;
} {
  const { value, selectionStart = 0 } = textArea;

  if (value) {
    const caretIndex = selectionStart;
    const prevChar = value.charAt(caretIndex - 1);
    const nextChar = value.charAt(caretIndex);

    return {
      prevChar,
      nextChar,
      newLine: !prevChar || /[\n\r]/.test(prevChar),
    };
  }

  return {
    prevChar: '',
    nextChar: '',
    newLine: true,
  };
}

export function getCharsLeft({
  isTextArea,
  linesArray = [],
  maxChars,
  maxCharsLine,
  maxLines,
  prefix = '',
  textArea = {},
}: {
  isTextArea: boolean;
  linesArray?: string[];
  maxChars: number;
  maxCharsLine: number;
  maxLines: number;
  prefix?: string;
  textArea?: TextArea;
}): number {
  const caretLine = getCaretLine({ textArea, maxLines, maxCharsLine, prefix, isTextArea });
  const charsUsed = linesArray.reduce((acc, line, index) => {
    if (index < caretLine) {
      return acc + maxCharsLine;
    }
    return acc + line.length;
  }, 0);
  return maxChars - charsUsed;
}

export function calcLinesAfterEdit({
  insert = '',
  isTextArea,
  maxCharsLine,
  prefix = '',
  textArea = {},
}: { insert?: string; isTextArea: boolean; maxCharsLine: number; prefix?: string; textArea?: TextArea }): number {
  const { value = '', selectionStart = 0, selectionEnd = 0 } = textArea;

  const selectRange = selectionEnd - selectionStart;
  const isBackspace = insert === '';
  const singleDelete = isBackspace && !selectRange && selectionStart > 0;
  const startToIndex = singleDelete ? selectionStart - 1 : selectionStart;
  const endFromIndex = selectionEnd;

  const start = value.substring(0, startToIndex);
  const end = value.substring(endFromIndex);

  const string = `${prefix}${start}${insert}${end}`;
  const lines = getTextAreaLinesArray({ string, maxLines: 10, maxCharsLine, isTextArea });

  return lines.length;
}


export function parseCharCode(charCode: 'backspace' | 'delete' | 'enter' | 'tab' | string): string {
  if (charCode === 'backspace' || charCode === 'delete' || charCode === 'tab') {
    return '';
  }
  if (charCode === 'enter') {
    return '\n';
  }
  return charCode;
}
