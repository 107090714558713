import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';
import { Modal } from 'jl-design-system/components/modal/Modal';

// Types
import { AppDispatch } from 'types/RootState.types';
import { PricePromiseProps } from './PricePromise.types';

// Config
import pricePromiseState from './PricePromise.state';
import { PRICE_PROMISE_GLOBAL_INTERACTION } from '../../constants/actionConstants';
import triggerAnalyticsEvent from '../../redux/actions/analytics/analyticsAction';
import { togglePricePromiseModal } from '../../redux/actions/app/appActions';

// Components
import BodyText from '../body-text';
import Container from '../container';

const PricePromise = ({
  isSidebarView = false,
}: PricePromiseProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    pricePromiseFeatureActive,
    showPricePromiseModal,
  } = useSelector(pricePromiseState);

  if (!pricePromiseFeatureActive) return null;

  const containerProps = isSidebarView ? {
    flex: {
      justifyContent: 'end' as const,
    },
    paddingRight: '2',
    paddingBottom: '2',
  } : {
    paddingBottom: '2',
  };

  const onClickHandler = (showModal: boolean) => {
    dispatch(togglePricePromiseModal(showModal));

    if (showModal) {
      dispatch(triggerAnalyticsEvent(
        PRICE_PROMISE_GLOBAL_INTERACTION,
        { ...(isSidebarView && { location: 'basket' }) },
      ));
    }
  };

  return (
    <Container {...containerProps}>
      <TertiaryButton
        onClick={() => onClickHandler(true)}
        small
      >
        Our price promise
      </TertiaryButton>

      {
        showPricePromiseModal &&
        <Modal
          isOpen
          onClose={() => onClickHandler(false)}
          onPrimaryButtonClick={() => onClickHandler(false)}
          primaryButtonText="Close"
          title="Our price promise"
          underlinedButtonText=""
        >
          <BodyText marginBottom="2">
            We match the prices of 25 online and high street retailers.
            But, if you do find a better price with them within 7 days of buying, we&apos;ll refund you the difference.
          </BodyText>
          <BodyText>
            It&apos;s just one of the ways we&apos;re committed to being Never Knowingly Undersold.
          </BodyText>
        </Modal>
      }
    </Container>
  );
};

export default PricePromise;
