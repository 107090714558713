import Input from 'jl-design-system/elements/input/Input';
import InputManageCursor from 'jl-design-system/elements/input-manage-cursor/InputManageCursor';
import getRegExp, {
  numbersPattern,
  uppercaseLettersNumbersPattern,
} from 'jl-design-system/form/regex';
import {
  numbersOnly,
  uppercaseLettersNumbers,
} from 'jl-design-system/form/normalizers/restrictInput';
//
import InputWithProgress from '../../../widgets/form/fields/input-with-progress';

const formValidationInvalidCharacters = 'Please remove the invalid characters to continue';
const formValidationGiftCardNumberRequired = 'Please enter the 19-digit card number on the back of your gift card';

export const formatGiftCardNumber = (value = '') => value.replace(/(.{4})/g, '$1 ').trim();

export const formatGiftVoucherSerialNumber = (value = '', previousValue = '') => {
  if (previousValue.length <= value.length || Math.abs(previousValue.length - value.length) > 1) {
    return value.replace(/(.{2})/, '$1 ').trim();
  }

  return value;
};

export const GIFT_CARD_NUMBER = {
  component: InputManageCursor,
  type: 'tel',
  autoComplete: 'off',
  id: 'giftCardNumber',
  name: 'giftCardNumber',
  label: 'Gift card number',
  a11yText: 'Enter the 19-digit card number',
  hideErrors: true,
  autoFocus: true,
  required: true,
  minLength: 19,
  maxLength: 19 + 4,
  regex: getRegExp(numbersPattern),
  validationMessageKeys: {
    required: formValidationGiftCardNumberRequired,
    minLength: formValidationGiftCardNumberRequired,
    maxLength: formValidationGiftCardNumberRequired,
    regex: formValidationGiftCardNumberRequired,
  },
  normalize: numbersOnly,
  format: formatGiftCardNumber,
};

const formValidationGiftCardPinRequired =
  'Please enter the 4-digit PIN underneath the scratch-off panel at the back of your gift card';
export const GIFT_CARD_PIN = {
  component: InputWithProgress,
  type: 'tel',
  autoComplete: 'off',
  id: 'giftCardPin',
  name: 'giftCardPin',
  label: 'Pin',
  a11yText: 'Enter 4-digit pin code from scratch-off panel',
  hideErrors: true,
  required: true,
  minLength: 4,
  maxLength: 4,
  regex: getRegExp(numbersPattern),
  validationMessageKeys: {
    required: formValidationGiftCardPinRequired,
    minLength: formValidationGiftCardPinRequired,
    maxLength: formValidationGiftCardPinRequired,
    regex: formValidationGiftCardPinRequired,
  },
  normalize: numbersOnly,
};

const formValidationGiftVoucherSecurityCodeRequired =
  'Please enter the 7 letter code under the scratch panel at the top left of your voucher';
export const GIFT_VOUCHER_SECURITY_CODE = {
  component: Input,
  type: 'text',
  autoComplete: 'off',
  id: 'giftVoucherSecurityCode',
  name: 'giftVoucherSecurityCode',
  label: 'Security code',
  instruction: 'e.g. ABCDEFG',
  a11yText: 'Enter the 7-letter security code (scratch-off panel)',
  minLength: 7,
  maxLength: 7,
  hideErrors: true,
  autoFocus: true,
  required: true,
  regex: getRegExp(uppercaseLettersNumbersPattern),
  validationMessageKeys: {
    required: formValidationGiftVoucherSecurityCodeRequired,
    minLength: formValidationGiftVoucherSecurityCodeRequired,
    maxLength: formValidationGiftVoucherSecurityCodeRequired,
    regex: formValidationGiftVoucherSecurityCodeRequired,
  },
  normalize: uppercaseLettersNumbers,
};

const formValidationGiftVoucherSerialNumberRequired =
  'Please enter the 10-digit number on the bottom right of your voucher';
export const GIFT_VOUCHER_SERIAL_NUMBER = {
  component: InputWithProgress,
  type: 'tel',
  autoComplete: 'off',
  id: 'giftVoucherSerialNumber',
  name: 'giftVoucherSerialNumber',
  label: 'Serial number',
  instruction: 'e.g. 12 34567890',
  a11yText: 'Enter the 10-digit serial number',
  hideErrors: true,
  required: true,
  minLength: 10,
  maxLength: 10 + 1,
  regex: getRegExp(numbersPattern),
  validationMessageKeys: {
    required: formValidationGiftVoucherSerialNumberRequired,
    minLength: formValidationGiftVoucherSerialNumberRequired,
    maxLength: formValidationGiftVoucherSerialNumberRequired,
    regex: formValidationInvalidCharacters,
  },
  normalize: numbersOnly,
  format: formatGiftVoucherSerialNumber,
  props: {
    inputElement: InputManageCursor,
  },
};
