export const ADDRESS_BOOK_ADDRESS_FORM_ID = 'addressBookAddressForm';

export default function getAddressBookAddressFormConfig(getConfig, editMode = false) {
  return (params) => {
    const config = getConfig({ ...params, editMode });
    return {
      submitButton: 'Use address',
      ...config,
      id: ADDRESS_BOOK_ADDRESS_FORM_ID,
    };
  };
}
