import { reportErrorToNewRelic } from '../../../utils/logging/logging-utils';
import { LAZY_COMPONENT_WITH_SUSPENSE } from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';

export const triggerLazyComponentWithSuspenseFailure = ({ error }) => (dispatch) => {
  if (error) {
    dispatch(reportErrorToNewRelic({ error, errorDescription: 'lazy component with suspense error' }));
  }

  dispatch({
    type: `${LAZY_COMPONENT_WITH_SUSPENSE}.FAILED`,
    error: {
      code: errorCodeConstants.LAZY_COMPONENT_WITH_SUSPENSE_ERROR,
    },
  });
};
