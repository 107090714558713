// Design System
import replaceStringVars from 'jl-design-system/utils/string/replaceStringVars';

// Types
import { PaymentCardProps } from 'types/Payment.types';

// Config
import paymentTypeConstants from '../../constants/paymentTypeConstants';

export default function getCardDetailsPayloadFromFormValues({
  cardType,
  formValues = {},
}: { cardType: string; formValues?: PaymentCardProps }) {
  const stripNonNumeric = (value: string | undefined) => value?.replace(/[^\d]/g, '') ?? '';
  const number = stripNonNumeric(formValues?.cardNumber);
  const expiryDate = stripNonNumeric(formValues?.expiryDate);

  return {
    type: paymentTypeConstants.CREDIT_CARD,
    creditCardDetails: {
      number,
      cardholderName: formValues?.cardholderName,
      expiryDate,
      securityCode: formValues?.securityCode,
      ...cardType && { cardType },
    },
  };
}

export function getLast4Digits(pan: string) {
  return pan.slice(-4);
}

export function getObfuscatedPanString(pan: string) {
  return replaceStringVars('**** **** **** ${last4Digits}', {
    last4Digits: getLast4Digits(pan),
  });
}
