import InputWithSubmit from 'jl-design-system/elements/input-with-submit/InputWithSubmit';
import getRegExp, { lettersNumbersPattern } from 'jl-design-system/form/regex';
import { uppercaseLettersNumbers } from 'jl-design-system/form/normalizers/restrictInput';

export const PROMO_CODE_FORM_ID = 'promoCodeForm';

export const PROMO_CODE_ERROR_ID = 'promoCodeErrorId';

export const PROMO_CODE = {
  component: InputWithSubmit,
  name: 'promoCode',
  type: 'text',
  id: 'promoCode',
  label: 'Add a promo code',
  autoFocus: true,
  maxLength: 30,
  validationMessageKeys: {
    maxLength: "You've entered a promo code that's too long",
    regex: 'Please enter a valid promo code',
  },
  regex: getRegExp(lettersNumbersPattern),
  normalize: uppercaseLettersNumbers,
  sessionCamExclude: false,
  ariaDescribedBy: PROMO_CODE_ERROR_ID,
};

const promoCodeFormConfig = ({
  fieldInputClassName = 'fieldLarge',
  showNewPromoCodeOption = false,
}) => {
  const promoCodeFields = {
    ...PROMO_CODE,
    className: fieldInputClassName,
    required: showNewPromoCodeOption,
  };

  return {
    id: PROMO_CODE_FORM_ID,
    fields: [
      promoCodeFields,
    ],
    submitButton: 'Apply',
    submitButtonInlineFieldId: PROMO_CODE.id,
  };
};

export default promoCodeFormConfig;
