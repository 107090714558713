// Types
import { RootState } from 'types/RootState.types';

import {
  getSessionExpiredSelector,
  getPosCreditSessionExpiredSelector,
  getSubmitOrderTimeoutSelector,
  getShowEmptyBasketNotificationSelector,
} from '../../redux/reducers/app/appSelector';
import { getErrorSelector } from '../../redux/reducers/error/errorSelector';

export const shouldShowUserActiveContent = (state: RootState) => {
  const sessionExpired = getSessionExpiredSelector(state);
  const posCreditSessionExpired = getPosCreditSessionExpiredSelector(state);
  const globalError = getErrorSelector(state);
  const submitOrderTimeout = getSubmitOrderTimeoutSelector(state);
  const showEmptyBasketNotification = getShowEmptyBasketNotificationSelector(state);

  return !sessionExpired
    && !posCreditSessionExpired
    && !globalError
    && !submitOrderTimeout
    && !showEmptyBasketNotification;
};
