// Types
import { AppDispatch, RootState } from 'types/RootState.types';

// Config
import { CARD_DETAILS_FORM_ID, PREPAID_CARD_DETAILS_FORM_ID } from '../form/configs/cardDetails';
import { sendNewRelicCustomEvent } from '../logging/logging-utils';
import paymentTypeConstants from '../../constants/paymentTypeConstants';

const logUnsupportedCardType = (
  selectedPaymentType: string,
) => (_dispatch: AppDispatch, getState: () => RootState): void => {
  const formId = selectedPaymentType === paymentTypeConstants.PREPAID_CARD ?
    PREPAID_CARD_DETAILS_FORM_ID :
    CARD_DETAILS_FORM_ID;
  const cardNumber = getState().form?.[formId]?.values?.cardNumber ?? '';
  const binRange = cardNumber.replace(' ', '').substring(0, 6);

  sendNewRelicCustomEvent('unsupportedBinRange', { binRange });
};

export default logUnsupportedCardType;
