import Input from 'jl-design-system/elements/input/Input';

import getRegExp, {
  lettersNumbersDotDashApostropheSpacePattern,
} from 'jl-design-system/form/regex';

import {
  restrictInputLettersNumbersDotDashApostropheSpaces,
} from '../normalizers/excludeSymbols';

const CLICK_AND_COLLECT_SEARCH = {
  component: Input,
  type: 'text',
  name: 'collectionPointSearchTerm',
  id: 'collectionPointSearchTerm',
  className: 'fieldLarge',
  label: 'Enter a postcode, town or shop name',
  required: true,
  maxLength: 150,
  validationMessageKeys: {
    required: 'Please enter a shop name or location',
    regex: 'Please remove the invalid characters to continue',
  },
  regex: getRegExp(lettersNumbersDotDashApostropheSpacePattern),
  normalize: restrictInputLettersNumbersDotDashApostropheSpaces,
};

export default CLICK_AND_COLLECT_SEARCH;
