// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export function shouldSaveDefaultAddress(state: RootState) {
  const {
    user: {
      addressBook = [],
      isSignedIn,
    } = {},
  } = state;

  const defaultAddress = addressBook.some(address => address.defaultShipping);

  return isFeatureActive(state, featureConstants.DEFAULT_DELIVERY_ADDRESS) && !defaultAddress && isSignedIn;
}
