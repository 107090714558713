import { PureComponent } from 'react';
import PropTypes from 'prop-types';

// redux
import { connect } from 'react-redux';
import {
  sessionExpiredRedirect,
} from '../../redux/actions/app/appActions';

export class SessionTimeoutRedirect extends PureComponent {

  static propTypes = {
    sessionExpiredAnnounced: PropTypes.bool,
    sessionExpiredRedirect: PropTypes.func,
  };

  static defaultProps = {
    sessionExpiredAnnounced: false,
    sessionExpiredRedirect: window.defaultFunc,
  };

  componentDidUpdate(prevProps) {
    const {
      sessionExpiredAnnounced,
      sessionExpiredRedirect,
    } = this.props;

    if (!prevProps.sessionExpiredAnnounced && sessionExpiredAnnounced) {
      sessionExpiredRedirect();
    }
  }

  render() {
    return null;
  }
}

export function mapStateToProps(state) {
  return {
    sessionExpiredAnnounced: state.app.sessionExpiredAnnounced,
  };
}

export function mapDispatchToProps() {
  return {
    sessionExpiredRedirect,
  };
}

export default connect(state => mapStateToProps(state), mapDispatchToProps())(SessionTimeoutRedirect);
