import { Component } from 'react';
import PropTypes from 'prop-types';
// lodash
import get from 'lodash/get';
// redux
import { connect } from 'react-redux';
import {
  handleAppsHandover,
  handleWebHandover,
} from '../../redux/actions/bff/bffActions';
import routeConstants from '../../constants/routeConstants';
import { APPS_AUTHENTICATED_HANDOVER, APPS_GUEST_HANDOVER } from '../../constants/actionConstants';
import { showDisableSiteSpinner } from '../../redux/actions/app/appActions';
import getCookieValue from '../../utils/helpers/cookie/getCookie';

export class SessionManager extends Component {

  static propTypes = {
    auth0AccessToken: PropTypes.string,
    basketId: PropTypes.string,
    biom: PropTypes.string,
    children: PropTypes.element.isRequired, // from react-router
    dynUserConfirm: PropTypes.string,
    dynUserId: PropTypes.string,
    email: PropTypes.string,
    environmentId: PropTypes.string,
    express: PropTypes.bool,
    fromAuth0: PropTypes.bool,
    getLoginPageComplete: PropTypes.bool,
    handleAppsHandover: PropTypes.func.isRequired,
    handleWebHandover: PropTypes.func.isRequired,
    isAppsAuthenticatedHandover: PropTypes.bool,
    isAppsGuestHandover: PropTypes.bool,
    jsessionId: PropTypes.string,
    lbnscId: PropTypes.string,
    monetateId: PropTypes.string,
    requestAccessToken: PropTypes.func,
    sessionHandoverComplete: PropTypes.bool,
    sessionHandoverFailed: PropTypes.bool,
    showDisableSiteSpinner: PropTypes.func,
    visitorId: PropTypes.string,
  };

  static defaultProps = {
    auth0AccessToken: undefined,
    basketId: undefined,
    biom: '',
    children: null,
    dynUserConfirm: undefined,
    dynUserId: undefined,
    email: '',
    environmentId: '',
    express: undefined,
    fromAuth0: undefined,
    getLoginPageComplete: false,
    handleAppsHandover: window.defaultFunc,
    handleWebHandover: window.defaultFunc,
    isAppsAuthenticatedHandover: false,
    isAppsGuestHandover: false,
    jsessionId: '',
    lbnscId: '',
    monetateId: undefined,
    requestAccessToken: undefined,
    sessionHandoverComplete: false,
    sessionHandoverFailed: false,
    showDisableSiteSpinner: window.defaultFunc,
    visitorId: '',
  };

  componentDidMount() {
    const {
      basketId,
      sessionHandoverComplete,
      sessionHandoverFailed,
      jsessionId,
      lbnscId,
      dynUserId,
      dynUserConfirm,
      isAppsGuestHandover,
      isAppsAuthenticatedHandover,
      email,
      environmentId,
      visitorId,
      biom,
      showDisableSiteSpinner,
      monetateId,
      express,
      fromAuth0,
    } = this.props;

    if (isAppsAuthenticatedHandover) {
      showDisableSiteSpinner();
      return;
    }

    if (isAppsGuestHandover) {
      showDisableSiteSpinner();
      this.props.handleAppsHandover({
        actionType: APPS_GUEST_HANDOVER,
        jsessionId,
        lbnscId,
        dynUserId,
        dynUserConfirm,
        email,
        environmentId,
        visitorId,
        biom,
        basketId,
        monetateId,
        fromAuth0,
        express,
      });
      return;
    }

    if (!sessionHandoverComplete && !sessionHandoverFailed) {
      this.props.handleWebHandover({
        express,
        basketId,
      });
    }
  }

  isAccessTokenProvided(prevProps) {
    return this.props.isAppsAuthenticatedHandover && !prevProps.auth0AccessToken && this.props.auth0AccessToken;
  }

  componentDidUpdate(prevProps) {
    const {
      jsessionId,
      lbnscId,
      dynUserId,
      dynUserConfirm,
      isAppsAuthenticatedHandover,
      environmentId,
      visitorId,
      biom,
      auth0AccessToken,
      requestAccessToken,
      basketId,
      monetateId,
      fromAuth0,
      express,
    } = this.props;

    if (isAppsAuthenticatedHandover
      && !prevProps.requestAccessToken
      && requestAccessToken) {
      requestAccessToken();
      return;
    }

    if (this.isAccessTokenProvided(prevProps)) {
      window.timeToAuth0Complete = Date.now();
      this.props.handleAppsHandover({
        actionType: APPS_AUTHENTICATED_HANDOVER,
        jsessionId,
        lbnscId,
        dynUserId,
        dynUserConfirm,
        environmentId,
        visitorId,
        biom,
        basketId,
        accessToken: auth0AccessToken,
        monetateId,
        fromAuth0,
        express,
      });
    }
  }

  render() {
    const { children, getLoginPageComplete } = this.props;

    return getLoginPageComplete ? children : null;
  }
}

export function mapStateToProps(state, ownProps) {
  const searchParams = new URLSearchParams(get(ownProps, 'location.search'));
  const query = Object.fromEntries(searchParams);
  const pathname = get(state.router, 'location.pathname');

  return {
    sessionHandoverComplete: state.bff?.sessionHandoverComplete,
    sessionHandoverFailed: state.bff?.sessionHandoverFailed,
    getLoginPageComplete: state.login?.getLoginPageComplete,
    isAppsGuestHandover: routeConstants.APPS_GUEST_HANDOVER === pathname,
    isAppsAuthenticatedHandover: routeConstants.APPS_AUTHENTICATED_HANDOVER === pathname,
    jsessionId: query.jsessionId,
    lbnscId: query.lbnscId,
    dynUserId: query.dynUserId,
    dynUserConfirm: query.dynUserConfirm,
    email: query.email,
    environmentId: query.envId,
    visitorId: query.vid,
    biom: query.biom,
    auth0AccessToken: get(state.mobileApps, 'auth0AccessToken'),
    requestAccessToken: get(state.mobileApps, 'requestAccessToken'),
    basketId: query.basketId || getCookieValue('basket_id'),
    monetateId: query.monetateId,
    express: query.express === 'true',
    fromAuth0: query.sso === 'false',
  };
}

export function mapDispatchToProps() {
  return {
    handleWebHandover,
    handleAppsHandover,
    showDisableSiteSpinner,
  };
}

export default connect((state, ownProps) => mapStateToProps(state, ownProps), mapDispatchToProps())(SessionManager);
