import { QUANTITY_INPUT } from 'jl-design-system/form/fields';
import { numbersPositiveOnly } from 'jl-design-system/form/normalizers/restrictInput';

export function calcMaxValueFromAvailability(stockLevel, quantityLimit) {
  const limit = parseInt(quantityLimit, 10);
  const stock = Number(stockLevel);
  const lowestValue = Math.min(limit, stock);

  // return whichever is lowest, stock level or quantity limit
  if (Number.isInteger(lowestValue)) return lowestValue;

  // if there's a quantity limit, return this
  if (Number.isInteger(limit)) return limit;

  // return the stock level
  return stock;
}

export const ITEM_QUANTITY_FORM = 'itemQuantityForm';
export function getFormId(id) {
  return `${ITEM_QUANTITY_FORM}-${id}`;
}

export default function getItemQuantityFormConfig({
  item,
  changeHandler,
  isItemFabric = false,
  editBasket,
}) {
  const { id, availability: { stockLevel, quantityLimit } = {} } = item;
  const selectMaxValue = calcMaxValueFromAvailability(stockLevel, quantityLimit);

  if (isItemFabric) {
    return {
      id: getFormId(id),
      fields: [{
        ...QUANTITY_INPUT,
        normalize: editBasket ? undefined : numbersPositiveOnly,
        props: {
          id: `itemQuantity-${id}`,
          name: `itemQuantity-${id}`,
          required: true,
          handleChange: changeHandler,
          unitText: 'metre(s)',
          selectMaxValue,
          quantity: item.quantity,
          textInputChangeDebounceMsecs: 500,
        },
      }],
    };
  }

  return {
    id: getFormId(id),
    maxValue: selectMaxValue,
  };
}
