import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
  SEARCH_FOR_ANOTHER_ADDRESS_BUTTON,
  SEARCH_UK_POSTCODE_BUTTON,
  POSTCODE,
  COUNTRY_SELECTOR,
} from 'jl-design-system/form/fields';
import getAddressFieldsConfig from 'jl-design-system/form/configs/address';
import {
  countryCodeIsInternational,
} from '../../address/addressHelpers';
import makePostcodeOptional from '../../address/makePostcodeOptional';
import { getStorageItem } from '../../storage/storage';
import appConstants from '../../../constants/appConstants';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export function getNameField(field, isPosCredit, text) {
  if (!isPosCredit) {
    return field;
  }

  return {
    ...field,
    maxLength: 30,
    validationMessageKeys: {
      ...field.validationMessageKeys,
      maxLength: `Please enter a ${text} name (maximum 30 characters)`,
      required: `Please enter a ${text} name (minimum 2 characters)`,
    },
  };
}

export const BILLING_ADDRESS_FORM_ID = 'billingDetailsForm';
export const RESIDENTIAL_ADDRESS_FORM_ID = 'residentialDetailsForm';
export default function getBillingDetailsFormConfig(params) {

  const {
    international,
    internationalMode,
    countryCode,
    addressLines = 3,
    maxAddressLines,
    actions,
    configExtras = {},
    postcodeSearchFormVisible,
    editMode,
    enableGBCounty,
  } = params;

  const {
    changeAddressButton,
    isClickCollectOrder,
    isPosCredit,
  } = configExtras;

  const renderAddressFields = !postcodeSearchFormVisible || editMode;

  let countrySelectorCountries;
  try {
    countrySelectorCountries = JSON.parse(getStorageItem(appConstants.COUNTRIES));
  } catch (err) {
    // No need to fallback to anything here as if countrySelectorCountries is undefined
    // we'll fallback to the list of countries in JLDS
  }

  const ADDRESS_FIELDS = renderAddressFields ? getAddressFieldsConfig({
    international,
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
    renderAddressFields,
    allowAsciiOnly: true,
    countrySelectorCountries,
    enableGBCounty,
  }) : [];

  if (!editMode) {
    const { searchForAnotherAddress, searchUKPostcode } = actions;

    const searchForAnotherAddressButton = !internationalMode ? {
      ...SEARCH_FOR_ANOTHER_ADDRESS_BUTTON,
      props: {
        ...SEARCH_FOR_ANOTHER_ADDRESS_BUTTON.props,
        children: 'Search for another address',
        'data-testid': 'search-for-address-button',
        onClick: searchForAnotherAddress,
      },
    } : {
      ...SEARCH_UK_POSTCODE_BUTTON,
      props: {
        ...SEARCH_UK_POSTCODE_BUTTON.props,
        children: 'Enter a UK address',
        'data-testid': 'search-for-address-button',
        onClick: searchUKPostcode,
      },
    };

    if (internationalMode && ADDRESS_FIELDS.length === 1) {
      const index = ADDRESS_FIELDS.findIndex(field => field?.id === COUNTRY_SELECTOR?.id);
      if (index >= 0) {
        ADDRESS_FIELDS[index].fieldExtra = searchForAnotherAddressButton;
      }
    }

    if (ADDRESS_FIELDS.length > 1) {
      const index = ADDRESS_FIELDS.findIndex(field => field?.id === POSTCODE?.id);
      if (index >= 0) {
        ADDRESS_FIELDS[index].fieldExtra = searchForAnotherAddressButton;
      }
    }
  }

  if (changeAddressButton) {
    const index = ADDRESS_FIELDS.findIndex(field => field?.id === POSTCODE?.id);
    if (index >= 0) {
      ADDRESS_FIELDS[index].fieldExtra = changeAddressButton;
    }
  }

  const PHONE_NUMBER = getInternationalPhoneNumberConfig({
    isClickCollectOrder,
    showDefaultInstruction: true,
  });

  const postcodeOptional = countryCodeIsInternational(countryCode);
  if (postcodeOptional) {
    makePostcodeOptional(ADDRESS_FIELDS);
  }

  return {
    id: isPosCredit ? RESIDENTIAL_ADDRESS_FORM_ID : BILLING_ADDRESS_FORM_ID,
    fields: [
      getNameField(FIRST_NAME_DATA_STANDARDS, isPosCredit, 'first'),
      getNameField(LAST_NAME_DATA_STANDARDS, isPosCredit, 'last'),
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
    ],
  };
}
