import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

// Config
import deliveryConstants, { undeliverableReasonConstants } from '../../constants/deliveryConstants';

export default function getUndeliverableItems(deliveries = [], items = []) {
  const restrictedItemsInDeliveries = deliveries.map(d => (d.items ?
    d.items.filter(item => item.undeliverableReasons?.includes(undeliverableReasonConstants.RESTRICTED))
    : [])).reduce((acc, items) => acc.concat(items.map(item => item.id)), []);
  const undeliverableDeliveries = deliveries.filter(d => d.type === deliveryConstants.UNDELIVERABLE);

  if (!isEmpty(restrictedItemsInDeliveries)) {
    const restrictedItemIds = new Set(restrictedItemsInDeliveries);
    const matchedItems = [];

    items.forEach((item) => {
      if (restrictedItemIds.has(item.id)) {
        matchedItems.push(item);
      }

      if (item.childItems) {
        item.childItems.forEach((childItem) => {
          if (restrictedItemIds.has(childItem.id)) {
            matchedItems.push(childItem);
          }
        });
      }
    });

    if (!isEmpty(matchedItems)) return matchedItems;
  }

  if (!isEmpty(undeliverableDeliveries)) {
    return flatten(undeliverableDeliveries.map(d => items
      .filter(item => d.items.map(delivery => delivery.id).includes(item.id))
      .map((preOrderItem) => {
        const undeliverableItems = d.items?.filter(deliverableItem => deliverableItem.id === preOrderItem.id);
        const undeliverableReasons = (undeliverableItems && undeliverableItems[0]?.undeliverableReasons) || [];
        return {
          ...preOrderItem,
          ...undeliverableReasons?.length && { undeliverableReasons },
          ...d.streetDate && { streetDate: d.streetDate },
        };
      })));
  }

  return null;
}
