// jl-design-system
import validate from 'jl-design-system/form/validation/validate';
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';
import getAddressFieldsConfig from 'jl-design-system/form/configs/address';
import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
  PHONE_NUMBER,
} from 'jl-design-system/form/fields';

import { countryCodeIsInternational } from '../address/addressHelpers';
import makePostcodeOptional from '../address/makePostcodeOptional';
import { internationalPhoneNumberLazy } from '../lazy/lazyMainBundle';
import getInternationalPhoneNumberConfig from '../lazy/internationalPhoneNumberConfig';
import { sendNewRelicCustomEvent } from '../logging/logging-utils';

export function validateOutOfAreaPostcodes(postcode = '') {
  const outOfAreaPostcodes = ['BF1', 'BF2', 'HA46AP'];
  const formattedPostcode = postcode.toUpperCase().replace(/ /g, '');
  const addressIsOutOfArea = outOfAreaPostcodes.some(code => formattedPostcode.startsWith(code));
  if (addressIsOutOfArea) return 'BFPO';

  return undefined;
}

export function getAddressFormConfig(addressFormValues, isDeliveryAddress) {

  const { countryCode } = addressFormValues;

  const ADDRESS_FIELDS = getAddressFieldsConfig({
    international: countryCodeIsInternational(countryCode),
    internationalMode: countryCodeIsInternational(countryCode),
    countryCode,
    addressLines: 3,
    maxAddressLines: 3,
    actions: {},
    configExtras: {},
    allowAsciiOnly: true,
    enableGBCounty: !!addressFormValues?.countyStateOrProvince,
  });

  const postcodeOptional = countryCodeIsInternational(countryCode);
  if (postcodeOptional && !isDeliveryAddress) {
    // international addresses can't be selected for delivery so this should only affect billing addresses
    makePostcodeOptional(ADDRESS_FIELDS);
  }

  const postcodeField = ADDRESS_FIELDS.find(field => field?.id === 'postcode');

  if (isDeliveryAddress && postcodeField) {
    postcodeField.validationFnc = validateOutOfAreaPostcodes;
  }

  return {
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      getInternationalPhoneNumberConfig(),
      ...ADDRESS_FIELDS,
    ],
  };
}

export function getAddressValidationErrors(addressRecord = {}, isDeliveryAddress) {
  const values = getFormInitialValuesFromAddress(addressRecord);

  let phoneNumberCountryCode;

  if (values.phoneNumber) {
    // we need to supply phoneNumberCountryCode field value to validate()
    // so that validation matches that when InternationalPhoneNumberInput is rendered in an AddressForm
    const InternationalPhoneNumberInput = internationalPhoneNumberLazy().component;
    if (InternationalPhoneNumberInput?.parseInitialPhoneNumber) {
      const params = [values.phoneNumber, values.countryCode || 'GB'];
      phoneNumberCountryCode = InternationalPhoneNumberInput.parseInitialPhoneNumber(...params).countryCode;
    } else {
      sendNewRelicCustomEvent('phoneNumberValidationIncomplete');
    }
  }

  const addressFormValues = {
    ...values,
    phoneNumberCountryCode,
  };
  const addressFormConfig = getAddressFormConfig(addressFormValues, isDeliveryAddress);

  return validate(addressFormValues, { config: addressFormConfig });
}

export function isNameAndNumberComplete(values) {
  return !!values[FIRST_NAME_DATA_STANDARDS.name] &&
    !!values[LAST_NAME_DATA_STANDARDS.name] &&
    !!values[PHONE_NUMBER.name];
}

function isAddressValid(addressRecord = {}) {
  const errors = getAddressValidationErrors(addressRecord);

  return Object.keys(errors).reduce((acc, key) => {
    if (acc && errors[key]) {
      return false;
    }
    return acc;
  }, true);

}

export default isAddressValid;
