import CLICK_AND_COLLECT_SEARCH from './clickAndCollectSearch';

export const CLICK_AND_COLLECT_FORM_ID = 'clickAndCollectSearchForm';

export default function clickAndCollectSearchFormConfig({
  savedCollectionPointsModalButton,
  clickAndCollectCollectionPointFieldLabel,
}) {

  return {
    id: CLICK_AND_COLLECT_FORM_ID,
    fields: [
      {
        ...CLICK_AND_COLLECT_SEARCH,
        label: clickAndCollectCollectionPointFieldLabel,
      },
    ],
    submitButton: 'Search',
    submitButtonExtra: savedCollectionPointsModalButton,
  };
}
