// lodash
import get from 'lodash/get';

import {
  GET_DELIVERY_METHODS,
  PUT_DELIVERY_ADDRESS,
  PUT_DELIVERY_DETAILS,
  SET_ADDRESS_BOOK_EDITABLE_ADDRESS,
  SET_PARTNER_DISCOUNT_PROCESSING,
  SET_PARTNER_DISCOUNT,
  REMOVE_ADDRESS,
  GET_ADDRESS_BOOK,
  ROUTER_LOCATION_CHANGE,
  SHOW_REMOVE_ADDRESS_OVERLAY,
  HIDE_REMOVE_ADDRESS_OVERLAY,
  UPDATE_SAVED_AS_DEFAULT_ADDRESS,
  LOGIN_RESET,
} from '../../../constants/actionConstants';
import { getSaveAsDefaultFieldIdByType, SAVE_AS_DEFAULT_FORM_ID } from '../../../utils/form/configs/saveAsDefault';
import deliveryConstants from '../../../constants/deliveryConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';

export const INITIAL_STATE = {
  editableAddress: '',
  loading: false,
  invalidAddress: false,
  removeAddressFailed: false,
  removeAddressSuccess: false,
  addressIdToBeRemoved: undefined,
  addressIdToBeSavedAsDefault: undefined,
  useAddressFailed: false,
};

const addressBookReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

    case SET_ADDRESS_BOOK_EDITABLE_ADDRESS: {
      return {
        ...state,
        editableAddress: action.selectedAddressId,
        invalidAddress: action.invalid,
      };
    }
    case UPDATE_SAVED_AS_DEFAULT_ADDRESS: {
      return {
        ...state,
        addressIdToBeSavedAsDefault: action.addressId,
      };
    }

    case '@@redux-form/CHANGE': {
      const formId = get(action, 'meta.form');
      const fieldId = get(action, 'meta.field');
      if (formId === SAVE_AS_DEFAULT_FORM_ID && fieldId === getSaveAsDefaultFieldIdByType(deliveryConstants.DELIVERY)) {
        return {
          ...state,
          addressIdToBeSavedAsDefault: action.payload,
        };
      }
      return state;
    }

    case LOGIN_RESET:
      return INITIAL_STATE;

    case SHOW_REMOVE_ADDRESS_OVERLAY:
      return {
        ...state,
        addressIdToBeRemoved: action.addressId,
        useAddressFailed: false,
        removeAddressOverlayVisible: true,
      };

    case HIDE_REMOVE_ADDRESS_OVERLAY:
      return {
        ...state,
        addressIdToBeRemoved: undefined,
        removeAddressOverlayVisible: false,
      };

    case `${PUT_DELIVERY_ADDRESS}.LOADING`:
    case `${GET_DELIVERY_METHODS}.LOADING`:
    case `${PUT_DELIVERY_DETAILS}.LOADING`:
    case `${SET_PARTNER_DISCOUNT}.LOADING`:
    case `${REMOVE_ADDRESS}.LOADING`:
      return {
        ...state,
        loading: true,
        useAddressFailed: false,
      };

    case `${PUT_DELIVERY_ADDRESS}.FAILED`:
      if (get(action, 'error.code') === errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR) {
        return {
          ...state,
          useAddressFailed: true,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };

    case `${GET_DELIVERY_METHODS}.FAILED`:
    case `${PUT_DELIVERY_DETAILS}.FAILED`:
    case `${PUT_DELIVERY_ADDRESS}.SUCCESS`:
    case `${GET_DELIVERY_METHODS}.SUCCESS`:
    case `${PUT_DELIVERY_DETAILS}.SUCCESS`:
    case `${SET_PARTNER_DISCOUNT_PROCESSING}.FALSE`:
      return {
        ...state,
        loading: false,
      };

    case `${REMOVE_ADDRESS}.FAILED`:
      return {
        ...state,
        removeAddressFailed: true,
        loading: false,
        addressIdToBeRemoved: undefined,
        removeAddressOverlayVisible: false,
      };

    case `${REMOVE_ADDRESS}.SUCCESS`:
      return {
        ...state,
        removeAddressSuccess: true,
        removeAddressOverlayVisible: false,
      };

    case `${GET_ADDRESS_BOOK}.LOADING`:
      return {
        ...state,
        getAddressBookAPICallActive: true,
      };

    case `${GET_ADDRESS_BOOK}.FAILED`:
      return {
        ...state,
        getAddressBookAPICallActive: false,
      };

    case `${GET_ADDRESS_BOOK}.SUCCESS`:
      return {
        ...state,
        loading: false,
        getAddressBookAPICallActive: false,
        addressIdToBeRemoved: undefined,
      };

    case ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        removeAddressFailed: false,
        removeAddressSuccess: false,
        addressIdToBeRemoved: undefined,
        useAddressFailed: false,
        removeAddressOverlayVisible: false,
      };

    default:
      return state;
  }
};

export default addressBookReducer;
