import React from 'react';

// Design System
import { PrimaryButton, SecondaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { CollectionPointProps } from 'types/CollectionPoint.types';
import { AddressBookModalRemoveOverlayProps } from './AddressBookModalRemoveOverlay.types';

// Config
import { useWindowSize } from '../../context/window/WindowSizeContext';

// Components
import Container from '../container';
import DisplayAddress from '../display-address/DisplayAddress';

// Styles
import styles from './address-book-modal-remove-overlay.scss';

const AddressBookModalRemoveOverlay = ({
  addressBook = [],
  addressId,
  collectionPointId,
  collectionPoints = [],
  confirmedDeliveryAddress,
  deleteCollectionPointApiCallActive = false,
  isClickCollectOrder = false,
  onCancel = () => {},
  onConfirm = () => {},
  selectedBillingAddress,
  selectedCollectionPoint = {},
  selectedDeliveryAddress,
}: AddressBookModalRemoveOverlayProps) => {
  const { isDesktop } = useWindowSize();

  const handleCancel = () => onCancel();
  const handleConfirm = (id = addressId) => onConfirm(id);

  const renderRemoveClickCollectSelectedAddress = () => (
    <Container testId="address-book-remove-delivery-address">
      <p><strong>You can&apos;t delete this address</strong></p>
      <p>
        This address may already be in use or it is your default delivery address.
        You will need to change this before you can delete this one.
      </p>
      <p>You can do this next time you choose a delivery address.</p>
      <PrimaryButton
        autoFocus
        data-testid="address-book-item-delete-address-cancel"
        inline={isDesktop}
        onClick={handleCancel}
      >
        Back
      </PrimaryButton>
    </Container>
  );

  const renderRemoveSelectedAddress = () => (
    <Container testId="address-book-remove-delivery-address">
      <p><strong>You can&apos;t delete your current address</strong></p>
      <p>
        This address is used for your current order.
        You will need to change to another address first before you can delete this one.
      </p>
      <PrimaryButton
        autoFocus
        data-testid="address-book-item-delete-address-cancel"
        inline={isDesktop}
        onClick={handleCancel}
      >
        Back
      </PrimaryButton>
    </Container>
  );

  const renderRemovePaymentAddress = () => (
    <Container testId="address-book-remove-payment-address">
      <p><strong>You can&apos;t delete your payment address</strong></p>
      <p>This address is linked to a payment card so cannot be deleted.</p>
      <p>You can manage your payment information in My Account.</p>
      <PrimaryButton
        autoFocus
        data-testid="address-book-item-delete-address-cancel"
        inline={isDesktop}
        onClick={handleCancel}
      >
        Back
      </PrimaryButton>
    </Container>
  );

  const renderRemoveCollectionPoint = (
    collectionPoint: CollectionPointProps,
    deleteCollectionPointApiCallActive: boolean,
  ) => (
    <Container testId="remove-saved-collection-point">
      <p><strong>Are you sure you want to delete this collection point?</strong></p>
      <DisplayAddress
        address={collectionPoint.address}
        collectionAddressTitle={collectionPoint.storeName}
        displayStoreNameInline
        hideMoreDetailsLink
        inline
        isClickCollectOrder
      />
      <Container marginTop="2">
        <PrimaryButton
          autoFocus
          data-testid="address-book-item-delete-collection-point-confirm"
          inline={isDesktop}
          onClick={() => handleConfirm(collectionPoint?.collectionPointId)}
          submitting={deleteCollectionPointApiCallActive}
        >
          Yes, delete
        </PrimaryButton>

        <SecondaryButton
          data-testid="address-book-item-delete-collection-point-cancel"
          inline={isDesktop}
          onClick={handleCancel}
        >
          No, cancel
        </SecondaryButton>
      </Container>
    </Container>
  );

  const renderRemoveSelectedCollectionPoint = () => (
    <Container testId="remove-selected-saved-collection-point">
      <p><strong>You can&apos;t delete your current collection point</strong></p>
      <p>
        The collection point is used for your current order.
        You will need to change to another collection point first before you can delete this one.
      </p>
      <PrimaryButton
        autoFocus
        data-testid="address-book-item-delete-collection-point-cancel"
        inline={isDesktop}
        onClick={handleCancel}
      >
        Back
      </PrimaryButton>
    </Container>
  );

  const renderRemoveContactAddress = () => (
    <Container testId="address-book-remove-contact-address">
      <p><strong>You can&apos;t delete your contact address</strong></p>
      <p>We use this address to send you things like guarantees in the post.</p>
      <p>You can change your contact address in My Account.</p>
      <PrimaryButton
        autoFocus
        data-testid="address-book-item-delete-address-cancel"
        inline={isDesktop}
        onClick={handleCancel}
      >
        Back
      </PrimaryButton>
    </Container>
  );

  const renderRemoveAddressConfirm = () => {
    const address = addressBook?.find(addressRecord => addressRecord.id === addressId);

    return (
      <Container testId="address-book-remove-address-confirmation">
        <p><strong>Are you sure you want to delete this address?</strong></p>
        <DisplayAddress
          address={address?.address}
          addressee={address?.addressee}
          inline
          phoneNumber={address?.phoneNumber}
        />
        <Container marginTop="2">
          <PrimaryButton
            autoFocus
            data-testid="address-book-item-delete-address-confirm"
            inline={isDesktop}
            onClick={() => handleConfirm()}
          >
            Yes, delete
          </PrimaryButton>

          <SecondaryButton
            data-testid="address-book-item-delete-address-cancel"
            inline={isDesktop}
            onClick={handleCancel}
          >
            No, cancel
          </SecondaryButton>
        </Container>
      </Container>
    );
  };


  if (!addressId && !collectionPointId) {
    return null;
  }

  const address = addressBook?.find(addressRecord => addressRecord.id === addressId);
  const collectionPoint = collectionPoints.find(point => point.id === collectionPointId);

  if ((addressId && !address) || (collectionPointId && !collectionPoint)) {
    return null;
  }

  let overlayContent;

  const { contact = null, payment = null } = address || {};
  const { id: confirmedDeliveryAddressId } = confirmedDeliveryAddress || {};
  const { id: selectedDeliveryAddressId } = selectedDeliveryAddress || {};
  const { id: selectedBillingAddressId } = selectedBillingAddress || {};
  const isContactAddress = contact;
  const isPaymentAddress = payment;
  const isSelected =
    confirmedDeliveryAddressId === addressId ||
    selectedDeliveryAddressId === addressId ||
    selectedBillingAddressId === addressId;

  const isSelectedCollectionPoint = selectedCollectionPoint.id === collectionPointId;

  if (collectionPointId && collectionPoint && !isSelectedCollectionPoint) {
    overlayContent = renderRemoveCollectionPoint(collectionPoint, deleteCollectionPointApiCallActive);
  } else if (collectionPointId && collectionPoint && isSelectedCollectionPoint) {
    overlayContent = renderRemoveSelectedCollectionPoint();
  } else if (isContactAddress) {
    overlayContent = renderRemoveContactAddress();
  } else if (isPaymentAddress) {
    overlayContent = renderRemovePaymentAddress();
  } else if (isSelected) {
    if (isClickCollectOrder) {
      overlayContent = renderRemoveClickCollectSelectedAddress();
    } else {
      overlayContent = renderRemoveSelectedAddress();
    }
  } else {
    overlayContent = renderRemoveAddressConfirm();
  }

  return (
    <Container className={`${styles.removeAddressOverlay} fade-in-animation`} testId="address-book-modal-remove-overlay">
      <Container className={styles.overlayContent} textAlign="center">
        { overlayContent }
      </Container>
    </Container>
  );
};

export default AddressBookModalRemoveOverlay;
