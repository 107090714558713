// Types
import { RootState } from 'types/RootState.types';

// Config
import { isMyJLMember } from '../myjl/isMyJLMember';

export const shouldApplyRewards = (state: RootState): boolean => {

  const shouldApplyRewards = state?.orderForm?.shouldApplyRewards;
  const isMember = isMyJLMember(state?.user?.loyaltyType ?? '');

  if (!isMember || !shouldApplyRewards) return false;

  return true;
};

export const shouldTriggerApplyRewards = (state: RootState): boolean => {
  const promoCodeApplied = state?.orderForm?.promoCodeApplied;
  const shouldSkipPromoCode = !promoCodeApplied || promoCodeApplied?.isRemoved === true;

  return !state?.orderForm?.customerRemovedRewards
    && shouldSkipPromoCode
    && isMyJLMember(state?.user?.loyaltyType ?? '');
};
