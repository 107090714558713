/* eslint-disable css-modules/no-undef-class */
/* eslint-disable css-modules/no-unused-class */
import cx from 'classnames';

// Types
import { DesignSystemTextThemeProps, DesignSystemHeadingThemeProps, SpacingTypes, SpacingPositions } from './DesignSystem.types';

// Styles
import './design-system.scss';

// Themes
export const colorKeys: Record<string, string> = {
  black: 'black',
  'grey-5': 'grey-5',
  'grey-15': 'grey-15',
  'grey-20': 'grey-20',
  'taupe-60': 'taupe-60',
  white: 'white',
};
export const designSystemColors: Record<string, { bg: string; border: string }> = Object.keys(colorKeys).reduce(
  (acc, color) => {
    acc[color] = {
      bg: `bg-${color}`,
      border: `border-${color}`,
    };
    return acc;
  }, {} as Record<string, { bg: string; border: string }>,
);

export function getBackgroundClass(color?: string) {
  return color && designSystemColors[color]?.bg;
}

export function getBorderClass(color?: string) {
  return color && designSystemColors[color]?.border;
}

// Typography
export const headingSizeKeys: Record<string, string> = { xs: 'xs', s: 's', m: 'm', l: 'l' };
export const headingThemes: DesignSystemHeadingThemeProps = Object.keys(headingSizeKeys).reduce((acc, size) => {
  acc[size] = `heading-${size}`;
  return acc;
}, {} as DesignSystemHeadingThemeProps);

export const bodySizeKeys: Record<string, string> = { m: 'm', l: 'l' };
export const textThemes: DesignSystemTextThemeProps = Object.keys(bodySizeKeys).reduce((acc, size) => {
  acc[size] = `text-${size}`;
  return acc;
}, {} as DesignSystemHeadingThemeProps);

// Spacing
const spacingSizes = ['auto', 0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, -0.5, -1, -1.5, -2, -3, -4, -5, -6, -7, -8];
type SpacingSizeKey = `${typeof spacingSizes[number]}`;
export const spacingSizeKeys: Record<SpacingSizeKey, number> = Object.fromEntries(
  spacingSizes.map(size => [size.toString(), size]),
) as Record<SpacingSizeKey, number>;

const prefixClass = (prefix: string, key: string, isNegative: boolean) => `${isNegative ? '-' : ''}${prefix}-${key}`;

const createMarginClasses = (key: string) => {
  const isNegative = key.startsWith('-');
  const formattedKey = key.replace('.', '_').replace('-', '');

  return {
    all: prefixClass('m', formattedKey, isNegative),
    bottom: prefixClass('mb', formattedKey, isNegative),
    left: prefixClass('ml', formattedKey, isNegative),
    right: prefixClass('mr', formattedKey, isNegative),
    top: prefixClass('mt', formattedKey, isNegative),
    x: prefixClass('mx', formattedKey, isNegative),
    y: prefixClass('my', formattedKey, isNegative),
  };
};

const createPaddingClasses = (key: string) => {
  const formattedKey = key.replace('.', '_');

  return {
    all: `p-${formattedKey}`,
    bottom: `pb-${formattedKey}`,
    left: `pl-${formattedKey}`,
    right: `pr-${formattedKey}`,
    top: `pt-${formattedKey}`,
    x: `px-${formattedKey}`,
    y: `py-${formattedKey}`,
  };
};

export const designSystemSpacing = Object.keys(spacingSizeKeys).reduce((acc, key) => {
  acc[key] = {
    margin: createMarginClasses(key),
    padding: createPaddingClasses(key),
  };
  return acc;
}, {} as {
  [key: string]: {
    margin: {
      all?: string;
      bottom?: string;
      left?: string;
      right?: string;
      top?: string;
      x?: string;
      y?: string;
    };
    padding: {
      all?: string;
      bottom?: string;
      left?: string;
      right?: string;
      top?: string;
      x?: string;
      y?: string;
    };
  };
});

export function getSpacingClass(
  { backupSize, position, size, type }:
  { backupSize?: string; position: SpacingPositions; size: string; type: SpacingTypes },
) {
  const primaryClass = designSystemSpacing[size]?.[type]?.[position];
  if (primaryClass) {
    return primaryClass;
  }

  if (backupSize) {
    const backupClass = designSystemSpacing[backupSize]?.[type]?.[position];
    return backupClass || undefined;
  }

  return undefined;
}

export function getSpacingClasses(
  { positions, type }:
  { positions: Record<string, string | undefined>; type: SpacingTypes },
) {
  if (positions.all) {
    return getSpacingClass({ type, position: 'all', size: positions.all });
  }

  return (
    cx(
      positions.bottom && getSpacingClass({ type, position: 'bottom', size: positions.bottom }),
      positions.left && getSpacingClass({ type, position: 'left', size: positions.left }),
      positions.right && getSpacingClass({ type, position: 'right', size: positions.right }),
      positions.top && getSpacingClass({ type, position: 'top', size: positions.top }),
      positions.x && getSpacingClass({ type, position: 'x', size: positions.x }),
      positions.y && getSpacingClass({ type, position: 'y', size: positions.y }),
    )
  );
}
