import React, { useMemo } from 'react';

// Design System
import Form from 'jl-design-system/components/form/Form';
import { Message } from 'jl-design-system/components/message/v2/Message';

// Types
import { LoginChoiceProps } from './LoginChoice.types';

// Config
import { guestFormConfig } from '../../utils/form/configs/login';

// Components
import Container from '../container';
import RadioButtonTile from '../radio-button-tile';

// Styles
import styles from './login-choice.scss';

const config = guestFormConfig();

const LoginChoice = ({
  auth0Failure = false,
  loginApiCallActive = false,
  onFormSubmit,
  showEmailValidationError = false,
  userEmail = '',
}: LoginChoiceProps) => {
  const label = useMemo(() => (
    <>
      <span data-testid="radio-field-label">Guest checkout</span>
      {!auth0Failure && (
        <span data-testid="radio-field-label-extra">You can create an account later</span>
      )}
    </>
  ), [auth0Failure]);

  return (
    <Container
      className={styles.container}
      testId="login-choice-new"
    >
      <RadioButtonTile
        id="guest:true"
        label={label}
        name="guest"
        value="guest:true"
      >
        <Container
          marginBottom="2"
          renderIf={showEmailValidationError}
        >
          <Message
            title="Please make sure your email format is correct"
            type="error"
          />
        </Container>

        <Form
          config={config}
          data-testid="register-form"
          destroyOnUnmount={false}
          form={config.id}
          initialValues={{ email: userEmail }}
          onSubmit={onFormSubmit}
          submittingOverride={loginApiCallActive}
          useV2Message
        />
      </RadioButtonTile>
    </Container>
  );
};

export default LoginChoice;
