import { createSelector } from 'reselect';

// Config
import { getAuth0CallbackCallbackErrorSelector } from '../../redux/reducers/auth0-callback/auth0CallbackSelector';
import { getFormGuestFormSelector } from '../../redux/reducers/form/formSelector';
import { getLoginApiCallActiveSelector, getLoginShowEmailValidationErrorSelector } from '../../redux/reducers/login/loginSelector';
import { getUserEmailAddressSelector } from '../../redux/reducers/user/userSelector';

const loginState = createSelector(
  [
    getAuth0CallbackCallbackErrorSelector,
    getLoginApiCallActiveSelector,
    getFormGuestFormSelector,
    getLoginShowEmailValidationErrorSelector,
    getUserEmailAddressSelector,
  ],
  (
    callbackError,
    loginApiCallActive,
    loginForm,
    showEmailValidationError,
    userEmail,
  ) => ({
    auth0Failure: !!callbackError,
    loginApiCallActive,
    loginForm,
    showEmailValidationError,
    userEmail,
  }),
);

export default loginState;
