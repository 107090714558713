import { createSelector } from 'reselect';

// Config
import { getAppDefaultGiftMessageValueFormSelector } from '../../redux/reducers/app/appSelector';
import { getDeliveryGiftMessageValidationFailedSelector } from '../../redux/reducers/delivery/deliverySelector';

const giftMessageState = createSelector(
  [
    getAppDefaultGiftMessageValueFormSelector,
    getDeliveryGiftMessageValidationFailedSelector,
  ],
  (
    defaultGiftMessageValue,
    giftMessageValidationFailed,
  ) => ({
    defaultGiftMessageValue,
    giftMessageValidationFailed,
  }),
);

export default giftMessageState;
