// jl-design-system
import env from 'jl-design-system/utils/env/env';
//
import { setStorageItem, getStorageItem } from '../../../utils/storage/storage';
import {
  SET_LOG_ANALYTICS,
} from '../../../constants/actionConstants';
import featureConstants from '../../../constants/featureConstants';

const INITIAL_STATE = {
  logAnalytics: getStorageItem('logAnalytics', true) || '',
};

export default function reducer(state = INITIAL_STATE, action = {}) {

  const actionType = action.type || '';

  if (env.isClientNonProd && actionType === SET_LOG_ANALYTICS) {
    setStorageItem({
      key: featureConstants.LOG_ANALYTICS,
      value: action.flag,
      local: true,
    });
    return {
      ...state,
      logAnalytics: action.flag,
    };
  }

  return state;
}
