import React from 'react';

// Types
import { TradeInDetailsProps } from './TradeInDetails.types';

// Components
import Container from '../container';
import TradeInItem from './trade-in-item';

const TradeInDetails = ({
  handleRemoveItem = () => {},
  hideItemRemoveOverlay = () => {},
  isModalView = false,
  isProcessing = false,
  showItemRemoveOverlay = () => {},
  showTradeInBoxInOrderConfirmation = false,
  tradeInItems = [],
}: TradeInDetailsProps) => tradeInItems?.length > 0 && (
  <Container
    borderBottom="grey-15"
    marginX={isModalView ? '0' : '3'}
    testId="trade-in-items-container"
  >
    {tradeInItems.map((item, ind) => (
      <TradeInItem
        key={`trade-in-item-${ind + 1}`}
        handleRemoveItem={handleRemoveItem}
        hideItemRemoveOverlay={hideItemRemoveOverlay}
        index={ind}
        isModalView={isModalView}
        isProcessing={isProcessing}
        itemId={item.id}
        itemName={item.deviceName}
        itemPaymentAmount={item.payment?.amount}
        itemPaymentType={item.payment?.type}
        showItemRemoveOverlay={showItemRemoveOverlay}
        showTradeInBoxInOrderConfirmation={showTradeInBoxInOrderConfirmation}
      />
    ))}
  </Container>
);

export default TradeInDetails;
