import React from 'react';

// Design System
import { Modal } from 'jl-design-system/components/modal/Modal';
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import reload from 'jl-design-system/utils/reload/reload';

// Components
import Container from '../container';

const ConnectivityErrorModal = () => (
  <Modal
    footer={false}
    isOpen
    onClose={reload}
    title="Lost connection"
  >
    <p>There was a problem connecting you to the next step.</p>
    <p>Please check your internet connection, refresh the page and try again.</p>
    <Container marginTop="3" textAlign="right">
      <PrimaryButton
        className="mb-0"
        inline
        onClick={reload}
      >
        Refresh the page
      </PrimaryButton>
    </Container>
  </Modal>
);

export default ConnectivityErrorModal;
