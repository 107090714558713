import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { validateAddressBookRecords } from '../../../utils/addressBook/validateAddressBookRecords';

function setConfirmedDeliveryAddress(state, action) {
  if (action.address) {
    // customer has chosen a new address
    return action.address;
  }

  if (state.confirmedDeliveryAddress) {
    // customer has already chosen a delivery address
    return state.confirmedDeliveryAddress;
  }

  // try and find the customer's address from the address provided in API response
  const addressBook = get(action, 'result.customer.addressBook', []);
  const addressFromResponse = get(action, 'result.orderForm.deliveryAddress', {});

  const foundAddress = addressBook?.find(addressRecord => isEqual(addressRecord.address, addressFromResponse.address) &&
    isEqual(addressRecord.addressee, addressFromResponse.addressee) &&
    isEqual(addressRecord.phoneNumber, addressFromResponse.phoneNumber));

  if (foundAddress) {
    const validatedAddressList = validateAddressBookRecords([foundAddress]);
    const validatedAddress = validatedAddressList[0] ?? {};

    if (!validatedAddress.invalid && !validatedAddress.notAvailableForDelivery) {
      return validatedAddress;
    }
  }

  return undefined;
}

export default setConfirmedDeliveryAddress;
