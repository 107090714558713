// lodash
import isEmpty from 'lodash/isEmpty';
import { getCalendarAnalytics } from './getCalendarAnalytics';
import daysBetweenDateAndStartOfWeek from '../../helpers/date/daysBetweenDateAndStartOfWeek';
import {
  COLLECT_PLUS,
  COLLECT_PLUS_OWNER_ID,
  ROYAL_MAIL_OWNER_ID,
  CO_OP_MIDCOUNTIES_OWNER_ID,
  EVRI_OWNER_ID,
} from '../../../constants/clickCollectConstants';

export function getUnavailableStores(stores = []) {
  return stores.slice(0, 5).map((item, index) => ({ ...item, index: index + 1 }))
    .filter(store => !isEmpty(store.unavailabilityReason));
}

export function getUnavailableStoresAnalyticsInfo(item) {
  return item.storeName
    .concat(':', item.unavailabilityReason.replace('due to ', ''), ':', item.index)
    .toLocaleLowerCase();
}

export function selectClickAndCollectStoreAnalytics(
  store,
  searchTerm,
  collectionDates,
  selectedIndex,
) {
  let collectionDateAnalytics = {};

  if (!isEmpty(collectionDates)) {
    const dates = collectionDates.filter(date => date.isAvailable).map(
      filteredDate => filteredDate.date,
    );

    const firstDeliverySlot = daysBetweenDateAndStartOfWeek(dates[0]);

    // TODO refactor getCalendarAnalytics to be more generic
    collectionDateAnalytics = {
      ...getCalendarAnalytics({
        deliveryMethods: [{
          dates,
          description: 'Click and Collect',
          id: '0',
        }],
        calendarPageIndexObject: {
          0: 0,
        },
        daysToShowPerPage: 7,
        selectedDeliveryMethodId: '0',
      })[0],
      firstDeliverySlot,
      clickandCollectType: 'named day',
      slotSelected: '',
    };
  }

  const deliveryPropositionValues = {
    [COLLECT_PLUS]: {
      method: 'col:cp',
      message: 'collect+',
    },
    default: {
      method: 'col:cc',
      message: 'click & collect',
    },
  };

  const getValues = (owner, valueType) => {
    let ownerType = {};
    if (owner === COLLECT_PLUS) {
      ownerType = deliveryPropositionValues[COLLECT_PLUS];
    } else ownerType = deliveryPropositionValues.default;

    return ownerType[valueType];
  };

  const getDeliveryPropositionValues = (ownerId, type) => {
    switch (ownerId) {
      case COLLECT_PLUS_OWNER_ID:
      case ROYAL_MAIL_OWNER_ID:
      case EVRI_OWNER_ID:
        return getValues(COLLECT_PLUS, type);
      case CO_OP_MIDCOUNTIES_OWNER_ID:
      default:
        return getValues('default', type);
    }
  };

  const deliveryProposition = [{
    selected: 1,
    deliveryId: store.id,
    method: getDeliveryPropositionValues(store.ownerId, 'method'),
    message: getDeliveryPropositionValues(store.ownerId, 'message'),
    searchTerm,
    ...collectionDateAnalytics,
    ...(selectedIndex && { shopSelected: selectedIndex }),
  }];

  return {
    checkout: {
      batch: [
        {
          deliveryProposition,
        },
      ],
    },
  };
}
