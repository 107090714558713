/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import cx from 'classnames';

// Types
import { LoadingDeliveryDetailsProps } from './LoadingDeliveryDetails.types';

// Components
import LoadingElement from '../loading-element';
import Container from '../../container';

// Styles
import styles from '../loading-wrapper.scss';

const LoadingDeliveryDetails = (
  { className }: LoadingDeliveryDetailsProps,
) => (
  <Container
    className={cx(className, styles.deliveryDetails)}
    testId="loading-delivery-details"
  >
    {Array.from({ length: 4 }, (_, index) => (
      <Container key={`loading-delivery-details__${index}`} marginBottom={index > 0 ? '1' : '2'}>
        <LoadingElement type={index > 0 ? 'light' : 'dark'} />
      </Container>
    ))}
  </Container>
);

export default LoadingDeliveryDetails;
