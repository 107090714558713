// lodash
import get from 'lodash/get';

import {
  LOQATE_ADDRESS_SEARCH,
  LOQATE_ADDRESS_SELECT,
} from 'jl-design-system/redux/actions/addressSearch/addressSearchActionConstants';
import {
  USER_AGE_CHECK,
  UPDATE_CUSTOMER_DOB,
  POST_ADDRESS_BOOK_CREATE,
  PUT_ADDRESS_BOOK_UPDATE,
  SET_PARTNER_DISCOUNT,
  SUBMIT_ORDER,
  APPLY_PROMO_CODE,
  ROUTER_LOCATION_CHANGE,
  DELETE_COLLECTION_POINT,
} from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { getGlobalError } from '../../../utils/error/parseError';


export const INITIAL_STATE = {
  error: undefined,
};


const errorReducer = (state = INITIAL_STATE, action = {}) => {
  const { type } = action;
  const existingErrorCode = get(state, 'error.code');

  if (
    type === ROUTER_LOCATION_CHANGE &&
    (existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_BAD_SIGNATURE ||
      existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_MALFORMED ||
      existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_EXPIRED)
  ) {
    return state;
  }


  if (
    type === ROUTER_LOCATION_CHANGE ||
    type === `${USER_AGE_CHECK}.FAILED` ||
    type === `${UPDATE_CUSTOMER_DOB}.FAILED`
  ) {
    return {
      error: undefined,
    };
  }

  if (
    type === `${PUT_ADDRESS_BOOK_UPDATE}.FAILED` ||
    type === `${POST_ADDRESS_BOOK_CREATE}.FAILED` ||
    type === `${DELETE_COLLECTION_POINT}.FAILED` ||
    (type === `${SET_PARTNER_DISCOUNT}.FAILED` && action.partnerDiscountEnabled) ||
    type === `${APPLY_PROMO_CODE}.FAILED` ||
    type.includes(`${LOQATE_ADDRESS_SEARCH}.FAILED`) ||
    type.includes(`${LOQATE_ADDRESS_SELECT}.FAILED`) ||
    type.includes('PREFERENCES')
  ) {
    return state;
  }

  if (state.error) {
    return state;
  }

  const errorCode = get(action, 'error.code');
  const ignoreErrors = [
    errorCodeConstants.CLIENT_CONNECTIVITY_ERROR,
    errorCodeConstants.ORDERING_IN_PROGRESS,
  ];

  const ignoreAction = type === `${SUBMIT_ORDER}.FAILED` && ignoreErrors.includes(errorCode);

  return {
    error: type?.includes('.FAILED') && !ignoreAction ? getGlobalError(action) : undefined,
  };

};

export default errorReducer;
