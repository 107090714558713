export default function getPathWithQueryString({
  path = '',
  query = '',
}: { path?: string; query?: string } = {}): string {
  if (query) {
    if (query.indexOf('?') === 0) {
      return `${path}${query}`;
    }
    return `${path}?${query}`;
  }
  return path;
}
