import { createSelector } from 'reselect';

// Config
import { getConfirmedDeliveryAddressSelector } from '../../../../redux/reducers/delivery/deliverySelector';
import { getShowAddressBookModalSelector } from '../../../../redux/reducers/payment/paymentSelector';
import {
  getAddressBookSelector,
  getSelectedBillingAddressSelector,
} from '../../../../redux/reducers/user/userSelector';

const getBillingAddressSelector = createSelector(
  [getSelectedBillingAddressSelector, getConfirmedDeliveryAddressSelector],
  (selectedBillingAddress, confirmedDeliveryAddress) => selectedBillingAddress || confirmedDeliveryAddress,
);

const cardholdersDetailsAddressBookState = createSelector(
  [getAddressBookSelector, getBillingAddressSelector, getShowAddressBookModalSelector],
  (
    addressBook,
    billingAddress,
    showAddressBookModal,
  ) => ({
    addressBook,
    billingAddress,
    showAddressBookModal,
  }),
);

export default cardholdersDetailsAddressBookState;
