export default function setCookie({
  domain,
  expires,
  name,
  path = '/',
  session = false,
  value,
}: {
  domain?: string; expires?: Date; name: string; path?: string; session?: boolean; value?: number | string;
}): void {
  const expiresValue = session ? '' : `expires=${expires?.toUTCString()}; `;
  const domainValue = domain ? ` domain=${domain};` : '';

  document.cookie = `${name}=${value};${domainValue} ${expiresValue}path=${path};`;
}
