// lodash
import isEmpty from 'lodash/isEmpty';
// jl-design-system
import validate from 'jl-design-system/form/validation/validate';
import env from 'jl-design-system/utils/env/env';
// constants
import {
  CREATE_GOOGLE_PAY_PAYMENT,
  GOOGLE_PAY_CANCEL,
  GOOGLE_PAY_PAYMENT_DATA_ERROR,
  GOOGLE_PAY_PAYMENT_DATA_RECEIVED,
  GOOGLE_PAY_STUBS_ENABLED,
  GOOGLE_PAY_STUBS_MODAL_OPEN,
  GOOGLE_PAY_SUBMIT_PAYMENT_DATA,
  GOOGLE_PAY_WALLET_ELIGIBLE,
  SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS,
  UPDATE_BILLING_ADDRESS,
  GOOGLE_SCRIPT_FAILED,
  GOOGLE_SCRIPT_LOADED,
  GOOGLE_SCRIPT_LOADING,
} from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import featureConstants from '../../../constants/featureConstants';
import {
  URL_CREATE_GOOGLE_PAY_PAYMENT,
  URL_OCP_SUBMIT_CARD_DETAILS,
} from '../../../constants/endpointConstants';
import paymentStatusConstants from '../../../constants/paymentStatusConstants';
// redux
import { updateBillingAddress } from './cardPaymentActions';
import { initPaymentPage } from './orderFormActions';
import {
  setPaymentProcessing,
  pendingChallege,
  pendingBrowserCheck,
  triggerPlaceOrderAndPayAnalytics,
} from './paymentActions';
import { submitOrderAndRedirect } from './submitOrderActions';
import { isAndroidApp } from '../../reducers/app/appReducer';
import { isFeatureActive } from '../../reducers/config/configReducer';
// utils
import getBillingDetailsFormConfig from '../../../utils/form/configs/billingAddress';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';
import browserInfo from '../../../utils/browserInfo/browserInfo';
import googlePayAllowedAuthMethods from '../../../utils/payment/googlePayAllowedAuthMethods';
import {
  getBillingAddress,
  getFormattedAddressFromGooglePay,
} from '../../../utils/address/addressHelpers';
import { loadScript } from '../../../utils';

export const setCanMakeGooglePayPayments = (baseCardPaymentMethod, cardPaymentMethod) => ({
  type: SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS,
  baseCardPaymentMethod,
  cardPaymentMethod,
});

export const canMakeGooglePayPayments = () => (dispatch, getState) => {
  const paymentsClient = window?.google?.payments?.api?.PaymentsClient;
  const googlePayPaymentsClientHasBeenCreated = getState().payment.googlePayPaymentsClientHasBeenCreated;

  if (paymentsClient && !googlePayPaymentsClientHasBeenCreated) {
    const state = getState();
    const googlePayConfiguration = state.config.googlePayConfiguration;
    const paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: googlePayConfiguration.environment,
      merchantInfo: googlePayConfiguration.merchantInfo,
    });

    const allowedAuthMethods = googlePayAllowedAuthMethods(state, googlePayConfiguration.allowedAuthMethods);

    const baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods,
        allowedCardNetworks: googlePayConfiguration.allowedCardNetworks,
        assuranceDetailsRequired: true,
        billingAddressRequired: true,
        billingAddressParameters: {
          format: 'FULL',
          phoneNumberRequired: true,
        },
      },
    };

    const cardPaymentMethod = {
      ...baseCardPaymentMethod,
      tokenizationSpecification: googlePayConfiguration.tokenizationSpecification,
    };

    const isReadyToPayRequest = googlePayConfiguration.baseRequest;
    isReadyToPayRequest.allowedAuthMethods = allowedAuthMethods;
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
    isReadyToPayRequest.existingPaymentMethodRequired = true;

    sendNewRelicCustomEvent('checkoutHandler', {
      checkoutHandler: window.CheckoutHandler || 'checkoutHandlerMissing',
    });

    if (isAndroidApp(getState())) {
      sendNewRelicCustomEvent('precanMakeGooglePayPaymentsCall', {
        request: JSON.stringify(isReadyToPayRequest),
      });

      window.CheckoutHandler.canMakeGooglePayPayments(JSON.stringify(isReadyToPayRequest));

      sendNewRelicCustomEvent('postcanMakeGooglePayPaymentsCall', {
        request: JSON.stringify(isReadyToPayRequest),
      });

      return;
    }

    paymentsClient.isReadyToPay(isReadyToPayRequest).then((response) => {
      const {
        paymentMethodPresent,
        result,
      } = response;

      if (result && paymentMethodPresent) {
        dispatch({
          type: GOOGLE_PAY_WALLET_ELIGIBLE,
        });

        dispatch(setCanMakeGooglePayPayments(baseCardPaymentMethod, cardPaymentMethod));
      }
    });
  }
};

export const googlePayOnCancel = () => async (dispatch, getState) => {
  const sessionId = getState()?.bff?.sessionId;
  sendNewRelicCustomEvent('googlePayCancelled', {
    sessionId,
  });
  dispatch({
    type: GOOGLE_PAY_CANCEL,
  });
  dispatch(initPaymentPage());
};

export const createGooglePayPayment = cardType => ({
  type: CREATE_GOOGLE_PAY_PAYMENT,
  request: client => client({
    path: URL_CREATE_GOOGLE_PAY_PAYMENT,
    config: {
      method: 'POST',
      body: {
        cardType,
      },
    },
  }),
});

export const submitGooglePayPaymentData = (createPaymentResponse, token) => async (dispatch, getState) => {
  const state = getState();
  const hostUrl = state?.orderForm?.ocpIntegration?.baseUrl;
  const sessionId = state?.bff?.sessionId;
  const orderFormId = state?.orderForm?.id;
  const omsOrderId = state?.orderForm?.omsOrderId;
  const {
    authenticationMethodNotificationUrl,
    challengeNotificationUrl,
    id: paymentId,
  } = createPaymentResponse;

  const assuranceDetails = state?.payment?.googlePayPaymentData?.paymentMethodData?.info?.assuranceDetails;

  const body = {
    method: 'googlePay',
    googlePayData: window.btoa(token),
    assuranceDetails,
    browserInfo: browserInfo(),
    authenticationMethodNotificationUrl,
    challengeNotificationUrl,
  };

  const headers = {
    JSESSIONID: sessionId,
    'JL-BASKET-ID': orderFormId,
    'JL-OMS-ORDER-ID': omsOrderId,
    'JL-CALLING-APP-V1': 'OCC',
    Accept: 'application/vnd.jl-payments-v3+json',
    'Content-Type': 'application/vnd.jl-payments-v3+json',
    'Accept-Language': '*',
  };

  return dispatch({
    type: GOOGLE_PAY_SUBMIT_PAYMENT_DATA,
    request: client => client({
      path: URL_OCP_SUBMIT_CARD_DETAILS(paymentId),
      config: {
        method: 'PATCH',
        headers,
        body,
      },
      hostUrl,
    }),
  });
};

export const onSuccessfulAddressSubmit = () => async (dispatch, getState) => {
  const paymentData = getState().payment.googlePayPaymentData;
  const createPaymentResponse =
    await dispatch(createGooglePayPayment(paymentData?.paymentMethodData?.info?.cardNetwork));

  await dispatch(triggerPlaceOrderAndPayAnalytics(createPaymentResponse?.result?.id));

  if (createPaymentResponse.type === `${CREATE_GOOGLE_PAY_PAYMENT}.SUCCESS`) {
    const token = paymentData?.paymentMethodData?.tokenizationData?.token;

    const submitPaymentDataResponse = await dispatch(submitGooglePayPaymentData(createPaymentResponse?.result, token));

    if (submitPaymentDataResponse.result) {
      const { browserCheck, status, challengeCheck } = submitPaymentDataResponse.result;

      if (status === paymentStatusConstants.PENDING_BROWSER_CHECK) {
        const { actionUrl, formData } = browserCheck;
        return dispatch(pendingBrowserCheck(createPaymentResponse?.result?.id, actionUrl, formData));
      }

      if (status === paymentStatusConstants.PENDING_CHALLENGE) {
        const { actionUrl, formData } = challengeCheck;
        return dispatch(pendingChallege(actionUrl, formData));
      }

      if (submitPaymentDataResponse.type === `${GOOGLE_PAY_SUBMIT_PAYMENT_DATA}.SUCCESS`) {
        dispatch(setPaymentProcessing(false));
        return dispatch(submitOrderAndRedirect());
      }
    }
  }

  dispatch(setPaymentProcessing(false));
  return dispatch(initPaymentPage());
};

export const resubmitBillingAddress = () => async (dispatch, getState) => {
  const billingAddress = getBillingAddress(getState());

  const updateBillingAddressResponse = await dispatch(updateBillingAddress({
    body: billingAddress,
    isGooglePay: true,
  }));
  if (updateBillingAddressResponse.type === `${UPDATE_BILLING_ADDRESS}.SUCCESS`) {
    return dispatch(onSuccessfulAddressSubmit());
  }

  dispatch(setPaymentProcessing(false));
  return dispatch(initPaymentPage());
};

export const handleGooglePayPaymentData = paymentData => async (dispatch) => {
  const rawAddress = paymentData?.paymentMethodData?.info?.billingAddress;
  const formattedAddress = getFormattedAddressFromGooglePay(rawAddress);
  const validateAddressResult = validate({
    ...formattedAddress?.address,
    ...formattedAddress?.addressee,
    phoneNumber: formattedAddress.phoneNumber,
  }, {
    config: getBillingDetailsFormConfig({
      countryCode: formattedAddress.address?.countryCode,
      actions: {},
      enableGBCounty: !!formattedAddress.address?.countyStateOrProvince,
    }),
  });

  if (!isEmpty(validateAddressResult)) {
    return dispatch({
      type: `${UPDATE_BILLING_ADDRESS}.FAILED`,
      error: {
        code: errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS,
      },
      googlePayBillingAddress: formattedAddress,
    });
  }

  const updateBillingAddressResponse =
    await dispatch(updateBillingAddress({
      body: formattedAddress,
      isGooglePay: true,
    }));

  if (updateBillingAddressResponse.type === `${UPDATE_BILLING_ADDRESS}.SUCCESS`) {
    return dispatch(onSuccessfulAddressSubmit());
  }

  dispatch(setPaymentProcessing(false));
  return dispatch(initPaymentPage());
};

export const handleGooglePaySuccess = paymentData => async (dispatch) => {
  sendNewRelicCustomEvent('handleGooglePaySuccess', {
    paymentData,
  });

  await dispatch({
    type: GOOGLE_PAY_PAYMENT_DATA_RECEIVED,
    paymentData,
  });

  sendNewRelicCustomEvent('googlePayPaymentDataReceived', {
    paymentData,
  });

  dispatch(handleGooglePayPaymentData(paymentData));
};

export const handleGooglePayFailure = () => (dispatch, getState) => {
  const sessionId = getState()?.bff?.sessionId;
  sendNewRelicCustomEvent('googlePayFailed', {
    sessionId,
  });
  dispatch({
    type: GOOGLE_PAY_PAYMENT_DATA_ERROR,
  });
};

export const handleGooglePayButtonClick = () => async (dispatch, getState) => {
  const state = getState();
  const config = state.config.googlePayConfiguration;
  const paymentsClient = new window.google.payments.api.PaymentsClient({
    environment: config.environment,
    merchantInfo: config.merchantInfo,
  });

  const transactionInfo = {
    totalPriceStatus: 'FINAL',
    totalPrice: state.orderForm?.amounts.outstandingBalanceRaw,
    currencyCode: 'GBP',
    countryCode: 'GB',
  };

  const paymentDataRequest = {
    apiVersion: config.baseRequest.apiVersion,
    apiVersionMinor: config.baseRequest.apiVersionMinor,
    allowedPaymentMethods: [state.payment.googlePayCardPaymentMethod],
    transactionInfo,
    merchantInfo: config.merchantInfo,
  };

  await dispatch(setPaymentProcessing(true));

  if (isAndroidApp(state)) {
    sendNewRelicCustomEvent('callingCheckoutHandler', {
      paymentDataRequest: JSON.stringify(paymentDataRequest),
    });

    window.CheckoutHandler.sendGooglePayPaymentRequest(JSON.stringify(paymentDataRequest));
    return;
  }

  paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
    dispatch(handleGooglePaySuccess(paymentData));
  }).catch((err) => {
    // eslint-disable-next-line
    console.log(err);

    const sessionId = state?.bff?.sessionId;

    // This spelling is intentionally incorrect
    if (err?.statusCode === 'CANCELED') {
      sendNewRelicCustomEvent('googlePayCancelled', {
        sessionId,
      });
      dispatch({
        type: GOOGLE_PAY_CANCEL,
      });
    } else {
      sendNewRelicCustomEvent('googlePayFailed', {
        sessionId,
      });
      dispatch({
        type: GOOGLE_PAY_PAYMENT_DATA_ERROR,
      });
    }
  });
};

export const mockGooglePayDataReceived = paymentData => async (dispatch) => {
  await dispatch({
    type: GOOGLE_PAY_PAYMENT_DATA_RECEIVED,
    paymentData,
  });

  dispatch(handleGooglePayPaymentData(paymentData));
};

export const openGooglePayStubsModal = () => ({
  type: GOOGLE_PAY_STUBS_MODAL_OPEN,
});

export const enableGooglePayStubs = () => ({
  type: GOOGLE_PAY_STUBS_ENABLED,
});

export const loadGooglePayScript = () => async (dispatch, getState) => {
  const state = getState();

  if (isFeatureActive(getState(), featureConstants.DISABLE_GOOGLE_PAY)) {
    return;
  }

  if (!state.user.deliveryPageLoaded) {
    return;
  }

  // change this to use real google pay locally
  if (env.isClientNonProd) {
    dispatch(canMakeGooglePayPayments());
    return;
  }

  if (state.payment.googlePayScriptLoaded) {
    return;
  }

  if (state.payment.googlePayScriptLoading) {
    return;
  }

  if (state.payment.googlePayScriptFailed) {
    return;
  }

  await dispatch({
    type: GOOGLE_SCRIPT_LOADING,
  });

  loadScript({
    url: 'https://pay.google.com/gp/p/js/pay.js',
    onErrorCallback: () => {
      dispatch({
        type: GOOGLE_SCRIPT_FAILED,
      });
    },
    onSuccessCallback: () => {
      dispatch({
        type: GOOGLE_SCRIPT_LOADED,
      });

      dispatch(canMakeGooglePayPayments());
    },
    async: true,
  })();
};
