import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';
import AddIcon from 'jl-design-system/elements/icons-jb/Plus24PxOutlined';
import MinusIcon from 'jl-design-system/elements/icons-jb/Minus24PxOutlined';

// Types
import { ExpandableContentProps } from './ExpandableContent.types';

// Components
import Container from '../container';

// Styles
import styles from './expandable-content.scss';

const ExpandableContent = ({
  classNames,
  children,
  containerCanBeContracted = true,
  itemsNeededToEnableExpander = 2,
  onExpanded = () => {},
}: ExpandableContentProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const elRef = useRef<HTMLDivElement>(null);

  const containerShouldBeContracted = !expanded && totalItems > itemsNeededToEnableExpander;
  const containerShouldBeExpanded = expanded && totalItems > itemsNeededToEnableExpander;
  const canToggle = (containerShouldBeContracted || containerShouldBeExpanded);

  const setExpandToggleState = () => {
    const isExpanded = expanded;
    setExpanded(!isExpanded);

    if (!isExpanded && onExpanded) {
      onExpanded();
    }
  };

  const setContainerHeight = (height: number | null) => {
    if (elRef.current) {
      elRef.current.style.height = height !== null ? `${height}px` : '';
    }
  };

  const setInlineHeightStyles = () => {
    const items = elRef?.current?.children;

    if (items) {
      if (items.length > itemsNeededToEnableExpander) {
        const height = expanded ?
          elRef.current.scrollHeight
          : items[0].clientHeight + items[1].clientHeight;
        setContainerHeight(height);
      } else {
        setContainerHeight(null);
      }
    }
  };

  useEffect(() => {
    if (!containerCanBeContracted) return;

    const items = elRef.current?.children;
    if (items) {
      setTotalItems(items.length);
    }
  }, [children, containerCanBeContracted]);

  useEffect(() => {
    if (!containerCanBeContracted || totalItems <= 0) return;

    setInlineHeightStyles();
  }, [containerCanBeContracted, expanded, totalItems]);

  const containerClassnames = cx(styles.container, {
    [styles.canToggle]: canToggle,
    [styles.containerContracted]: containerShouldBeContracted,
    [styles.containerExpanded]: containerShouldBeExpanded,
  });

  if (!containerCanBeContracted) {
    return children;
  }

  return (
    <div ref={elRef} className={containerClassnames} data-testid="expandable-content-container">
      {children}

      {canToggle && (
        <Container
          background="white"
          className={styles.expandToggleContainer}
          hideForPrint
          paddingTop="2"
          width="full"
        >
          <TertiaryButton
            IconComponent={expanded ? MinusIcon : AddIcon}
            className={classNames?.button}
            data-testid="show-item-button"
            iconPositionRight
            onClick={setExpandToggleState}
            small
            type="button"
          >
            {expanded ? 'Show less' : 'Show full list'}
          </TertiaryButton>
        </Container>
      )}
    </div>
  );
};

export default ExpandableContent;
