import { createSelector } from 'reselect';

import { getBasketUrlSelector } from '../../redux/reducers/bff/bffSelector';
import {
  getAllItemsHaveBatchingFailureSelector,
  getBatchingFailureItemsSelector,
  getShowBatchingFailureModalSelector,
  getShowRemoveBatchingFailureItemErrorSelector,
} from '../../redux/reducers/user/userSelector';

const batchingFailureModalState = createSelector(
  [
    getBasketUrlSelector,
    getAllItemsHaveBatchingFailureSelector,
    getBatchingFailureItemsSelector,
    getShowRemoveBatchingFailureItemErrorSelector,
    getShowBatchingFailureModalSelector,
  ],
  (
    basketUrl,
    allItemsHaveBatchingFailure,
    batchingFailureItems,
    removeItemsFailed,
    showModal,
  ) => ({
    basketUrl,
    allItemsHaveBatchingFailure,
    items: batchingFailureItems,
    removeItemsFailed,
    showModal,
  }),
);

export default batchingFailureModalState;
