import React from 'react';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "outOfStockModal" */ './OutOfStockModal')));

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'OutOfStockModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as OutOfStockModal };
