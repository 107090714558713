import {
  JOHN_LEWIS_OWNER_ID,
  WAITROSE_OWNER_ID,
} from '../../constants/clickCollectConstants';

function promotePartnershipStore(points) {
  let storeToPromote;
  let storeToPromoteIndex;
  let shouldRemoveFromList = false;

  const partnerStores = [WAITROSE_OWNER_ID, JOHN_LEWIS_OWNER_ID];
  const firstPartnershipStore = points.find(point => point.isAvailable && partnerStores.includes(point.ownerId));

  if (firstPartnershipStore) {
    const firstPartnershipStoreIsNearby = firstPartnershipStore.distanceInMiles < 10;

    if (firstPartnershipStoreIsNearby) {
      storeToPromoteIndex = points.indexOf(firstPartnershipStore);
      const shouldPromote = storeToPromoteIndex !== 0;

      if (shouldPromote) {
        storeToPromote = firstPartnershipStore;

        if (storeToPromoteIndex < 4) {
          shouldRemoveFromList = true;
        }

        return {
          storeToPromote,
          storeToPromoteIndex,
          shouldRemoveFromList,
        };
      }
    }
  }

  return {};
}

export default promotePartnershipStore;
