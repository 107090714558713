import React from 'react';

// Types
import { OrderTimeoutNotificationProps as LazyComponentProps } from './OrderTimeoutNotification.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "orderTimeoutNotification" */ './OrderTimeoutNotification')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'OrderTimeoutNotification');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as OrderTimeoutNotification };
