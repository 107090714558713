import DayPicker from 'jl-design-system/components/day-picker/DayPicker';

const CLICK_AND_COLLECT_DATES = {
  component: DayPicker,
  className: 'fieldLargeSmallMarginBottom',
  name: 'collectionDateTime',
  id: 'collectionDateTime',
  selectedDayMessageBody: "We'll keep your order for 7 days.",
  startWeekAtEarliestDate: true,
  required: true,
  validationMessageKeys: {
    required: 'Please select a day',
  },
};

export default CLICK_AND_COLLECT_DATES;
