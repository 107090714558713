import React from 'react';

// Types
import { EmptyBasketNotificationProps as LazyComponentProps } from './EmptyBasketNotification.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "emptyBasketNotification" */ './EmptyBasketNotification')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'EmptyBasketNotification');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as EmptyBasketNotification };
