import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import printLogo from 'jl-design-system/assets/logos-jb/john-lewis-_-partners-jb/john-lewis-_-partners-dark.svg';
import TickInCircle32PxOutlined from 'jl-design-system/elements/icons-jb/TickInCircle32PxOutlined';
import Link from 'jl-design-system/elements/link/Link';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import headerState from './Header.state';
import routeConstants, { accountCreationRoutes } from '../../constants/routeConstants';
import { openLeaveCheckoutModal } from '../../redux/actions/app/appActions';
import returnToJL from '../../utils/helpers/returnToJL';
import { useWindowSize } from '../../context/window/WindowSizeContext';

// Components
import BodyText from '../body-text';
import Container from '../container';

// Styles
import styles from './header.scss';

const Header = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, order, orderComplete, pathname = './', showFullScreenSignUpFlow } = useSelector(headerState);
  const { desktopResolution } = useWindowSize();

  const onLeaveCheckout = () => dispatch(openLeaveCheckoutModal(pathname));

  const HeaderLogoLink = () => {
    const showLeaveCheckoutModal =
      pathname.includes(routeConstants.DELIVERY) ||
      pathname.includes(routeConstants.CLICK_AND_COLLECT) ||
      pathname.includes(routeConstants.PAYMENT);
    const Tag = showLeaveCheckoutModal ? 'button' : Link;

    const commonProps = {
      className: styles.headerLogoLink,
      'data-testid': 'header-logo-link',
    };

    const tagSpecificProps = showLeaveCheckoutModal
      ? { onClick: onLeaveCheckout, type: 'button' }
      : { external: true, to: returnToJL() };

    return (
      <Tag {...commonProps} {...tagSpecificProps}>
        <span className={styles.visuallyHiddenText}>John Lewis</span>
      </Tag>
    );
  };

  const isPathForAccountCreation = accountCreationRoutes.some(routeConstant => pathname.includes(routeConstant));

  const showAccountCreatedHeader = isPathForAccountCreation
    && !isLoading
    && orderComplete
    && showFullScreenSignUpFlow;

  const secureMessageLabel = desktopResolution ? 'Secure checkout' : 'Secure';
  const secureMessageProps = {
    flex: {
      alignItems: 'center',
      gap: desktopResolution ? '1' : undefined,
      flexDirection: desktopResolution ? undefined : 'col-reverse',
    },
  } as const;

  return (
    <>
      <Container
        borderBottom="grey-15"
        className={styles.header}
        paddingY="3"
        tag="header"
        testId="app-header"
      >
        <Container className={styles.container} marginX={desktopResolution ? '4' : '2'}>
          <img
            alt="John Lewis & Partners"
            className={styles.printLogo}
            src={printLogo}
          />

          <Container flex={{ alignItems: 'center', justifyContent: 'space-between' }} hideForPrint>
            <HeaderLogoLink />

            <Container
              className={styles.secureText}
              testId="header-secure-message"
              {...secureMessageProps}
            >
              {secureMessageLabel}
              <span />
            </Container>
          </Container>
          <p className={styles.printOrderId} data-testid="header-order-id">
            Order number: <span><strong>{order?.id}</strong></span>
          </p>
        </Container>
      </Container>

      {showAccountCreatedHeader && (
        <Container borderBottom="grey-15" paddingY="3" textAlign="center">
          <Container flex={{ alignItems: 'center', gap: '0.5', justifyContent: 'center' }}>
            <TickInCircle32PxOutlined />
            <BodyText uppercase>Account created</BodyText>
          </Container>
        </Container>
      )}
    </>
  );
};

export default Header;
