import isEmpty from 'lodash/isEmpty';

function hasClickCollectBecomeAvailable(prevDeliveryOptionsAvailable = {}, deliveryOptions = {}) {
  if (isEmpty(prevDeliveryOptionsAvailable)) return false;
  const { clickCollectAvailable: prevClickCollectAvailable } = prevDeliveryOptionsAvailable;
  const { clickCollectAvailable } = deliveryOptions;

  return !!(!prevClickCollectAvailable && clickCollectAvailable);
}

export default hasClickCollectBecomeAvailable;
