import {
  POSTCODE,
} from 'jl-design-system/form/fields';

export default function makePostcodeOptional(addressFields) {
  const postcodeFieldIndex = addressFields?.findIndex(field => field?.id === POSTCODE?.id);
  const postcodeField = addressFields[postcodeFieldIndex];

  if (postcodeField) {
    const newField = {
      ...POSTCODE,
      required: false,
    };

    addressFields.splice(postcodeFieldIndex, 1, newField);
  }
}
