import { useEffect, useRef } from 'react';
import appConstants from '../../constants/appConstants';

const SessionTimeoutPageTitle = () => {
  const titleDefaultInterval = useRef<number | undefined>(undefined);
  const titleExpiryInterval = useRef<number | undefined>(undefined);

  const setTitleExpiryWarning = () => {
    document.title = appConstants.TITLE_EXPIRY_WARNING;
  };

  const setTitleDefault = () => {
    document.title = appConstants.TITLE_DEFAULT;
  };

  useEffect(() => {
    titleExpiryInterval.current = window.setInterval(
      setTitleExpiryWarning,
      2000,
    );

    titleDefaultInterval.current = window.setInterval(
      setTitleDefault,
      4000,
    );

    return () => {
      if (titleDefaultInterval.current !== undefined) {
        window.clearInterval(titleDefaultInterval.current);
      }
      if (titleExpiryInterval.current !== undefined) {
        window.clearInterval(titleExpiryInterval.current);
      }
      setTitleDefault();
    };
  }, []);

  return null;
};

export default SessionTimeoutPageTitle;
