import { DeliveryProps } from 'types/Delivery.types';
import { ProductProps } from 'types/Product.types';

import { undeliverableReasonConstants } from '../../../constants/deliveryConstants';
import { shouldRemoveAndSaveForLater } from '../../../utils/saveForLater/saveForLaterHelp';

export function deliveryContainsUndeliverableReason({
  orderForm,
  reason,
}: {
  orderForm: {
    deliveries: DeliveryProps[];
  };
  reason: string;
}) {
  const deliveries = orderForm?.deliveries ?? [];
  return deliveries.some((
    delivery: DeliveryProps,
  ) => delivery.items?.some((
    item: ProductProps,
  ) => item.type === 'service' && item.undeliverableReasons?.includes(reason)));
}

export function deliveryContainsIncompatibleServices(orderForm: {
  deliveries: DeliveryProps[];
}) {
  return deliveryContainsUndeliverableReason({ orderForm, reason: undeliverableReasonConstants.INCOMPATIBLE });
}

export function deliveryContainsUndeliverableServices(orderForm: {
  deliveries: DeliveryProps[];
}) {
  return deliveryContainsUndeliverableReason({ orderForm, reason: undeliverableReasonConstants.UNDELIVERABLE });
}

export const undelivereableServiceReasons = [
  undeliverableReasonConstants.INCOMPATIBLE,
  undeliverableReasonConstants.UNDELIVERABLE,
];

export const filterUndeliverableItems = (
  delivery: DeliveryProps,
) => delivery?.items?.filter((
  item: ProductProps,
) => item?.undeliverableReasons?.some((reason: string) => undelivereableServiceReasons.includes(reason)));

export const mapChildItems = (
  items: ProductProps[],
  service: ProductProps,
) => items.flatMap((
  item: ProductProps,
) => item?.childItems?.filter((child: ProductProps) => child?.id === service?.id).map((
  child: ProductProps,
) => ({
  ...child,
  productName: item?.name,
  parentItemId: item?.id,
})));

export const getServicesThatNeedRemoving = (deliveries: DeliveryProps[], items: ProductProps[]) => {
  const servicesThatNeedRemoving = deliveries.flatMap((delivery: DeliveryProps) => {
    const undeliverableItems = filterUndeliverableItems(delivery);
    return undeliverableItems?.flatMap((service: ProductProps) => mapChildItems(items, service));
  });

  if (servicesThatNeedRemoving?.length > 0) return servicesThatNeedRemoving;

  return undefined;
};

export const saveForLaterAvailableForUndeliverableServiceParent = ({
  servicesThatNeedRemoving = [],
  deliveries,
  isSignedIn,
  isExpressCheckout,
  isM2M,
  isShipFromStore,
}: any) => {
  const result = servicesThatNeedRemoving.every((service: ProductProps) => {
    const parentDelivery = deliveries.find((
      delivery: DeliveryProps,
    ) => delivery?.items?.some((item: ProductProps) => item?.id === service?.parentItemId));

    return shouldRemoveAndSaveForLater({
      isExpressCheckout,
      isM2M,
      isOutlet: parentDelivery?.outlet,
      isShipFromStore,
      isSignedIn,
    });
  });

  return result;
};
