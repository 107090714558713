const isZeroAmount = (amount?: string | number | null): boolean => {
  // null, undefined, '', 0,
  if (!amount) return true;
  // {}, [], true, 1,
  if (typeof amount !== 'string') return false;
  // '0', '0.0', '0.00', '£0', '£0.0', ' £0 remaining', ' ',
  const cleanString = amount.replace(/[£\s]/g, '');
  const float = parseFloat(cleanString);

  return (Number.isNaN(float) || float === 0);
};

export default isZeroAmount;
