import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import AddressWithSearchForm from 'jl-design-system/components/address-with-search-form/AddressWithSearchForm';
import { UnderlinedButton } from 'jl-design-system/elements/button/Button';
import { resetAddressSearch } from 'jl-design-system/redux/actions/address/addressActions';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';

// Config
import addBillingAddressState from './AddBillingAddress.state';
import { BILLING_ADDRESS } from '../../../../constants/actionConstants';
import scrollConstants from '../../../../constants/scrollConstants';
import getLoqateMocks from '../../../../non-prod-utils/utils/loqate/getLoqateMocks';
import {
  triggerLoqateTimeoutEnterAddressManuallyAnalytics,
  hideSelectedBillingAddressPreview,
  onLoqateNetworkTimeoutCallback,
  onLoqateSearch,
  onEnterManualAddress,
  toggleUseDeliveryAsBillingAddress,
} from '../../../../redux/actions/address/addressActions';
import { scrollToElement } from '../../../../utils';
import { getAddressFromFormValues, shouldMockLoqate } from '../../../../utils/address/addressHelpers';
import isAddressValid from '../../../../utils/addressBook/isAddressValid';
import mergeBillingAddressSources from '../../../../utils/address/mergeBillingAddressSources';
import getBillingDetailsFormConfig, { BILLING_ADDRESS_FORM_ID } from '../../../../utils/form/configs/billingAddress';

// Components
import ToggleProposedBillingAddress from './ToggleProposedBillingAddress';
import DisplayAddress from '../../../display-address/DisplayAddress';

// Styles
import styles from './add-billing-address.scss';

const AddBillingAddress = () => {
  const dispatch: AppDispatch = useDispatch();

  const {
    deliveryAddress = {},
    isClickCollectOrder,
    isSavedPayment,
    loqateApiKey = '',
    previewSelectedBillingAddress,
    sessionId,
    useDeliveryAddressAsBillingAddress,
  } = useSelector(addBillingAddressState);
  const previewSelectedBillingAddressValues = useSelector((state: RootState) => mergeBillingAddressSources(state));

  useEffect(() => {
    if (previewSelectedBillingAddress) {
      const billingAddressValid = isAddressValid(getAddressFromFormValues(previewSelectedBillingAddressValues));
      if (!billingAddressValid) {
        dispatch(hideSelectedBillingAddressPreview());
      } else {
        scrollToElement({ id: scrollConstants.BILLING_DETAILS_HEADING });
      }
    }
  }, [previewSelectedBillingAddress, previewSelectedBillingAddressValues, dispatch]);

  const getFormInitialValues = () => {
    const phoneNumber = deliveryAddress.phoneNumber
      ? { phoneNumber: deliveryAddress.phoneNumber }
      : {};

    return {
      ...deliveryAddress.addressee,
      ...phoneNumber,
    };
  };

  const initialValues = isClickCollectOrder ? getFormInitialValues() : undefined;
  const addressId = BILLING_ADDRESS;

  const configExtras = {
    // TODO is anything downwind of AddressWithSearchForm using isClickCollectOrder?
    isClickCollectOrder,
  };

  const formattedSelectedBillingAddress = getAddressFromFormValues(previewSelectedBillingAddressValues);

  const onResetAddressSearchHandler = (params: { countryCode: string }) => {
    const shouldResetCountryToGb = params.countryCode !== 'GB';
    if (shouldResetCountryToGb) {
      dispatch(
        resetAddressSearch({
          ...params,
          countryCode: 'GB',
        }),
      );
    } else {
      dispatch(resetAddressSearch(params));
    }
  };
  const onToggleUseDeliveryAsBillingAddressHandler = (evt: any) => dispatch(toggleUseDeliveryAsBillingAddress(evt));
  const onTriggerLoqateTimeoutEnterAddressManuallyAnalyticsHandler =
    () => dispatch(triggerLoqateTimeoutEnterAddressManuallyAnalytics());
  const onHideSelectedBillingAddressPreviewHandler = () => dispatch(hideSelectedBillingAddressPreview());
  const onLoqateNetworkTimeoutCallbackHandler = (url: any) => dispatch(onLoqateNetworkTimeoutCallback(url));
  const onLoqateSearchHandler =
    (response: any, actionType: any, json: any) => dispatch(onLoqateSearch(response, actionType, json));
  const onEnterManualAddressHandler = () => dispatch(onEnterManualAddress());

  return (
    <div className={styles.container}>
      {(isSavedPayment || !isClickCollectOrder) && (
        <ToggleProposedBillingAddress
          action={onToggleUseDeliveryAsBillingAddressHandler}
          addressAsLabel={isSavedPayment}
          deliveryAddress={deliveryAddress}
          useProposedBillingAddress={useDeliveryAddressAsBillingAddress}
        />
      )}

      {(!previewSelectedBillingAddress && ((!isSavedPayment && isClickCollectOrder) ||
        !useDeliveryAddressAsBillingAddress)) && (
        <AddressWithSearchForm
          addressId={addressId}
          combineExtraAddressLines
          configExtras={configExtras}
          data-testid="billing-address"
          destroyOnUnmount={false}
          formId={BILLING_ADDRESS_FORM_ID}
          getConfig={getBillingDetailsFormConfig}
          getLoqateMocks={getLoqateMocks}
          initialValues={initialValues}
          international
          jlpid={sessionId}
          loqateApiKey={loqateApiKey}
          maxAddressLines={3}
          multiFormValidation
          onEnterManualAddress={onEnterManualAddressHandler}
          onLoqateNetworkTimeoutCallback={onLoqateNetworkTimeoutCallbackHandler}
          onLoqateSearch={onLoqateSearchHandler}
          onLoqateTimeoutEnterAddressManuallyCallback={onTriggerLoqateTimeoutEnterAddressManuallyAnalyticsHandler}
          onSearchForAnotherAddressOverride={onResetAddressSearchHandler}
          replaceGbTerritoryCountryCodes={false}
          scrollValidationMessageIntoView
          shouldMockLoqate={shouldMockLoqate()}
          useLoqateAddressSearch
          useV2Message
        />
      )}

      {previewSelectedBillingAddress && (
        <>
          <DisplayAddress
            address={formattedSelectedBillingAddress.address}
            addressee={formattedSelectedBillingAddress.addressee}
            data-testid="payment-page-billing-address"
            phoneNumber={formattedSelectedBillingAddress.phoneNumber}
          />

          <UnderlinedButton
            className={styles.editAddressButton}
            onClick={onHideSelectedBillingAddressPreviewHandler}
          >
            Edit address
          </UnderlinedButton>
        </>
      )}

    </div>
  );

};

export default AddBillingAddress;
