// Types
import { GiftCardVoucherProps } from 'types/GiftCardVoucher.types';

// Config
import makeGiftOptionsReducer from './giftOptionsHigherOrderReducer';
import { APPLY_GIFT_VOUCHER } from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';

export const INITIAL_STATE = {
  isLoading: false,
  formSubmitFailed: false,
  errorMessage: undefined,
};

export default makeGiftOptionsReducer({
  INITIAL_STATE,
  ACTION_NAME: APPLY_GIFT_VOUCHER,
});

export const giftVouchersSelector = (payments: GiftCardVoucherProps[] = [], context: string = '') => payments
  .filter(payment => payment.type === paymentTypeConstants.GIFT_VOUCHER)
  .filter(payment => !payment.isRemoved || (payment.isRemoved && payment.removalContext === context))
  .map(payment => ({
    ...payment,
    serialNumber: payment?.details?.serialNumber,
    securityCode: payment?.details?.securityCode,
  }));
