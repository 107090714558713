// Types
import { GiftCardVoucherProps } from 'types/GiftCardVoucher.types';

// Config
import paymentTypeConstants from '../../constants/paymentTypeConstants';

export default function getAppliedPayments(
  paymentStates: GiftCardVoucherProps[],
  returnedPayments: GiftCardVoucherProps[],
): GiftCardVoucherProps[] {
  const removedPayments = paymentStates?.filter(paymentState => paymentState.isRemoved);
  const payments = removedPayments?.length > 0 ? returnedPayments.concat(removedPayments) : returnedPayments;

  return payments.map((payment) => {
    const appliedPayment = returnedPayments?.filter(appliedPayment => payment.id === appliedPayment.id)[0];
    const previousStateEntry = paymentStates.find(paymentState => paymentState.id === payment.id);
    const previousStateEntryType = previousStateEntry?.type ?? '';
    const isGiftCard = previousStateEntryType === paymentTypeConstants.GIFT_CARD;
    const isGiftVoucher = previousStateEntryType === paymentTypeConstants.GIFT_VOUCHER;

    return appliedPayment ? {
      ...appliedPayment,
      details: {
        ...appliedPayment.details,
        ...(isGiftCard && {
          pin: previousStateEntry?.details?.pin,
          cardNumber: previousStateEntry?.details?.cardNumber,
        }),
        ...(isGiftVoucher && {
          securityCode: previousStateEntry?.details?.securityCode,
          serialNumber: previousStateEntry?.details?.serialNumber,
        }),
      },
    } : payment;
  });
}
