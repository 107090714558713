export default function getInvalidAddressFormConfig(args) {
  const {
    getConfig,
    editMode = false,
    initialValues,
  } = args;

  return params => getConfig({
    ...params,
    editMode,
    enableGBCounty: !!initialValues?.countyStateOrProvince,
  });
}
