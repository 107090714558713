export const brokenRules = {
  GIFT_CARD_APPLIED: 'GIFT_CARD_APPLIED',
  GIFT_VOUCHER_APPLIED: 'GIFT_VOUCHER_APPLIED',
  MAX_ORDER_LINES_EXCEEDED: 'MAX_ORDER_LINES_EXCEEDED',
  LOCAL_COLLECTION_NETWORK_NOT_ALLOWED: 'LOCAL_COLLECTION_NETWORK_NOT_ALLOWED',
};

export const requiredActionTypes = {
  REMOVE_ITEMS: 'REMOVE_ITEMS',
};

export const creditApplicationStatuses = {
  DECLINED: 'DECLINED',
  REFERRED: 'REFERRED',
  CANCELLED: 'CANCELLED',
  APPROVED: 'APPROVED',
};

export const creditTypes = {
  INTEREST_FREE: 'INTEREST_FREE',
  PAY_MONTHLY: 'PAY_MONTHLY',
  PAYMENT_PLAN: 'PAYMENT_PLAN',
};
