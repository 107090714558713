type MergeItemResponse = {
  result?: {
    orderForm?: {};
  };
};

export const mergeItemResponse = (responses: MergeItemResponse[]) => {
  const mainResponse = responses[0];
  const itemsResponse = responses[1];

  // no result implies an API failure so just return the main response unmodified
  if (!mainResponse?.result) return mainResponse;

  return {
    result: {
      ...mainResponse.result,
      ...mainResponse.result?.orderForm && itemsResponse?.result?.orderForm && {
        orderForm: {
          ...mainResponse.result.orderForm,
          ...itemsResponse?.result.orderForm,
        },
      },
    },
  };
};
