import {
  lettersNumbersSpacesPattern,
  lettersNumbersDotDashApostropheSpacePattern,
} from 'jl-design-system/form/regex';

// lodash
import isString from 'lodash/isString';

export const controlCharPattern = '\\x00-\\x09\\x0B\\x0C\\x0E-\\x1F\\x7F';

export const restrictInputLettersNumbersSpaces = (value = '') => {
  if (!isString(value)) {
    return '';
  }
  const regex = new RegExp(`[^${lettersNumbersSpacesPattern}]`, 'g');
  return value.replace(regex, '');
};

export const restrictInputLettersNumbersDotDashApostropheSpaces = (value = '') => {
  if (!isString(value)) {
    return '';
  }
  const regex = new RegExp(`[^${lettersNumbersDotDashApostropheSpacePattern}]`, 'g');
  return value.replace(regex, '');
};

export const restrictInputExcludeControlChars = (value = '') => {
  if (!isString(value)) {
    return '';
  }
  const regex = new RegExp(`[${controlCharPattern}]`, 'g');
  return value.replace(regex, '');
};
