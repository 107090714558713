// lodash
import get from 'lodash/get';
// redux
import {
  APPLE_PAY_SET_CARD_TYPE,
} from '../../../constants/actionConstants';
import {
  cancelApplePayPayment,
  failureApplePayPayment,
  setApplePayCardType,
  setCanMakeApplePayPayments,
  submitApplePayTokenAndOrder,
} from '../payment/applePayActions';
// utils
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';


export const getOrderData = (order) => {
  const orderItems = get(order, 'items', []).map((item) => {
    const childItems = get(item, 'childItems', []).map(childItem => ({
      title: childItem.name,
      unitPrice: get(childItem, 'price', '').replace('£', ''),
      quantity: childItem.quantity,
    }));
    return {
      title: item.name,
      unitPrice: get(item, 'price', '').replace('£', ''),
      quantity: item.quantity,
      childItems,
    };
  });
  return {
    orderNumber: get(order, 'id'),
    orderTotal: get(order, 'amounts.grandTotal', '').replace('£', ''),
    orderItems,
  };
};

const getOrder = store => get(store.getState(), 'confirmation.order');
export const getOrderDataIOS = store => () => {
  const order = getOrder(store);
  return getOrderData(order);
};

export const applePayEligibleOnDevice = store => (canMakeApplePayPayments) => {
  if (canMakeApplePayPayments === true) {
    store.dispatch(setCanMakeApplePayPayments(canMakeApplePayPayments));
  }
};

export const applePayPaymentSuccess = store => async (token) => {
  const state = store.getState();
  const sessionId = get(state, 'bff.sessionId', '');
  const orderFormId = get(state, 'orderForm.id', '');
  const omsOrderId = get(state, 'orderForm.omsOrderId', '');
  const paymentId = get(state.payment, 'ocpPaymentId');

  sendNewRelicCustomEvent('applePayPaymentSuccess callback triggered', {
    orderFormId,
    omsOrderId,
    paymentId,
    sessionId,
  });

  const response = await store.dispatch(setApplePayCardType(token?.paymentMethod?.network));
  if (response.type === `${APPLE_PAY_SET_CARD_TYPE}.FAILED`) {
    return store.dispatch(failureApplePayPayment());
  }

  return store.dispatch(submitApplePayTokenAndOrder(token));
};

export const applePayPaymentCancel = store => () => {
  store.dispatch(cancelApplePayPayment());
};

export const applePayPaymentFailure = store => () => {
  store.dispatch(failureApplePayPayment());
};
