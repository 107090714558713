// lodash
function getDeliveryInstructions(formValues) {

  const deliveryInstruction = formValues.deliveryInstructions;

  if (!deliveryInstruction) {
    return formValues.deliveryInstructionsOption;
  }

  const selectedOptionPrefix = `${formValues.deliveryInstructionsOption}:`;

  return `${selectedOptionPrefix} ${deliveryInstruction}`;
}

export default getDeliveryInstructions;
