import React from 'react';

// Types
import { TradeInItemProps as LazyComponentProps } from './TradeInItem.types';

// Components
import withSuspense, { lazyRetry } from '../../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "tradeInItem" */ './TradeInItem')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'TradeInItem');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as TradeInItem };
