import React from 'react';

// Components
import withSuspense, { lazyRetry } from '../../../../components/suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "paymentWalletStubsModal" */ './PaymentWalletStubsModal')));

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'PaymentWalletStubsModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as PaymentWalletStubsModal };
