import { createSelector } from 'reselect';

// Types
import { PaymentCardProps } from 'types/Payment.types';

// Config
import { getSavedPaymentCardsSelector } from '../../../../redux/reducers/user/userSelector';
import {
  getPaymentCardDeleteApiCallActiveSelector,
  getSelectedPaymentTypeSelector,
  getShouldShowPaymentCardDeleteModalSelector,
} from '../../../../redux/reducers/payment/paymentSelector';

const getSelectedPaymentCardSelector = createSelector(
  [getSavedPaymentCardsSelector, getSelectedPaymentTypeSelector],
  (savedPaymentCards, selectedPaymentType) => {
    if (selectedPaymentType) {
      const selectedPaymentCardId = selectedPaymentType.split(':')[1];
      return savedPaymentCards?.find((card: PaymentCardProps) => card?.id === selectedPaymentCardId);
    }
    return undefined;
  },
);

const paymentCardDeleteState = createSelector(
  [
    getPaymentCardDeleteApiCallActiveSelector,
    getSelectedPaymentCardSelector,
    getShouldShowPaymentCardDeleteModalSelector,
  ],
  (paymentCardDeleteApiCallActive, selectedPaymentCard, shouldShowPaymentCardDeleteModal) => ({
    paymentCardDeleteApiCallActive,
    selectedPaymentCard,
    shouldShowPaymentCardDeleteModal,
  }),
);

export default paymentCardDeleteState;
