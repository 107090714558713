// jl-design-system
import { LOQATE_ADDRESS_SEARCH } from 'jl-design-system/redux/actions/addressSearch/addressSearchActionConstants';
// lodash
import get from 'lodash/get';
//
import {
  ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK,
  HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
  USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
  USE_DIFFERENT_RESIDENTIAL_ADDRESS,
  RESET_ADDRESS_SEARCH,
  RESIDENTIAL_ADDRESS,
  USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS,
  USE_DIFFERENT_BILLING_ADDRESS,
} from '../../../constants/actionConstants';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';

export const onLoqateSearch = (response, actionType = '', json) => async (dispatch, getState) => {
  const state = getState();
  const orderFormId = get(state, 'orderForm.id');
  const sessionId = get(state, 'bff.sessionId');
  const timestamp = Date.now();
  const { userAgent } = window.navigator;
  const { status, url } = response;

  const postCode = get(json, 'Items[0].PostalCode', '');
  const errorCode = get(json, 'Items[0].Error', '');
  const errorCause = get(json, 'Items[0].Cause', '');
  const errorDescription = get(json, 'Items[0].Description', '');

  if (actionType !== `${LOQATE_ADDRESS_SEARCH}.SUCCESS`) {
    sendNewRelicCustomEvent('checkoutAppPostcodeSearch', {
      postCode,
      orderFormId,
      sessionId,
      status,
      timestamp,
      url,
      userAgent,
      errorCode,
      errorCause,
      errorDescription,
      'x-internal-duration': response.headers.get('x-internal-duration'),
      'x-response-size': response.headers.get('x-response-size'),
      'x-response-status': response.headers.get('x-response-status'),
    });
  }
  return {};
};

export const onLoqateNetworkTimeoutCallback = url => async (dispatch, getState) => {
  const state = getState();
  const orderFormId = get(state, 'orderForm.id');
  const sessionId = get(state, 'bff.sessionId');
  const timestamp = Date.now();
  const { userAgent } = window.navigator;

  sendNewRelicCustomEvent('checkoutAppPostcodeSearch', {
    errorDescription: 'No response from Loqate service',
    orderFormId,
    sessionId,
    timestamp,
    url,
    userAgent,
  });
  return {};
};

export const onEnterManualAddress = () => async (dispatch, getState) => {
  const state = getState();
  const sessionId = get(state, 'bff.sessionId');
  const allLoqateCallsFailed = get(state, 'app.allLoqateCallsFailed', false);
  const timestamp = Date.now();
  const { userAgent } = window.navigator;

  sendNewRelicCustomEvent('manualAddressEntry', {
    sessionId,
    timestamp,
    userAgent,
    allLoqateCallsFailed,
  });
  return {};
};

export const triggerLoqateTimeoutEnterAddressManuallyAnalytics = () => ({
  type: ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK,
});

export const hideSelectedBillingAddressPreview = () => ({
  type: HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
});

export const toggleResidentialAddress = evt => async (dispatch, getState) => {
  dispatch({
    type: `${RESIDENTIAL_ADDRESS}_${RESET_ADDRESS_SEARCH}`,
  });

  if (evt?.target?.checked) {
    dispatch({
      type: USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
      confirmedDeliveryAddress: get(getState(), 'delivery.confirmedDeliveryAddress'),
    });
    return;
  }

  dispatch({
    type: USE_DIFFERENT_RESIDENTIAL_ADDRESS,
  });
};

export const toggleUseDeliveryAsBillingAddress = evt => ({
  type: evt?.target?.checked ? USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS : USE_DIFFERENT_BILLING_ADDRESS,
});
