// lodash
import get from 'lodash/get';
import { destroyForm } from 'jl-design-system/redux/actions/form/formActions';
import { resetApp } from '../reset/resetActions';
import { removeStorageItem } from '../../../utils/storage/storage';
import validateEmail from '../../../utils/email-validation/emailValidatorProxy';
import {
  GET_LOGIN_PAGE,
  LOGIN_CREDS,
  LOGIN_RESET,
  SHOW_EMAIL_VALIDATION_ERROR,
} from '../../../constants/actionConstants';
import {
  URL_LOGIN_PAGE_AUTH0_FALLBACK,
  URL_LOGIN_SESSIONS_V2,
  URL_LOGIN_SESSIONS_V2_WITH_LOGIN_TYPE,
} from '../../../constants/endpointConstants';
import { GUEST_FORM_ID } from '../../../utils/form/configs/login';
import {
  initLogging,
} from '../../../utils/logging/logging-utils';
import { getFeatures } from '../bff/bffActions';
import loginTypeConstants from '../../../constants/loginTypeConstants';
import { isApps } from '../../reducers/app/appReducer';
import { initDeliveryPage } from '../delivery/deliveryActions';

export const getFallbackLoginPage = auth0Fallback => async (dispatch, getState) => {
  if (get(getState().app, 'orderComplete', false)) {
    return {};
  }

  return dispatch({
    type: GET_LOGIN_PAGE,
    request: client => client({
      path: URL_LOGIN_PAGE_AUTH0_FALLBACK(auth0Fallback),
      config: { method: 'GET' },
    }),
  });
};

export const loginCredentials = (loginParams = {}, getState) => {
  let loginType = get(getState(), 'login.loginType');
  if (loginType === undefined) {
    if (isApps(getState())) {
      loginType = loginTypeConstants.CUSTOMER_APPS;
    }
  }
  const loginUrl = loginType === undefined ? URL_LOGIN_SESSIONS_V2 : URL_LOGIN_SESSIONS_V2_WITH_LOGIN_TYPE(loginType);
  return {
    type: LOGIN_CREDS,
    request: client => client({ path: loginUrl, config: { method: 'PUT', body: loginParams } }),
  };
};

export const submitAndHandleLogin = loginParams => async (dispatch, getState) => {
  if (!await validateEmail(loginParams.email)) {
    dispatch({
      type: SHOW_EMAIL_VALIDATION_ERROR,
    });
  } else {
    const response = await dispatch(loginCredentials(loginParams, getState));

    if (response.type === `${LOGIN_CREDS}.SUCCESS`) {

      removeStorageItem('email');
      dispatch(resetApp(LOGIN_RESET));
      dispatch(destroyForm(GUEST_FORM_ID));

      await dispatch(getFeatures());
      dispatch(initLogging());
      dispatch(initDeliveryPage());
    }
  }
};
