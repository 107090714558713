import React from 'react';

// Types
import { PaymentWalletStubsProps as LazyComponentProps } from './PaymentWalletStubs.types';

// Components
import withSuspense, { lazyRetry } from '../../../components/suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "paymentWalletStubs" */ './PaymentWalletStubs')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'PaymentWalletStubs');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as PaymentWalletStubs };
