import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

// Design System
import Form from 'jl-design-system/components/form/Form';
import { Stepper } from 'jl-design-system/components/stepper/Stepper';
import { QUANTITY_INPUT } from 'jl-design-system/form/fields';
import { isDiorBrand } from 'jl-design-system/utils/brands/brands';

// Config
import getItemQuantityFormConfig from '../../utils/form/configs/itemQuantity';
import isItemFabric from '../../utils/orderform/isItemFabric';

// Component
import BodyText from '../body-text';
import Heading from '../heading';

export function getQuantitySelector(item, reducedQuantity, styles, onQuantityChange) {
  const config = getItemQuantityFormConfig({
    item,
    isItemFabric: isItemFabric(item),
  });

  const handleStepperChange = (newQuantity) => {
    onQuantityChange(newQuantity);
  };

  if (isItemFabric(item)) {
    return (
      <Form
        config={config}
        data-testid="order-item-quantity-selector"
        form={config.id}
        initialValues={{
          [QUANTITY_INPUT.id]: reducedQuantity.toString(),
          itemId: item.id,
        }}
        useFormValidationErrorsAlertMessage={false}
      />
    );
  }

  return (
    <Stepper
      className={styles.stepper}
      data-testid="order-item-quantity-stepper"
      handleChange={handleStepperChange}
      inputId={`${item.id}-quantity-stepper`}
      inputName="quantity-selector"
      max={config.maxValue}
      value={reducedQuantity}
    />
  );
}

export function getReducedQuantityText(quantity, reducedQuantity, styles) {
  return (
    <div className={styles.reducedQuantity}>
      <span data-testid="order-item-unavailable-quantity">
        <span>Quantity: </span>
        <span className={styles.strikeThrough}>{quantity}</span>
      </span>
      <span data-testid="order-item-available-quantity">{reducedQuantity}</span>
    </div>
  );
}

export function getReducedQuantityTextForFabricItem(quantity, reducedQuantity, styles) {
  return (
    <div className={styles.reducedQuantity}>
      <span>Quantity: </span>
      <span
        className={styles.strikeThrough}
        data-testid="order-item-unavailable-quantity"
      >
        {quantity}
      </span>
      <span data-testid="order-item-available-quantity">{reducedQuantity}</span>
      <span>metre(s)</span>
    </div>
  );
}

export function getQuantityText({ bold = false, quantity, styles }) {
  const quantityText = bold ? <strong>{quantity}</strong> : quantity;
  return (
    <div className={styles.quantity}>
      <span
        className={styles.visuallyHiddenText}
        data-testid="order-item-quantity-reference"
      >
        Quantity: {quantity}
      </span>

      <p
        aria-hidden="true"
        data-testid="order-item-quantity"
      >
        Quantity: {quantityText}
      </p>
    </div>
  );
}

export function getQuantityTextFabricItem(quantity, styles) {
  // TODO simplify this
  const quantityLabel = quantity === 1 ?
    `Quantity: ${quantity} metre` : `Quantity: ${quantity} metres`;
  const quantityReferenceLabel = quantity === 1 ?
    `Quantity ${quantity} metre` : `Quantity ${quantity} metres`;

  return (
    <div className={styles.quantity}>
      <span
        className={styles.visuallyHiddenText}
        data-testid="order-item-quantity-reference"
      >
        {quantityReferenceLabel}
      </span>

      <p
        aria-hidden="true"
        data-testid="order-item-quantity"
      >
        {quantityLabel}
      </p>
    </div>
  );
}

export function getInformationList(information, styles) {
  if (isEmpty(information)) {
    return null;
  }

  return (
    <dl className={styles.informationList} data-testid="order-item-information-list">
      {information.map(infoItem => (
        <div key={infoItem.name} className={styles.informationListItem}>
          <dt className={styles.informationListItemTerm}>
            {infoItem.name}:{' '}
          </dt>
          <dd className={styles.informationListItemDefinition}>{infoItem.value}</dd>
        </div>
      ))}
    </dl>
  );
}

export function getPromotionalText({
  brand = '',
  showPartnerDiscountRate = false,
  showPromotionalMessageTitle = false,
  partnerDiscountRate = '',
  promotionalMessageTitle = '',
  styles,
}) {
  const isDior = isDiorBrand(brand);

  return (showPartnerDiscountRate || showPromotionalMessageTitle) && (
    <>
      {!!(showPromotionalMessageTitle && promotionalMessageTitle) && (
        <BodyText
          className={cx({
            [styles.reducerToClearText]: !isDior,
          })}
          testId="reduced-to-clear-text"
        >
          {promotionalMessageTitle}
        </BodyText>
      )}
      {!!(showPartnerDiscountRate && partnerDiscountRate) && (
        <BodyText
          className={cx({
            [styles.reducerToClearText]: !isDior,
          })}
          testId="reduced-to-clear-text"
        >
          {partnerDiscountRate} Partner Discount
        </BodyText>
      )}
    </>
  );
}

export function getSubtotalText({
  brand = '',
  sidebar = false,
  subtotal,
  subtotalDiscounted,
  styles,
  basketView = false,
  isChildItem = false,
  isOrderConfirmation = false,
  priceReductionHistory = [],
  promotionalMessageTitle = '',
  partnerDiscountRate = '',
  showPartnerDiscountRate = false,
  showPromotionalMessageTitle = false,
}) {
  const basketViewSubtotal = subtotalDiscounted || subtotal;
  const isDior = isDiorBrand(brand);
  const showPriceReductionHistory = sidebar || basketView || isOrderConfirmation;

  const priceReductionHistoryDisplay = (additionalValue) => {
    const historyMap = priceReductionHistory.map((history, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <span key={`price-history-${index}`} className={styles.priceReductionHistory}>
        { history.subTotal }
      </span>
    ));

    if (additionalValue) {
      const additionalValueDisplay = (
        <span key="price-history-additional-value" className={styles.priceReductionHistory}>
          { additionalValue }
        </span>
      );
      historyMap.push(additionalValueDisplay);
    }
    return historyMap.reverse();
  };

  if (showPriceReductionHistory) {
    if (partnerDiscountRate && subtotalDiscounted) {
      return (
        <div className={styles.subtotalHistory}>
          <div className={styles.discountedPriceContainer} data-testid="order-item-discounted-price-container">
            <BodyText
              className={cx({
                [styles.reducedToClearSubtotal]: !isDior,
              })}
              testId="order-item-discounted-subtotal-value"
            >
              {subtotalDiscounted}
            </BodyText>
            <p
              className={styles.priceReductionHistoryContainer}
              data-testid="discount-price-reduction-history-container"
            >
              {
                priceReductionHistoryDisplay(
                  promotionalMessageTitle || isEmpty(priceReductionHistory) ? subtotal : undefined,
                )
              }
            </p>
          </div>
          {getPromotionalText({
            brand,
            showPromotionalMessageTitle,
            showPartnerDiscountRate,
            partnerDiscountRate,
            promotionalMessageTitle,
            styles,
          })}
        </div>
      );
    }

    if (!isEmpty(priceReductionHistory)) {
      return (
        <div className={styles.subtotalHistory}>
          <div className={styles.discountedPriceContainer} data-testid="order-item-discounted-price-container">
            <BodyText
              className={cx({
                [styles.reducedToClearSubtotal]: !isDior,
              })}
              testId="order-item-discounted-subtotal-value"
            >
              {subtotal}
            </BodyText>
            <BodyText
              className={styles.priceReductionHistoryContainer}
              testId="price-reduction-history-container"
            >
              { priceReductionHistoryDisplay() }
            </BodyText>
          </div>
          {getPromotionalText({
            brand,
            showPromotionalMessageTitle,
            promotionalMessageTitle,
            styles,
          })}
        </div>
      );
    }
  }

  return (

    <span
      className={cx(styles.subtotal, {
        [styles.childSubtotalBasket]: isChildItem && basketView,
        [styles.orderConfirmationSubtotal]: isOrderConfirmation,
      })}
      data-testid="get-subtotal-text-container"
    >

      {
        !basketView &&
        <>
          <span
            className={styles.visuallyHiddenText}
            data-testid="order-item-subtotal-label"
          >
            Subtotal
          </span>
          <Heading
            data-testid="order-item-subtotal-value"
            label={subtotal}
            tag="p"
            type="xs"
          />
        </>
      }
      {
        basketView &&
        <Heading
          data-testid="order-item-subtotal-value"
          label={basketViewSubtotal}
          tag="p"
          type="xs"
        />
      }
    </span>
  );

}
