import { RewardsProps } from '../../types/Rewards.types';

export const getMyJLRewardsMessage = (rewards: RewardsProps[], maxLength: number = 40) => {
  const firstReward = rewards[0];

  const truncateText = (text: string, length: number) => (text.length > length ? `${text.slice(0, length)}...` : text);
  const campaignName = firstReward?.campaignFriendlyName
    ? truncateText(firstReward.campaignFriendlyName, maxLength)
    : null;

  const rewardMessage = campaignName
    ? `We've automatically applied your My John Lewis "${campaignName}" reward`
    : "We've automatically applied your My John Lewis reward";

  return rewardMessage;
};
