// Loads script by appending <script> tag to the DOM
export function loadScript({
  appendToNodeId,
  async = false,
  attributes = {},
  onErrorCallback = () => {},
  onSuccessCallback = () => {},
  url,
}: {
  appendToNodeId: string;
  async?: boolean;
  attributes?: { [key: string]: string };
  onErrorCallback?: () => void;
  onSuccessCallback?: () => void;
  url: string;
}): () => void {
  let scriptLoaded = false;

  function onLoad() {
    scriptLoaded = true;
    onSuccessCallback();
  }

  function onError() {
    scriptLoaded = false;
    onErrorCallback();
  }

  return () => {
    if (scriptLoaded) {
      onSuccessCallback();
    }

    const script = document.createElement('script');
    script.onload = onLoad;
    script.onerror = onError;
    script.type = 'text/javascript';
    script.src = url;
    script.async = async;
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        script.setAttribute(key, attributes[key]);
      });
    }

    const node = appendToNodeId ? document.getElementById(appendToNodeId) : document.body;
    if (node) {
      node.appendChild(script);
    }
  };
}

// Executes script by appending <script> tag to the DOM
export function appendScript(jsString: string, nodeId?: string): void {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = jsString;

  const node = nodeId ? document.getElementById(nodeId) : document.body;
  if (node) {
    node.appendChild(script);
  }
}
