// Types
import { AddressRecordProps } from 'types/Address.types';

// Config
import { countryCodeIsInternational } from '../address/addressHelpers';

export function getDefaultDeliveryAddressAvailableForDelivery(addressBook: AddressRecordProps[]) {
  const ukDefaultAddress = addressBook?.find(addressRecord => addressRecord.defaultShipping
    && !countryCodeIsInternational(addressRecord?.address?.countryCode) && !addressRecord.notAvailableForDelivery);
  const ukContactAddress = addressBook?.find(addressRecord => addressRecord.contact
    && !countryCodeIsInternational(addressRecord?.address?.countryCode) && !addressRecord.notAvailableForDelivery);
  const firstAddressEntry = addressBook?.find(addressRecord => !countryCodeIsInternational(
    addressRecord?.address?.countryCode,
  ) && !addressRecord.notAvailableForDelivery);

  // UKdefaultAddress -> UKcontactAddress -> firstAddressInAddressBook
  return (
    ukDefaultAddress
    || ukContactAddress
    || firstAddressEntry
    || undefined
  );
}
